import React, { Component, useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import { hidePrintLoader, useLastPage, useTitleFunctional } from '../functions/Reusables';


function Login(props){
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect((props) => {
    // isAuth(); // to be implemented
    // console.log(props)
  }, []);
  useTitleFunctional("Login | AMAC Tenement")
  hidePrintLoader()
  // useLastPage("/dashboard")
    return (
      <div className="container-fluid">
        
         { props.isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
        <div className="row">
          {/* <div>Hello</div> */}
          <div className="col-xl-7" style={{ backgroundImage: 'url(assets/images/login/amacLogin.jpg)',backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'}}>
          
          </div>
          <div className="col-xl-5 p-0">
            <div className="login-card">
              <div className="theme-form login-form">
                <h4>Login</h4>
                <h6>Welcome back! Log in to your account.</h6>
                    {props.errorMessage !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{props.errorMessage}</strong>
                      <button class="btn-close" onClick={()=>props.closeErroMessageBox('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {props.successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{props.successMessage}</strong>
                      <button class="btn-close" onClick={()=>props.closeSuccessMessageBox('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                <div className="form-group">
                  <label>User Name</label>
                  <div className="input-group"><span className="input-group-text"><i className="icon-email"></i></span>
                    <input className="form-control" type="email" onChange={(e)=> {
                      props.closeErroMessageBox()
                      setUsername(e.target.value)
                      }} required="" placeholder="youremail@mail.com"/>
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="input-group"><span className="input-group-text"><i className="icon-lock"></i></span>
                    <input className="form-control" type={passwordVisible ? "text": "password"} name="login[password]" onChange={(e)=> {
                      props.closeErroMessageBox()
                      setPassword(e.target.value)
                      }} required="" placeholder="*********"/>
                    <div className="show-hide">
                      {!passwordVisible ? <span className="input-group-text" onClick={()=> setPasswordVisible(true)}><FeatherIcon icon="eye"  size={20}></FeatherIcon></span> :
                      <span className="input-group-text" onClick={()=> setPasswordVisible(false)}><FeatherIcon icon="eye-off"  size={20}></FeatherIcon></span>}
                    </div>
                  </div>
                </div>
                <div className="form-group" >
                  {/* <Link className="btn btn-primary btn-block" to={"/dashboard"} type="submit">Sign in</Link> */}
                 {props.isLoading ? 
                 <button disabled  className="btn btn-primary btn-block" onClick={()=>{
                    props.authenticate(username,password)
                  }} type="submit">Sign in</button>:
                 <button  className="btn btn-primary btn-block" onClick={()=>{
                    props.authenticate(username,password)
                  }} type="submit">Sign in</button>
                }
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div>Hello</div>
    )
  
}
export default Login