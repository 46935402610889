import React, { Component, useRef, useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { BtnCellRendererViewOnly } from '../components/ActionButtons';
import MyDataTable from '../components/MyDataTable';
import { getUser, setFilterData, useLastPage, useTitleFunctional } from '../functions/Reusables';
import FeatherIcon from 'feather-icons-react';
export default function ViewDispatch (){
  let navigate = useNavigate();
  const tableColumnsDispatch = [
    { headerName:'PID',field: 'pid'},
    { headerName:'TYPE',field: 'type'},
    { headerName:'DATE DISPATCHED',field: 'date_delivered'},
    { headerName:'RECEIVED BY.',field: 'received_by'},
    { headerName:'DISPATCH MODE',field: 'mode'},
    {
      headerName:'Action',
      field: "pid",
      shouldAction:true,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function(field,id) {
          console.log(parseInt(field.slice(3)), id)
          if(id === 'view'){
            navigate("/createdispatch", { state:{ pid:  field} });
          }
        }
      },
      maxWidth:300
    },
  ]
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'TYPE',field: 'type'},
    { headerName:'DATE DISPATCHED',field: 'date_delivered'},
    { headerName:'RECEIVED BY.',field: 'received_by'},
    { headerName:'DISPATCH MODE',field: 'mode'},
    { headerName:'',field: 'none',shouldAction:true,maxWidth:100},
    
  ]

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");

  const frameworkComponents = {
    btnCellRenderer: BtnCellRendererViewOnly
  }

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }
  const myRef = useRef()
  useTitleFunctional("View Dispatch | AMAC Tenement")
  useLastPage("viewdispatch")
  
    return (
          <div className="page-body">
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col-sm-4" >
                  <h3>View Dispatch</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Dispatch</a></li>
                    <li className="breadcrumb-item">View Dispatch</li>
                  </ol>
                </div>
                <div className="col-sm-8" style={{display:'flex', justifyContent:'end'}}>
                  <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div>
                   
                  <div className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search" style={{display:'flex' }}>
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                        <button style={{paddingLeft:'5px', paddingRight:'5px', marginLeft:'10px'}} className='btn btn-primary' onClick={()=>{
                          myRef.current.childMethod()
                          setFilterData({})
                        }}><FeatherIcon icon="filter" size={14}></FeatherIcon></button>

                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <MyDataTable endpoint = "dispatch/get_dispatch" ref={myRef} col = {tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime} /> 
              {/* <MyDataTable endpoint = "dispatch/get_dispatch" col = {getUser().role == "2" ? tableColumnsDispatch : tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime} frameworkComponents= {frameworkComponents}/>  */}
            </div>
          </div>
        </div>
    )

}
