import React, { Component, useState } from 'react'
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getAmountComma, getNowDateFormatted, getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';
import { BtnDiscountCellRenderer } from '../components/ActionButtons';
import PrintReceipt from './printReceipt';

export default function MakePayment (){

  const [isLoading, setIsLoading] = useState(false)
  const [isApplying, setIsApplying] = useState(false)
  const [formData, setFormData] = useState({paid_amt:0})
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessageForm, setSuccessMessageForm] = useState('')
  const [errorMessageForm, setErrorMessageForm] = useState('')
  const [searchedPid, setSearchedPid] = useState('')
  const [selectedFile, setselectedFile] = useState('')
  const [printResponse, setPrintResponse] = useState({});
  const [isViewingPrint, setisViewingPrint] = useState(false);
  const [propResponse, setPropResponse] = useState(
    {
      pid: '',
      prop_use: '',
      occupant: '',
      asset_no: '',
      rating_dist: '',
      grand_total: 0,
    }
  );
 
  function isEmpty(str) {
    return !str.trim().length;
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const handleDateChange = date => {
    setFormData(prevState => ({
        ...prevState,
        date_paid: formatDate(date)
    }));
};


  async function getProperty(optionalPid){
    setErrorMessage('')
    setSuccessMessage('')
    console.log(searchedPid)
    console.log(optionalPid)
    if(optionalPid){
      setSearchedPid(optionalPid)
    }
    if(optionalPid == null && isEmpty(searchedPid)){
      return
    }
    setIsLoading(true)
    let object = {
      pid: optionalPid ? optionalPid : searchedPid
    }
    try {
      const headers = {
        "AccessToken": getUser().access_token
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}properties/get_property/`,
        object,{
          headers
        }
      );
      console.log(response.data)
      
      setPropResponse({
        pid: response.data.pid,
        prop_use: response.data.prop_use,
        occupant: response.data.occupant,
        asset_no: response.data.asset_no,
        prop_addr: response.data.prop_addr,
        cadastral_zone: response.data.cadastral_zone,
        group: response.data.group,
        annual_value: response.data.annual_value,
        rate_payable: response.data.rate_payable,
        rating_dist: response.data.rating_dist,
        grand_total: response.data.grand_total,
        year:response.data.year,
      })
      setIsLoading(false)

    } catch (error) {
      if(error.response.data){
        console.log(error.response.data.message)
        setErrorMessage(error.response.data.message)
        setIsLoading(false)
        return
      }
      setErrorMessage("Something went wrong")
      setIsLoading(false)
    }
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  async function createPayment (){
    console.log(searchedPid)
    if(isEmpty(searchedPid)){
      return
    }
    setIsApplying(true)
    let object = {
      "pid": searchedPid, 
      "paid_amt": Number(formData.paid_amt),
      "current_amt": propResponse.grand_total,
      "balance": propResponse.grand_total - Number(formData.paid_amt),
      "date_paid": formData.date_paid,
      "teller_no": formData.teller_no,
      "bank": formData.bank,
      "month": new Date().getMonth() + 1, 
      "year": new Date().getFullYear(), 
      "transaction_id": formData.teller_no,
      "created_by": getUser().user_id,
      "rating_dist": propResponse.rating_dist,
      "migrate_year": propResponse.year,
    } 
    try {
      console.log(object)
      const headers = {
        "AccessToken": getUser().access_token
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}fmake/create_payment/`,
        object,{
          headers
        }
      );
      console.log(response.data)
      setIsApplying(false)
      setSuccessMessageForm("Payment Created Successfully")
      setPrintResponse(response.data.fpage)
      setisViewingPrint(true)
      setFormData({
        paid_amt:0,
        date_paid: "",
        teller_no:"",
        bank:""
      })
      setPropResponse({
        pid: "",
        prop_use: "",
        occupant: "",
        asset_no: "",
        prop_addr: "",
        cadastral_zone: "",
        rating_dist: "",
        group: "",
        annual_value: 0,
        rate_payable: 0,
        grand_total: 0,
        year:"",
      })
      setSearchedPid("")


    } catch (error) {
      if(error.response){
        console.log(error.response.data.message)
        setErrorMessage(error.response.data.message)
        setIsApplying(false)
        return
      }
      setErrorMessage("Something went wrong")
      setIsLoading(false)
    }
  }

 function handleFileSelected(event){
    setselectedFile(event.target.files[0])
  }

  async function uploadFile(){
    setIsLoading(true)
    const formData = new FormData() 
    formData.append('amacfile', selectedFile)
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(selectedFile);
      let url = `${process.env.REACT_APP_API}fmake/bulk_payment/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      setSuccessMessage(`Bulk Payments Made Successfully`)
      setIsLoading(false)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setSuccessMessage(error.response.data.message)
        setIsLoading(false)
        return
      }
      setSuccessMessage(`Something went wrong`)
      setIsLoading(false)
    }

  }

  function closeModal(){
    setisViewingPrint(false)
  }
  function loadingPage(value){
    setIsApplying(value)
  }
  
  useTitleFunctional("Make Payment | AMAC Tenement")
  useLastPage("makepayment")
    return (
          <div className="page-body">
            { isApplying ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          {isViewingPrint ?
            
            <PrintReceipt pageLoader={loadingPage} closeModal={closeModal} propResponse={printResponse}/> 
         
          : <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                    <h3>Make Payments</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Make Payments</a></li>
                    {/* <li className="breadcrumb-item">Supervisors</li> */}
                  </ol>
                </div> 
                <div className="col" style={{display:'flex', justifyContent:'end', alignItems:'center'}}>
                <input class="form-control mb-20" style={{margin:'0px', maxWidth:'250px'}} type="file" onChange={(e)=>{
                      handleFileSelected(e)
                    }} aria-label="file example" required=""/>
                <button style={{width:'210px'}} className="btn btn-primary m-10" onClick={()=>{
                  uploadFile()
                }} 
                type="submit">Bulk Payment</button>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid" style={{marginTop:'20px'}}>
            <div className="row">
              <div className='col-md-7'>
              <div className='card p-20'>

                  <div className="pb-20 d-flex" style={{justifyContent:'space-between'}}>
                    <h5 style={{fontWeight:'600', padding:'10px 0'}} >Property Details</h5>
                    {isLoading ? <p className='p-10'>Fetching...</p>: <div></div> }
                    {successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong style={{marginRight:'20px'}}>{successMessage}</strong>
                      <button class="btn-close"  onClick={()=>setSuccessMessage('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {errorMessage !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong style={{marginRight:'20px'}}>{errorMessage}</strong>
                      <button class="btn-close" onClick={()=>setErrorMessage('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                  </div>
                  <div className='row' style={{display:'flex', alignItems:'end'}}>
                    <div class="input-group">
                      <input class="form-control" type="text" placeholder="Enter PID you want to find" value={searchedPid} disabled={isLoading} aria-label="Recipient's username" onChange={(e)=>{
                          setSearchedPid(e.target.value)
                        }}/><span class="input-group-text btn-primary" onClick={()=>{
                        console.log("clicked")
                        
                        getProperty(null)
                      }}><i class="icofont icofont-search"> </i></span>
                    </div>
                    <div className='d-flex' style={{marginTop:'20px'}}>
                      <div className="col-md-4 p-10">
                        <label className="control-label">Name of Occupier</label>
                        <input className="form-control" type="text" value={propResponse.occupant} disabled placeholder="Occupier Name" required="required"  
                          
                        />
                      </div>
                      <div className="col-md-4 p-10">
                        <label className="control-label">Asset Number</label>
                        <input className="form-control" type="text" value={propResponse.asset_no} disabled placeholder="Asset Number" required="required"  
                      
                              />
                      </div>
                      <div className="col-md-4 p-10">
                        <label className="control-label">Rating Dist</label>
                        <input className="form-control" type="text" value={propResponse.rating_dist} disabled placeholder="Rating District" required="required"  
                      
                              />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 p-10">
                        <label className="control-label">Property Address</label>
                        <textarea className="form-control" style={{width:'100%'}} disabled type="text" value={propResponse.prop_addr}  placeholder="Property Address" required="required"></textarea>
                      </div>
                      
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-6 p-10">
                        <label className="control-label">Cadastral Zone</label>
                        <input className="form-control" type="text" value={propResponse.cadastral_zone} disabled placeholder="Cadastral Zone" required="required"  
                          
                        />
                      </div>
                      <div className="col-md-6 p-10">
                        <label className="control-label">Group</label>
                        <input className="form-control" type="text" value={propResponse.group} disabled placeholder="Group" required="required"  
                      
                              />
                      </div>

                    </div>
                    <div className='d-flex'>
                      <div className="col-md-4 p-10">
                        <label className="control-label">Property Value</label>
                        <input className="form-control" type="text" value={getAmountComma(propResponse.annual_value)} disabled placeholder="Property Value" required="required"  
                          
                        />
                      </div>
                      <div className="col-md-4 p-10">
                        <label className="control-label">Rate Payable</label>
                        <input className="form-control" type="text" value={getAmountComma(propResponse.rate_payable)} disabled placeholder="Rate Payable" required="required"  
                      
                              />
                      </div>
                      <div className="col-md-4 p-10">
                        <label className="control-label">Grand Total</label>
                        <input className="form-control" type="text" value={getAmountComma(propResponse.grand_total)} disabled placeholder="Grand Total" required="required"  
                      
                              />
                      </div>
                    </div>
                    
                  </div>

                  </div>
                
              </div> 
              <div className='col-md-5'>
          
                <div className='card p-20'>

                  <div className="pb-20 d-flex" style={{justifyContent:'space-between'}}>
                    <h5 style={{fontWeight:'600', padding:'10px 0'}} >Payment Form</h5>
                    {successMessageForm !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong style={{marginRight:'20px'}}>{successMessageForm}</strong>
                      <button class="btn-close"  onClick={()=>setSuccessMessageForm('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {errorMessageForm !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong style={{marginRight:'20px'}}>{errorMessageForm}</strong>
                      <button class="btn-close" onClick={()=>setErrorMessageForm('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                  </div>
                  <div className='row' style={{display:'flex', alignItems:'end'}}>
                   
                    <div className='d-flex'>
                      <div className="col-md-12 p-10">
                        <label className="control-label">Enter Teller / Transaction Number</label>
                        <input className="form-control" type="text" value={formData.teller_no}  placeholder="Teller / Transaction Number" required="required"  
                          name='teller_no' onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 p-10">
                        <label className="control-label">Enter Bank Name</label>
                        <input className="form-control" type="text" value={formData.bank}  placeholder="Bank Name" required="required"  
                           name='bank' onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 p-10 myinputdate2">
                      <label className="control-label">Enter Payment Date</label> 
                        <DatePicker placeholderText='Payment Date' name='date_paid' value={formData.date_paid}  onChange={handleDateChange} />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 p-10">
                        <label className="control-label">Enter Payment Amount</label>
                        <input className="form-control" type="text" value={formData.paid_amt}  placeholder="Payment Amount" required="required"  
                          name='paid_amt' onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 p-10 d-flex" style={{justifyContent:'space-between'}}>
                        <label className="control-label">BALANCE</label>
                        <label className="control-label">₦{getAmountComma(propResponse.grand_total - Number(formData.paid_amt))}</label>
                        
                      </div>
                    </div>
                    
                    
                    
                    <div  className='d-flex'><button className="btn btn-primary m-10" onClick={()=>{
                          createPayment()
                        }} 
                        type="submit">Make Payment</button></div>
                  </div>
      
                </div>
            
              </div> 
            </div>
          </div>
        </div>
    )

}
