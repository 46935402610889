import React, { Component, useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import BtnCellRenderer, { BtnCellRendererViewOnly } from '../components/ActionButtons';
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';

export default function Prosecuted (){
  let navigate = useNavigate();
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'RATE PAY',field: 'grand_total',valueFormatter: params => getAmountComma(params.value)},
    // { headerName:'ACTION',field: 'action'},
  ]
  const tableColumnsLegal = [
    { headerName:'PID',field: 'pid'},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'}, 
    { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'RATE PAY',field: 'grand_total',valueFormatter: params => getAmountComma(params.value)},
    {
      headerName:'Action',
      field: "pid",
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function(field,id) {
          console.log(parseInt(field.slice(3)), id)
          if(id === 'edit'){
            navigate("/createprosecuted", { state:{ pid: field } });
          }
          if(id === 'view'){
            navigate("/viewprosecuteddetails", { state:{ pid: field} });
          }
        }
      },
      maxWidth:300
    },
  ]

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  const frameworkComponents = {
    btnCellRenderer: BtnCellRenderer
  }

  useTitleFunctional("Prosecuted | AMAC Tenement")
  useLastPage("prosecuted")
  
    return (
          <div className="page-body">
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Prosecuted</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Reminders</a></li>
                    <li className="breadcrumb-item">Prosecuted</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>
                  {/* <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div> */}
                   
                  <form className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </form>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <MyDataTable endpoint = "reminder/get_rem_pros" col = { getUser().role == "7" ? tableColumnsLegal : tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime} frameworkComponents= {frameworkComponents}/> 
            </div>
          </div>
        </div>
    )

}
