import axios from 'axios';
import React, { Component, useEffect, useState } from 'react'
import { ShimmerThumbnail } from "react-shimmer-effects";
import Print from './print';
import "react-datepicker/dist/react-datepicker.css";
import { BtnCellRendererViewOnly } from '../components/ActionButtons';
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';
import PrintReminder from './printReminder';

export default function ReminderDistrict (){
  // const tableColumns = [
  //   { headerName:'PID',field: 'pid'},
  //   { headerName:'OCCUPANT',field: 'occupant'},
  //   { headerName:'ADDRESS',field: 'prop_addr'},
  //   { headerName:'ASSET NO.',field: 'asset_no'},
  //   { headerName:'ZONE.',field: 'cadastral_zone'},
  //   { headerName:'PROP TYPE.',field: 'prop_type'},
  //   { headerName:'PROP USE',field: 'prop_use'},
  //   { headerName:'RATING DIST',field: 'rating_dist'},
  //   { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
  //   { headerName:'RATE PAY',field: 'grand_total',valueFormatter: params => getAmountComma(params.value)},
  //   { headerName:'ACTION',field: 'action'},
  //   {
  //     headerName:'Print',
  //     field: "pid",
  //     cellRenderer: "btnCellRenderer",
  //     cellRendererParams: {
  //       clicked: function(field,id) {
  //         console.log(parseInt(field.slice(3)), id)
  //         if(id === 'view'){
  //           setIsViewing(true)
  //           getProperty((parseInt(field.slice(3))).toString())
  //         }
  //       }
  //     },
  //     maxWidth:100
  //   },
  // ]
  const tableColumns2 = [
    { headerName:'PID',field: 'pid'},
    { headerName:'STREET',field: 'street_name'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    { headerName:'GRAND TOTAL',field: 'grand_total',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'TYPE',field: 'type'},
    { headerName:'DATE',field: 'date_delivered'},
    { headerName:'RECEIVED BY',field: 'received_by'},
    { headerName:'MODE',field: 'mode'},
  ]

  const [propResponse, setPropResponse] = useState({});
  const [isViewing, setIsViewing] = useState(false);
  const [isViewingPrint, setIsViewingPrint] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");
  const [ratingDist, setRatingDist] = useState("");
  const [districts, setdistricts] = useState([]);

  async function getDistricts () {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}hoo/get_dist/`,
        {headers})
        
        const dataList = response.data.data;
        console.log(dataList)
        setdistricts(dataList)
        setIsLoading(false)
  
    } catch (error) {
      console.log(error)
    }
  }

  

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  useEffect(() => {
    getDistricts()
  }, []);


  useTitleFunctional("Reminder Notice | AMAC Tenement")
  useLastPage("reminderbydistrict")
  
    return (
          <div className="page-body">
           { isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Reminder Notice by District</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Bills</a></li>
                    <li className="breadcrumb-item">Reminder Notice by District</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>
                  {/* <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div> */}
                   <div>
                    <label class="control-label" for="exampleFormControlSelect9">Select District</label>
                    <select class="form-select digits" id="exampleFormControlSelect9" onChange={(e)=>{
                        setRatingDist(e.target.value)
                        setRand(new Date().getMilliseconds().toString())
                      }}>
                        <option value="" >All Districts</option>
                        {districts.map((district)=>{
                          return <option value={district} >{district}</option>
                        })}
                        {/* <option value="1" >ACTIVE</option>
                        <option value="2" >VACANT</option>
                        <option value="3">UNDER CONSTRUCTION</option>
                        <option value="4" >EMPTY LAND</option>
                        <option value="5" >EXEMPTED</option> */}
                    </select>
                   </div>
                  <form className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </form>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">

              <MyDataTable endpoint = "hoo/get_reminder" col = {tableColumns2} search = {searchTerm} startDate={startDateReq} rating_dist={ratingDist} endDate={endDateReq} key= {randTime} />

            </div>
          </div>
        </div>
    )

}
