import React, { Component, useState } from 'react'
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css";
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';
import { BtnDiscountCellRenderer } from '../components/ActionButtons';

export default function Discounts (){

  const [isLoading, setIsLoading] = useState(false)
  const [isApplying, setIsApplying] = useState(false)
  const [randTime, setRand] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [calculatedDiscount, setCalculatedDiscount] = useState(0)
  const [percentDiscount, setPercentDiscount] = useState(0)
  const [searchedPid, setSearchedPid] = useState('')
  const [propResponse, setPropResponse] = useState(
    {
      pid: '',
      prop_use: '',
      occupant: '',
      asset_no: '',
      rating_dist: '',
      grand_total: 0,
    }
  );
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'ASSET NO',field: 'asset_no'},
    { headerName:'TYPE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    { headerName:'GRAND TOTAL',field: 'grand_total', valueFormatter: params => getAmountComma(params.value)},
    {
      headerName:'Action',
      field: "pid",
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function(field,id) {
          console.log(parseInt(field.slice(3)), id)
          if(id === 'discount'){
            setSearchedPid((parseInt(field.slice(3))).toString())
            getProperty((parseInt(field.slice(3))).toString());

          }
        }
      },
      maxWidth:100
    },
  ]
  const frameworkComponents = {
    btnCellRenderer: BtnDiscountCellRenderer
  }
  function isEmpty(str) {
    return !str.trim().length;
  }


  async function getProperty(optionalPid){
    setErrorMessage('')
    setSuccessMessage('')
    console.log(searchedPid)
    console.log(optionalPid)
    if(optionalPid){
      setSearchedPid(optionalPid)
    }
    if(optionalPid == null && isEmpty(searchedPid)){
      return
    }
    setIsLoading(true)
    let object = {
      pid: optionalPid ? optionalPid : searchedPid
    }
    try {
      const headers = {
        "AccessToken": getUser().access_token
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}discount/get_property/`,
        object,{
          headers
        }
      );
      console.log(response.data)
      
      setPropResponse({
        pid: response.data.pid,
        prop_use: response.data.prop_use,
        occupant: response.data.occupant,
        asset_no: response.data.asset_no,
        rating_dist: response.data.rating_dist,
        grand_total: response.data.grand_total,
      })
      setIsLoading(false)

    } catch (error) {
      if(error.response.data){
        console.log(error.response.data.message)
        setErrorMessage(error.response.data.message)
        setIsLoading(false)
        return
      }
      setErrorMessage("Something went wrong")
      setIsLoading(false)
    }
  }

  async function createDiscount (){
    console.log(searchedPid)
    if(isEmpty(searchedPid)){
      return
    }
    setIsApplying(true)
    let object = {
      "percent": percentDiscount,
      "discount_value": calculatedDiscount,
      "prop_id": searchedPid,
      "value_before_discount": propResponse.grand_total,
    } 
    try {
      console.log(object)
      const headers = {
        "AccessToken": getUser().access_token
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}discount/create_discount/`,
        object,{
          headers
        }
      );
      console.log(response.data)
      setIsApplying(false)
      setSuccessMessage("Discount Applied Succesfully")
      setRand(new Date().getMilliseconds().toString())

    } catch (error) {
      if(error.response){
        console.log(error.response.data.message)
        setErrorMessage(error.response.data.message)
        setIsLoading(false)
        return
      }
      setErrorMessage("Something went wrong")
      setIsLoading(false)
    }
  }
  
  useTitleFunctional("Discounts | AMAC Tenement")
  useLastPage("discounts")
    return (
          <div className="page-body">
            { isApplying ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                    <h3>Discounts</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Discounts</a></li>
                    {/* <li className="breadcrumb-item">Supervisors</li> */}
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>
                  {/* <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div> */}
                   
                  {/* <form className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </form> */}
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid" style={{marginTop:'20px'}}>
            <div className="row">
              <div className='col-md-7'>
                <div className='card p-10' style={{height: 'calc(100vh - 220px)'}}>
                  <div className="pb-20">
                    <h5 style={{fontWeight:'600', padding:'10px 0'}} >Overview</h5>
                  </div>
                  <MyDataTable endpoint = "discount/get_properties" col = {tableColumns} 
                  search = {searchTerm}  key= {randTime} frameworkComponents= {frameworkComponents}
                  />
                </div>
                
              </div> 
              <div className='col-md-5'>
          
                <div className='card p-20'>

                  <div className="pb-20 d-flex" style={{justifyContent:'space-between'}}>
                    <h5 style={{fontWeight:'600', padding:'10px 0'}} >Discount</h5>
                    {isLoading ? <p className='p-10'>Fetching...</p>: <div></div> }
                    {successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong style={{marginRight:'20px'}}>{successMessage}</strong>
                      <button class="btn-close"  onClick={()=>setSuccessMessage('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {errorMessage !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong style={{marginRight:'20px'}}>{errorMessage}</strong>
                      <button class="btn-close" onClick={()=>setErrorMessage('')} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                  </div>
                  <div className='row' style={{display:'flex', alignItems:'end'}}>
                    <div class="input-group">
                      <input class="form-control" type="text" placeholder="Search PID" value={searchedPid} disabled={isLoading} aria-label="Recipient's username" onChange={(e)=>{
                          setSearchedPid(e.target.value)
                        }}/><span class="input-group-text btn-primary" onClick={()=>{
                        console.log("clicked")
                        
                        getProperty(null)
                      }}><i class="icofont icofont-search"> </i></span>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-6 p-10">
                        <label className="control-label">PID</label>
                        <input className="form-control" type="text" value={propResponse.pid} disabled placeholder="PID" required="required"  
                          
                        />
                      </div>
                      <div className="col-md-6 p-10">
                        <label className="control-label">Asset Number</label>
                        <input className="form-control" type="text" value={propResponse.asset_no} disabled placeholder="Asset Number" required="required"  
                       
                              />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-6 p-10">
                        <label className="control-label">Occupant</label>
                        <input className="form-control" type="text" value={propResponse.occupant} disabled placeholder="Occupant" required="required"  
                        
                              />
                      </div>
                      <div className="col-md-6 p-10"> 
                        <label className="control-label">Property Use</label>
                        <input className="form-control" type="text" value={propResponse.prop_use} disabled placeholder="Property Use" required="required"  
                     
                              />
                      </div>
                    </div>
                    <div className='d-flex' style={{alignItems:'end'}}>
                      <div className="col-md-6 p-10">
                        <label className="control-label">Percentage Discount</label>
                        <input className="form-control" type="text" placeholder="Enter Discount" required="required"  
                        onChange={(e)=>{
                                setPercentDiscount(Number(e.target.value))
                                setCalculatedDiscount(Number((propResponse.grand_total - (propResponse.grand_total * (Number(e.target.value)/100))).toFixed(2)))
                              }}
                        />
                              
                      </div>
                      <div className="col-md-6 p-10"> 
                        <label className="control-label">% X <span style={{display:'inline-block',fontWeight:'bold', fontSize:'20px', paddingLeft:'15px'}}>{`₦${getAmountComma(propResponse.grand_total)}`}</span></label>
                        
                      </div>
                      
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 p-10">
                        <label className="control-label">Calculated Discount</label>
                        <input className="form-control" type="text" value={`₦${getAmountComma(calculatedDiscount)}`} placeholder="Calculated Discount" disabled required="required"  

                              />
                              
                      </div>
                      
                    </div >
                    <div  className='d-flex'><button className="btn btn-primary m-10" onClick={()=>{
                          createDiscount()
                        }} 
                        type="submit">Apply Discount</button></div>
                  </div>
      
                </div>
            
              </div> 
            </div>
          </div>
        </div>
    )

}
