import React, { Component, useRef, useState } from 'react'
import Header from '../components/Header'
import axios from 'axios'
import MyDataTable from '../components/MyDataTable';
import BtnCellRenderer, { BtnCellRendererViewEditDelete } from '../components/ActionButtons';
import { getAmountComma, getFilterData, getUser, saveLastID, setFilterData, useLastPage, useTitleFunctional } from '../functions/Reusables';
import { Link, useNavigate } from 'react-router-dom';
import { ShimmerThumbnail } from "react-shimmer-effects";
import Print from './print';
import PrintBulk from './printbulk';
import FeatherIcon from 'feather-icons-react';
import MyBasicDataTable from '../components/MyBasicDataTable';
import MyBasicDataTable2 from '../components/MyBasicDataTable2';
import ClockLoader from "react-spinners/ClockLoader"; 

export default function AllProperties (){
  let navigate = useNavigate();
  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [startDateReq, setStartReqDate] = useState("");
  // const [endDateReq, setEndReqDate] = useState("");
  const [isFloatingFilterEnabled, setIsFloatingFilterEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isViewing, setIsViewing] = useState(false);
  const [isViewingPrint, setIsViewingPrint] = useState(false);
  const [isPrintingGroup, setIsPrintingGroup] = useState(false);
  const [propResponse, setPropResponse] = useState({});
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoadingExcel1, setIsLoadingExcel1] = useState(false);
  const [isLoadingExcel2, setIsLoadingExcel2] = useState(false);
  const [isLoadingcsv1, setIsLoadingcsv1] = useState(false);
  const [isLoadingcsv2, setIsLoadingcsv2] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasLoadedExcel, setHasLoadedExcel] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeletedSuccessfully, setIsDeletedSuccessfully] = useState(false);
  const [isDeletedUnSuccessfully, setIsDeletedUnSuccessfully] = useState(false);
  const [deletePID, setDeletePID] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [propertydata, setPropertData] = useState([]);

  const myRef = useRef()

  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'Occupant',field: 'occupant'},
    { headerName:'Rating Dist',field: 'rating_dist'},
    { headerName:'Asset No.',field: 'asset_no'},
    { headerName:'Zone',field: 'cadastral_zone'},
    { headerName:'Prop. Use',field: 'prop_use'},
    { headerName:'Prop. Type',field: 'prop_type'},
    { headerName:'Address',field: 'prop_addr'},
    { headerName:'Annual',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'Rate Pay',field: 'rate_payable',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'Special',field: 'active', }, 
    {
      headerName:'Action',
      field: "pid",
      shouldAction:true,
      cellRenderer: getUser().role == '13' ? "btnCellRendererit" : "btnCellRenderer",
      cellRendererParams: {
        clicked: function(field,id) {
          console.log(parseInt(field.slice(3)), id)
          if(id === 'view'){
            setIsViewing(true)
            getProperty((parseInt(field.slice(3))).toString())
          }
          if(id === 'edit'){
            saveLastID((parseInt(field.slice(3))).toString());
            navigate("/editproperty"); 
          }
          if(id === 'delete'){
            console.log('deleting')
            console.log(field)
            setDeletePID(field)
            setConfirmDelete(true)
          }
        }
      },
      width:120,
      minWidth:120
    },
  ]
  const tableColumns2 = [
    { headerName:'PID',field: 'pid'},
    { headerName:'Occupant',field: 'occupant'},
    { headerName:'Rating Dist',field: 'rating_dist'},
    { headerName:'Asset No.',field: 'asset_no'},
    { headerName:'Zone',field: 'cadastral_zone'},
    { headerName:'Prop. Use',field: 'prop_use'},
    { headerName:'Prop. Type',field: 'prop_type'},
    { headerName:'Address',field: 'prop_addr'},
    { headerName:'Annual',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'Rate Pay',field: 'rate_payable',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'Special',field: 'active'}, 
    { headerName:'',field: 'none',shouldAction:true, maxWidth:80}, 
    
  ]
  const tableColumns3 = [
    { headerName:'pid',field: 'pid'},
    { headerName:'occupant',field: 'occupant'},
    { headerName:'rating_dist',field: 'rating_dist'},
    { headerName:'asset_no',field: 'asset_no'},
    { headerName:'cadastral_zone',field: 'cadastral_zone'},
    { headerName:'prop_use',field: 'prop_use'},
    { headerName:'prop_type',field: 'prop_type'},
    { headerName:'prop_addr',field: 'prop_addr'},
    { headerName:'group',field: 'group'},
    { headerName:'street_name',field: 'street_name'},
    { headerName:'category',field: 'category'},
    { headerName:'annual_value',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'rate_payable',field: 'rate_payable',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'arrears',field: 'arrears',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'penalty',field: 'penalty',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'grand_total',field: 'grand_total',valueFormatter: params => getAmountComma(params.value)}, 
    { headerName:'active',field: 'active'}, 
    // { headerName:'',field: 'none',shouldAction:true, maxWidth:80}, 
    
  ]

 

  function closeModal(){
    setIsViewingPrint(false)
  }

  async function getProperty(pid){
    setIsLoading(true)
    const requestPID = {
      pid: pid,

    };
    const headers = {
      "AccessToken":getUser().access_token
    }
    try {
      console.log(requestPID)
      const response = await axios.post(
        `${process.env.REACT_APP_API}properties/get_property/`,
        requestPID,
        {headers}
      );
      setPropResponse({
        "pid": response.data.pid,
        "occupant": response.data.occupant,
        "prop_addr": response.data.prop_addr,
        "street_name": response.data.street_name,
        "asset_no": response.data.asset_no,
        "cadastral_zone": response.data.cadastral_zone,
        "prop_type": response.data.prop_type,
        "prop_use": response.data.prop_use,
        "rating_dist": response.data.rating_dist,
        "annual_value": response.data.annual_value,
        "rate_payable": response.data.rate_payable,
        "arrears": response.data.arrears,
        "penalty": response.data.penalty,
        "grand_total": response.data.grand_total,
        "category": response.data.category,
        "group": response.data.group,
        "active": response.data.active,
        "year": response.data.year,
      })
      setIsLoading(false)
      
    } catch (error) {
      const errors = []
      console.log(error)
      errors.push("Something went wrong")
      
      if(error.response !== null || error.response !== undefined){
        errors.push(error.response.data.message)
      }
      console.log(errors.toString())
    }
  }
  async function deleteProperty(){
    setIsLoading(true)
    const requestPID = {
      pid: parseInt(deletePID.slice(3)),

    };
    const headers = {
      "AccessToken":getUser().access_token
    }
    try {
      console.log(requestPID)
      const response = await axios.post(
        `${process.env.REACT_APP_API}properties/del_property/`,
        requestPID,
        {headers}
      );
      console.log(response)
      myRef.current.childMethod()
      setFilterData({})
      setValue("")
      setRand(new Date().getMilliseconds().toString())
      console.log(response.data)
      console.log(response.data['message'])
      if(response.data['message'] == "True"){
        setIsDeletedSuccessfully(true)
      }else{
        setIsDeletedUnSuccessfully(true)
      }
      // setIsDeletedSuccessfully(true)
      setIsLoading(false)
      
    } catch (error) {
      const errors = []
      console.log(error)
      errors.push("Something went wrong")
      
      if(error.response !== null || error.response !== undefined){
        errors.push(error.response.data.message)
      }
      console.log(errors.toString())
    }
  }

  const frameworkComponents = {
    btnCellRenderer: BtnCellRendererViewEditDelete,
    btnCellRendererit: BtnCellRenderer,
  }

  async function getPropertyData(file){
    setIsLoadingFile(true)
    const formData = new FormData() 
    
    formData.append('amacfile', file) 
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(selectedFile);
      let url = `${process.env.REACT_APP_API}properties/prop_view/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data.data)
      setPropertData(response.data.data)
      setHasLoadedExcel(true)
      setIsLoadingFile(false)
      // console.log(response.data)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setIsLoadingFile(false)
        return
      }
      setIsLoadingFile(false)
    }
  }

  async function handleFileSelected(event){
    setSelectedFile( event.target.files[0])
    await getPropertyData(event.target.files[0])
    setIsDisabled(false)
  }

  function handleClear(event){
    document.getElementById("spoolID").value=null; 
    setSelectedFile("")
    setIsDisabled(true)
    setHasLoadedExcel(false)
    setRand(new Date().getMilliseconds().toString())
  }

  async function exportGroup(group, type){
    let url = `${process.env.REACT_APP_API}properties/get_properties/?ext_type=${type}&ext_group=${group}`;
      
      let access_token =  getUser().access_token
      const headers ={
        'AccessToken':access_token
      }
      console.log(url);
      try {
        const response = await axios.get(
          url,
           {headers})
        console.log(response.data.message)
          window.open(`${process.env.REACT_APP_API}${response.data.message}`)

      } catch (error) {
        console.log(error)
      }
  }

 function closeReminderAlert(){
    setConfirmDelete(false)
    setIsDeletedSuccessfully(false)
    setIsDeletedUnSuccessfully(false)
  }

  useTitleFunctional("Properties | AMAC Tenement")
  useLastPage("viewproperties")
  
    return (
      // <div className="page-wrapper compact-wrapper" id="pageWrapper">
      //   <Header/>
      //   <div className="page-body-wrapper sidebar-icon">
      //     <Sidebar/>
      
          <div className="page-body">
            {isDeletedSuccessfully ? <div className="swal-overlay swal-overlay--show-modal" tabindex="-1" >
          <div className="swal-modal" role="dialog" aria-modal="true">
          <div class="swal-icon swal-icon--success">
          <span class="swal-icon--success__line swal-icon--success__line--long"></span>
          <span class="swal-icon--success__line swal-icon--success__line--tip"></span>

          <div class="swal-icon--success__ring"></div>
          <div class="swal-icon--success__hide-corners"></div>
        </div>
            <div className="swal-title">Property Deleted Successfully</div>
            {/* <p>The following property(ies)</p> */}
            {/* <p>{this.trimResponse(this.state.successModalResponse)}</p> */}
            <div className="swal-footer">
              <div className="swal-button-container" style={{display:'flex', justifyContent:'end'}}>
                <button className="btn" style={{color:'grey'}} onClick={()=>{closeReminderAlert()}} >OK</button>
                
                <div className="swal-button__loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>: <div></div>}

             {confirmDelete ? <div className="swal-overlay swal-overlay--show-modal" tabindex="-1" onClick={()=>{closeReminderAlert()}} >
          <div className="swal-modal" role="dialog" aria-modal="true">
            <div className="swal-icon swal-icon--warning">
              <span className="swal-icon--warning__body">
                <span className="swal-icon--warning__dot"></span>
              </span>
            </div>
            <div className="swal-title">Delete Property!</div>
            <div className="swal-text" style={{textAlign:'center'}}>You are about to delete the property with PID {deletePID}. <br/>
             <b> Are you sure you want to DELETE this property. This process CANNOT be undone?</b>
            </div>
            <div className="swal-footer">
              <div className="swal-button-container">
                <button className="btn" style={{color:'grey'}} onClick={()=>{closeReminderAlert()}} >Cancel</button>
                <button className="btn btn-danger" onClick={()=>{
                  // setisPrintingBulk(true)
                  deleteProperty()
                }}>Confirm Delete</button>
                <div className="swal-button__loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>: <div></div>}
             {isDeletedUnSuccessfully ? <div className="swal-overlay swal-overlay--show-modal" tabindex="-1" onClick={()=>{closeReminderAlert()}} >
          <div className="swal-modal" role="dialog" aria-modal="true">
            <div className="swal-icon swal-icon--warning">
              <span className="swal-icon--warning__body">
                <span className="swal-icon--warning__dot"></span>
              </span>
            </div>
            <div className="swal-title">Delete Property Failed!</div>
            <div className="swal-text" style={{textAlign:'center'}}>The deletion process failed 
            </div>
            <div className="swal-footer">
              <div className="swal-button-container">
                <button className="btn" style={{color:'grey'}} onClick={()=>{closeReminderAlert()}} >Cancel</button>
               
                <div className="swal-button__loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>: <div></div>}
            { isViewing ?<div class="modal fade show" id="exampleModalCenter" style={{display:'block', backgroundColor:'#0000004a',overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    {isLoading ? <ShimmerThumbnail height={30} className='myShimmerOveride' rounded /> :<h5 class="modal-title">{propResponse.pid}</h5>}
                    <button class="btn-close" type="button" onClick={()=>setIsViewing(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className='p-20'>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Occupant</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.occupant}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Assessment Number</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.asset_no}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Rating District</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.rating_dist}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Cadastrial Zone</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.cadastral_zone}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Use</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.prop_use}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Type</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.prop_type}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Category</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.category}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Address</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.prop_addr}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Special Action</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.active}</p>}</div>
                    </div>
                    <p className=" m-0">VALUES</p>
                    <div style={{height:'1px', width:'100%', backgroundColor:'#80808042', marginTop:'5px',marginBottom:'5px'}}></div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Annual Value</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.annual_value)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Rate Payable</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.rate_payable)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Arrears</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.arrears)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Penalty</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.penalty)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Grand Total</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.grand_total)}</p>}</div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    {/* <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button> */}
                    <button  class="btn btn-primary" onClick={()=>{
                      setIsViewing(false)
                      setIsViewingPrint(true)
                    }} type="button">Print</button>
                  </div>
                </div>
              </div>
            </div>: <div></div>}
            { isViewingPrint ?<div class="modal fade show" id="exampleModalLong" style={{display:'block', backgroundColor:'#0000004a', overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'1000px'}}>
                  
                <div className="modal-content" id='printModal'>
                  <Print propResponse={propResponse} closeModal={closeModal} />
                </div>
              </div>
            </div>: <div></div>}

            {isPrintingGroup ? <div className="swal-overlay swal-overlay--show-modal" tabindex="-1"  >
          <div className="swal-modal" style={{width:'250px',borderRadius: '10px'}} role="dialog" aria-modal="true">
          {/* <div class="swal-icon swal-icon--success">
          <span class="swal-icon--success__line swal-icon--success__line--long"></span>
          <span class="swal-icon--success__line swal-icon--success__line--tip"></span>

          <div class="swal-icon--success__ring"></div>
          <div class="swal-icon--success__hide-corners"></div>
        </div> */}
            <div className="swal-title">Export Group</div>
            <p>Choose the Group to Export</p>
            <div style={{padding:'20px', display:'flex', flexDirection:'column',justifyContent:'center'}}>
            <button className="btn btn-primary" style={{display:'flex', marginBottom:'4px', padding: '7px 0px 7px 20px'}}  onClick={async()=>{
                  setIsLoadingExcel1(true)
                  await exportGroup('AMAC 1', 'excel') 
                  setIsLoadingExcel1(false)
                }}><span style={{marginRight:'10px'}}>Export AMAC 1 Excel</span>{isLoadingExcel1 ?<ClockLoader color={'#ffffff'} loading={isLoadingExcel1} cssOverride={{}} size={20} /> : <div></div>}
                </button>
                <button className="btn btn-primary" style={{display:'flex', marginBottom:'4px',padding: '7px 0px 7px 20px'}}  onClick={async ()=>{
                  setIsLoadingcsv1(true)
                  await exportGroup('AMAC 1', 'csv')
                  setIsLoadingcsv1(false)
                }}><span style={{marginRight:'10px'}}>Export AMAC 1 CSV</span>{isLoadingcsv1 ?<ClockLoader color={'#ffffff'} loading={isLoadingcsv1} cssOverride={{}} size={20} /> : <div></div>}
                </button>
                <button className="btn btn-secondary" style={{display:'flex', marginBottom:'4px',padding: '7px 0px 7px 20px'}}  onClick={async()=>{
                  setIsLoadingExcel2(true)
                  await exportGroup('AMAC 2', 'excel')
                  setIsLoadingExcel2(false)
                }}><span style={{marginRight:'10px'}}>Export AMAC 2 Excel</span>{isLoadingExcel2 ?<ClockLoader color={'#ffffff'} loading={isLoadingExcel2} cssOverride={{}} size={20} /> : <div></div>}
                </button>
                <button className="btn btn-secondary" style={{display:'flex', marginBottom:'10px',padding: '7px 0px 7px 20px'}}  onClick={async()=>{
                 setIsLoadingcsv2(true)
                 await exportGroup('AMAC 2', 'csv')
                 setIsLoadingcsv2(false)
                }}><span style={{marginRight:'10px'}}>Export AMAC 2 CSV</span>{isLoadingcsv2 ?<ClockLoader color={'#ffffff'} loading={isLoadingcsv2} cssOverride={{}} size={20} /> : <div></div>}
                </button>

                <button className="btn" style={{display:'flex', margin:'0 auto'}}  onClick={()=>{
                  setIsPrintingGroup(false)
                }}>Done
                </button>
            </div>
            {/* <p>{this.trimResponse(this.state.successModalResponse)}</p> */}
            
          </div>
        </div>: <div></div>}
            
          <div className="container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-4">
                  <h3>View Properties</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Properties</a></li>
                    <li className="breadcrumb-item">View Properties</li>
                  </ol>
                </div>
                <div className="col-sm-8" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <div style={{display:'flex', width:'100%', alignItems:'center'}}>
                    <div className='myInputDate'>
                      <p>Export Group: </p>

                         <button style={{width:'130px', marginBottom:'20px',height: '40px'}}  className="btn btn-primary pull-right" onClick={()=>{
                          setIsPrintingGroup(true)
                        }}  type="button">Export</button>
                    </div>

                  </div>
                <div style={{display:'flex', width:'100%', alignItems:'center'}}>
                    <div className='myInputDate'>
                      <p>Spool: {isLoadingFile ? <span>.. FETCHING ..</span>: <span></span>}</p>
                      <input class="form-control mb-20" id='spoolID' type="file" onChange={(e)=>{
                          handleFileSelected(e)
                        }} aria-label="file example" required=""/>
                    </div>
                    <div className='myInputDate'>
                      <p> </p>
                      {/* <button style={{width:'130px'}} disabled={isDisabled} className="btn btn-primary pull-right" onClick={()=>{
                          // uploadFile()
                        }}  type="button">Bulk Print</button> */}
                    </div>
                    <button className='btn btn-secondary' disabled={isDisabled} style={{padding:'5px', marginLeft:'5px'}} onClick={()=>{handleClear()}}>X</button>
                  </div>
                <div className=" search-form row"  style={{marginLeft:'10px'}}>
                  
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search" style={{display:'flex' }}>
                      <input type="text" style={{ borderRadius:'10px', width:'180px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                        <button style={{paddingLeft:'5px', paddingRight:'5px', marginLeft:'10px'}} className='btn btn-primary' onClick={()=>{
                          myRef.current.childMethod()
                          setFilterData({})
                        }}><FeatherIcon icon="filter" size={14}></FeatherIcon></button>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              {getUser().role == '9' || getUser().role == '10'  || getUser().role == '4' || getUser().role == '1' || getUser().role == '5' ?
              hasLoadedExcel ? <MyBasicDataTable2 col = {tableColumns3} data = {propertydata} /> : <MyDataTable endpoint = "properties/get_properties" ref={myRef} col = {tableColumns2} search = {searchTerm} key= {randTime} isFloatingFilterEnabled={isFloatingFilterEnabled} />
              :
              hasLoadedExcel ? <MyBasicDataTable2 col = {tableColumns3} data = {propertydata} /> :<MyDataTable endpoint = "properties/get_properties" ref={myRef} col = {tableColumns} search = {searchTerm} key= {randTime} isFloatingFilterEnabled={isFloatingFilterEnabled} frameworkComponents= {frameworkComponents} /> 
               }

            </div>
            </div>
            </div>
        // </div>
      // </div>
    )

}
