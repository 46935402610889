import React, { Component,useState,useEffect } from 'react'
import FeatherIcon from 'feather-icons-react';
import { ShimmerThumbnail,ShimmerTable } from "react-shimmer-effects";
import { getAmountComma, getHasSeenReminder, getPrintData, getUser, saveHasSeenReminder, saveLastPage, setPrintData, titleClass, useLastPage, useTitle, useTitleClass, useTitleFunctional } from '../functions/Reusables';
import axios from "axios";
import { PrintBulkReminder } from './printbulkreminder';




export default function  ZonalDashboard (props) {


  const [hasReminder, setHasReminder] = useState(false)
  const [isLoadingCards, setisLoadingCards] = useState(true)
  const [isLoadingTable1, setisLoadingTable1] = useState(true)
  const [isLoadingTable2, setisLoadingTable2] = useState(true)
  const [isLoadingTable3, setisLoadingTable3] = useState(true)
  const [isPrintingBulk, setisPrintingBulk] = useState(false)
  const [cardValues, setcardValues] = useState({})
  const [table1Values, settable1Values] = useState([])
  const [table2Values, settable2Values] = useState([])
  const [table3Values, settable3Values] = useState([])

   async function getCardData () {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}zonal/dash_zonal/`,
        {headers})
        const dataList = response.data.data;
        const newMap = {}
        for (let index = 0; index < dataList.length; index++) {
          let key = Object.keys(dataList[index])[0]
          let value = Object.values(dataList[index])[0]
          newMap[key] = value
        }
        setcardValues(newMap)
        setisLoadingCards(false)
    } catch (error) {
      console.log(error)
    }
  }

  async function getTableData2 () {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}dash/get_dashboard_dis/`,
        {headers})
        
        const dataList = response.data.data;
        settable2Values(dataList)
        setisLoadingTable2(false)

        console.log(table2Values)
  
    } catch (error) {
      console.log(error)
    }
  }
  async function getTableData1 () {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}zonal/dash_zonal_rem/`,
        {headers})
        
        const dataList = response.data.data;
        settable1Values(dataList)
        setisLoadingTable1(false)
  
    } catch (error) {
      console.log(error) 
    }
  }
  async function getTableData3 () {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}dash/get_dashboard2/`,
        {headers})
        
        const dataList = response.data.data;
        settable3Values(dataList)
        setisLoadingTable3(false)
  
    } catch (error) {
      console.log(error)
    }
  }

 async function getReminder(){
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}auto/get_auto/`,
        {headers})
        
        const printData = response.data;
        console.log(printData)
        if(printData.message == 0){
          setHasReminder(false)
          return
        }
        if(printData.message.length != 0){
          setHasReminder(true)
          setPrintData(printData)
          
          props.changeHeaderNotify(true)
        }
        
  
    } catch (error) {
      console.log(error)
    }
  }

 function closeReminderAlert (){
   setHasReminder(false)
  saveHasSeenReminder(true)
  }

 function closePrintModal(){
   setisPrintingBulk(false)

  }

  function loadComponents(){
    getCardData()
    getTableData2()
    getTableData1()
    getTableData3()
    console.log(getUser().role)
    console.log(getHasSeenReminder())

    if((getUser().role == "11" || getUser().role == "12" || getUser().role == "13") && (getHasSeenReminder() == false || getHasSeenReminder() == null)){
      getReminder()
    }
  }

  useEffect(() => {
    loadComponents()
  }, []);

  useTitleFunctional("Dashboard | AMAC Tenement")
  useLastPage("dashboard")

  // componentDidMount() {
  //   titleClass("Dashboard | AMAC Tenement")
  //   saveLastPage("dashboard")
  //   this.getCardData()
  //   this.getTableData2()
  //   this.getTableData1()
  //   this.getTableData3()
  //   // this.getReminder()
  // }


    return (
      <div>
        <div className="page-body">
        {hasReminder ? <div className="swal-overlay swal-overlay--show-modal" tabindex="-1" onClick={()=>{closeReminderAlert()}} >
          <div className="swal-modal" role="dialog" aria-modal="true">
            <div className="swal-icon swal-icon--warning">
              <span className="swal-icon--warning__body">
                <span className="swal-icon--warning__dot"></span>
              </span>
            </div>
            <div className="swal-title">Print Reminder Notice!</div>
            <div className="swal-text" style={{textAlign:'center'}}>You have pending print reminder notice. <br/>
             <b> Would you like to print them now?</b>
            </div>
            <div className="swal-footer">
              <div className="swal-button-container">
                <button className="btn" style={{color:'grey'}} onClick={()=>{closeReminderAlert()}} >Cancel</button>
                <button className="btn btn-primary" onClick={()=>{
                  setisPrintingBulk(true)

                }}>Confirm Print</button>
                <div className="swal-button__loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>: <div></div>}
        { isPrintingBulk ? <div className="modal fade show" id="exampleModalLong" style={{display:'block', backgroundColor:'#0000004a', overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'1000px'}}>
                  
                <div className="modal-content" id='printModal'>
                  <PrintBulkReminder propResponses={getPrintData().message} changeHeaderNotify={props.changeHeaderNotify} closeModal={()=>{
                    closePrintModal()
                  }} />
                </div>
              </div>
            </div>: <div></div>}
          <div className="container-fluid dashboard-default-sec">
          <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Dashboard</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                  </ol>
                </div>
                
              </div>
            </div>
            <div className="row" style={{marginTop:'20px'}}>
              <div className="col-xl-5 box-col-12 des-xl-100"> 
                <div className="row">
                  <div className="col-xl-12 col-md-6 box-col-6 des-xl-50">
                  {isLoadingCards ? <div className='card'><ShimmerThumbnail height={220} rounded className='myShimmerOveride' /></div> :
                    <div className="card profile-greeting">
                      <div className="card-header">
                        <div className="header-top">
                          <div className="text-center" style={{color:'white'}}>
                          <FeatherIcon icon="trending-up"  size={70}></FeatherIcon>
                          </div>
                        </div>
                      </div>
                      <div className="card-body text-center p-t-0">
                        <p>REVENUE COLLECTED</p>
                        
                        <h3 className="font-light">{`₦ ${cardValues.total}`}</h3>
                    
                      </div>
                      <div className="confetti">
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="code-box-copy">
                          <button className="code-box-copy__btn btn-clipboard" data-clipboard-target="#profile-greeting" title="Copy"><i className="icofont icofont-copy-alt"></i></button>
                      
                        </div>
                      </div>
                    </div> }
                  </div>
                  <div className="col-xl-12 col-md-6 box-col-6 des-xl-50">
                  {isLoadingCards ? <div className='card'><ShimmerThumbnail height={220} rounded className='myShimmerOveride' /></div> :<div className="card profile-greeting" style={{backgroundColor: "rgb(117, 181, 236)",backgroundImage: 'none'}}>
                      <div className="card-header">
                        <div className="header-top">
                          <div className="text-center" style={{color:'white'}}>
                          <FeatherIcon icon="target"  size={70}></FeatherIcon>
                          </div>
                        </div>
                      </div>
                      <div className="card-body text-center p-t-0">
                        <p>FINANCIAL TARGET</p>
                        <h3 className="font-light" style={{color:"black !important"}}>₦ 4,463,210,134.50</h3>
                      </div>
                      
                    </div>}
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 box-col-12 des-xl-100 dashboard-sec">
                <div className="row">
                  
                  
                  
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {isLoadingCards ? <div className='card' ><ShimmerThumbnail height={100} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={20} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                   
                      <div className="card-body text-center" style={{padding:'23px'}}>
                        <div className="">
                        <FeatherIcon icon="archive" fill='white' size={40}></FeatherIcon>
                        </div>
                        <h5>{`${cardValues.total_dispatch}`}</h5>
                        <p>DISPATCHED BILLS</p>
                        
                      </div>
                    </div>}
                  </div>
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {isLoadingCards ? <div className='card' ><ShimmerThumbnail height={100} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={20} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                   
                      <div className="card-body text-center" style={{padding:'23px'}}>
                        <div className="">
                        <FeatherIcon icon="home" fill='white' size={40}></FeatherIcon>
                        </div>
                        <h5>{`${cardValues.total_prop}`}</h5>
                        <p>TOTAL PROPERTIES</p>
                        
                      </div>
                    </div>}
                  </div>  
                  
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {isLoadingCards ? <div className='card' ><ShimmerThumbnail height={100} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={20} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                   
                      <div className="card-body text-center" style={{padding:'23px'}}>
                        <div className="">
                        <FeatherIcon icon="bell" fill='white' size={40}></FeatherIcon>
                        </div>
                        <h5>{`${cardValues['7days']}`}</h5>
                        <p>REMINDER 7 DAYS</p>
                        
                      </div>
                    </div>}
                  </div>
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {isLoadingCards ? <div className='card' ><ShimmerThumbnail height={100} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={20} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                   
                      <div className="card-body text-center" style={{padding:'23px'}}>
                        <div className="">
                        <FeatherIcon icon="bell" fill='black' size={40}></FeatherIcon>
                        </div>
                        <h5>{`${cardValues['21days']}`}</h5>
                        <p>REMINDER 21 DAYS</p>
                        
                      </div>
                    </div>}
                  </div>  
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {isLoadingCards ? <ShimmerThumbnail height={115} rounded /> :<div className="card o-hidden border-0">
                      <div className="bg-primary b-r-4 card-body">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center"><FeatherIcon icon="printer"></FeatherIcon></div>
                          <div className="media-body"><span className="m-0">Bill Printed</span>
                            <h4 className="mb-0 counter">{`${cardValues.bills_printed}`}</h4><FeatherIcon className="icon-bg" icon="printer"></FeatherIcon>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {isLoadingCards ? <ShimmerThumbnail height={115} rounded /> :<div className="card o-hidden border-0">
                      <div className="bg-secondary b-r-4 card-body" >
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center"><FeatherIcon icon="repeat"></FeatherIcon></div>
                          <div className="media-body"><span className="m-0">Bills Reprint</span>
                            <h4 className="mb-0 counter">{`${cardValues.bills_reprinted}`}</h4><FeatherIcon className="icon-bg" icon="repeat"></FeatherIcon>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="col-xl-7 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div className="card-header pb-0">
                        <h5>Bills Dispatched</h5>
                      </div>    
                      <div className="card-body pt-10">
                      <div className="table-responsive">
                        {isLoadingTable2 ? <ShimmerTable row={4} col={5} /> :<table className="table">
                          <thead style={{backgroundColor:"#efefef"}}>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">Recieved By</th>
                              <th scope="col">Date Delivered</th>
                              <th scope="col">Mode</th>
                    
                            </tr>
                          </thead>
                          <tbody>
                            
                              {table2Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.received_by}</td>
                                  <td>{items.date_delivered}</td>
                                  <td>{items.mode}</td>

                                </tr>
                              )}
                       
                          </tbody>
                        </table>}
                      </div>
                      </div>
           
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div className="card-header pb-0">
                        <h5>Reminder Notice</h5>
                      </div>    
                      <div className="card-body pt-10">
                      <div className="table-responsive">
                        {isLoadingTable1 ? <ShimmerTable row={4} col={4} /> :<table className="table">
                          <thead style={{backgroundColor:"#efefef"}}>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">District</th>
                              <th scope="col">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                          {table1Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.occupant}</th>
                                  <td>{items.rating_dist}</td>
                                  <td>{getAmountComma(items.grand_total)}</td>

                                </tr>
                              )}
                            
                          </tbody>
                        </table>}
                      </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-13 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div className="card-header pb-0">
                        <h5>Recent Payments</h5>
                      </div>    
                      <div className="card-body pt-10">
                      <div className="table-responsive">
                        {isLoadingTable3 ? <ShimmerTable row={4} col={9} /> :<table className="table">
                          <thead style={{backgroundColor:"#efefef"}}>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">Rating. Dst.</th>
                              <th scope="col">Month</th>
                              <th scope="col">Curr. Amount</th>
                              <th scope="col">Amount Paid</th>
                              <th scope="col">Balance</th>
                              <th scope="col">Date</th>
                              <th scope="col">Bank</th>
                              <th scope="col">Channel</th>
                            </tr>
                          </thead>
                          <tbody>
                          {table3Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.rating_dist}</td>
                                  <td>{items.month}</td>
                                  <td>{getAmountComma(items.current_amt)}</td>
                                  <td>{getAmountComma(items.paid_amt)}</td>
                                  <td>{getAmountComma(items.balance)}</td>
                                  <td>{items.date_paid}</td>
                                  <td>{items.bank}</td>
                                  <td>{items.channel}</td>

                                </tr>
                              )}
                            
                          </tbody>
                        </table>}
                      </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2022 © AMAC All rights reserved.</p>
              </div>
              
            </div>
          </div>
        </footer>

    </div>
    )

}
