import React, {useState, useEffect, Component} from 'react'
import axios from 'axios'
import { getUser, saveLastPage, titleClass } from '../functions/Reusables';
import FeatherIcon from 'feather-icons-react';

export default class UploadAddress extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      canProceed: false,
      cadastral_zone: "---- Choose a Cadastral Zone ----",
      rating_dist: "Select a Cadastral Zone",
      active: 1,
      prop_use: "RESIDENTIAL",
      category: "EDUCATIONAL INSTITUTION",
      group: "AMAC 1",
      occupant:"",
      asset_no:"",
      prop_addr:"",
      street_name:"",
      rate_payable:0,
      rate_payableFormatted:"0",
      grand_total:0,
      annual_value:"",
      step: 1,
      isLoading:false,
      errorMessage:"",
      errorMessageUpload:"",
      successMessage :"",
      successMessageUpload :"",
      selectedFile:"",
      attribute:"0",
      isCreatedSuccessfully:false,
      successModalResponse:[],
      hasNoFailed: true,
      fraud:""
    };
  }

  formateToAmount(value){
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    return formatter.format(value)
    
  }

  handleFileSelected(event){
    this.setState({
      selectedFile: event.target.files[0],
    })
  }

  async uploadFile(){
    this.setState((state, props) => ({
      isLoading: true,
    }));
    const formData = new FormData() 
    formData.append('amacfile', this.state.selectedFile)
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(this.state.selectedFile);
      let url = `${process.env.REACT_APP_API}properties/up_bulk_addr/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data)
      this.setState((state, props) => ({
        successMessageUpload: `Bulk Properties Address Updated Successfully`,
        isLoading: false, 
        isCreatedSuccessfully:true,
        successModalResponse : response.data.fail,
        fraud : response.data.fraud,
        hasNoFailed:  response.data.fail.length > 0 ? false :true
      }));
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        this.setState((state, props) => ({
          isLoading: false,
          errorMessageUpload:error.response.data.message
        }));
        return
      }
      this.setState((state, props) => ({
        isLoading: false,
        errorMessageUpload:"Something went wrong"
      }));
    }

  }


  

  isEmpty(str) {
    return !str.trim().length;
  }

  closeReminderAlert =()=>{
    this.setState({
      isCreatedSuccessfully:false
    })
   }


  componentDidMount(){
    titleClass("Upload Address | AMAC Tenement")
    saveLastPage("uploadaddress")
  }


 render(){
  return (
    
    <div className="page-body">
          <div className="container-fluid">
          { this.state.isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          {this.state.isCreatedSuccessfully ? 
          <div className="swal-overlay swal-overlay--show-modal" tabindex="-1" >
            <div className="swal-modal" role="dialog" aria-modal="true">
              {this.state.hasNoFailed ? <div class="swal-icon swal-icon--success">
                <span class="swal-icon--success__line swal-icon--success__line--long"></span>
                <span class="swal-icon--success__line swal-icon--success__line--tip"></span>

                <div class="swal-icon--success__ring"></div>
                <div class="swal-icon--success__hide-corners"></div>
              </div> :
              <div class="swal-icon swal-icon--warning">
                <span class="swal-icon--warning__body">
                  <span class="swal-icon--warning__dot"></span>
                </span>
              </div>}
              <div className="swal-title">Property Upload Information</div>
            <p>Property Update Successful</p>
            {this.state.hasNoFailed == false ?<p>Failed Properties PID: {this.state.successModalResponse.join(" , ")}</p>: <div></div>}
            {this.state.fraud == "Yes" ?<p>The File contained more FIELDS than necssary to Update</p>: <div></div>}
            <div className="swal-footer">
              <div className="swal-button-container">
                <button className="btn" style={{color:'grey'}} onClick={()=>{this.closeReminderAlert()}} >OK</button>
                {/* <button className="btn btn-primary" onClick={()=>{
                  // setisPrintingBulk(true)

                }}>Confirm Print</button> */}
                <div className="swal-button__loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>: <div></div>}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Upload Address</h3>
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Properties</a></li>
                    <li className="breadcrumb-item">Upload Address</li>

                  </ol>
                </div>
                <div className="col-sm-6">
                  
                </div>
              </div>
            </div>
          <div className="container-fluid" style={{marginTop:'20px'}}>
            <div className="row">
              
              <div className="col-sm-12">
                <div className='card'>
                  
                  <div className='card-body'>
                  {this.state.errorMessageUpload !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{this.state.errorMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>this.setState({errorMessageUpload:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {this.state.successMessageUpload !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{this.state.successMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>this.setState({successMessageUpload:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <p style={{fontWeight:'bold'}}> <span style={{marginRight:'20px'}}><FeatherIcon icon='upload' size={20}></FeatherIcon></span> Bulk Upload (Spool)</p>
                    <p style={{}}>Update Address in bulk</p>
                    <input class="form-control mb-20" type="file" onChange={(e)=>{
                      this.handleFileSelected(e)
                    }} aria-label="file example" required=""/>
                    <button className="btn btn-secondary pull-right" onClick={()=>{
                      this.uploadFile()
                    }}  type="button">Upload</button>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
  )
 }
}
