import React, { Component, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { BtnDefaultSAVE } from './ActionButtons';



export default function MyBasicDataTable2(props) {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  // const gridStyle = useMemo(() => ({ height: 'calc(100vh - 220px)', width: '100%' }), []);
  const gridStyle = useMemo(() => ({  width: '100%' }), []);
  
 const rowStyle = {
   fontSize:"12px",
   padding:"0px"
 }

  const [gridApi, setGridApi] = useState(null)

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      // sortable: true
    };
  }, []);


  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);


  const onGridReady = useCallback((params) => {
    sizeToFit()
  }, []);

  
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: BtnDefaultSAVE,
        },
      ],
    };
  }, []);


  const onBtExportxcel = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);
  const onBtExportcsv = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
  

  // const [render, rerender] = useState(true);

    return (
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine" >
         <AgGridReact
              rowData={props.data}
              ref={gridRef}
              columnDefs={props.col}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={15}
              animateRows={true}
              rowHeight={27}
              onGridReady={onGridReady}
              rowStyle={rowStyle}
              domLayout = "autoHeight"
              statusBar={statusBar}
            >
          </AgGridReact>
          </div>
        </div>
    )

}
