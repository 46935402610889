import axios from 'axios';
import React, { Component } from 'react'
import QRCode from "react-qr-code";
import { getUser } from '../functions/Reusables';

export default function Print  (props) { 

    function getAmount(value){
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
    }

    function getDateTime(){
        const d = new Date();
        return `${d.toJSON().slice(0,10).split('-').reverse().join('/')} ${d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        
    }
    function getYear(){
        const d = new Date();
        return `${d.getFullYear()}`;
    }
    function getMonth(){
        const d = new Date();
        return `${d.getMonth() +1}`;
    }

    async function updatePrint(pid){
        const requestPID = {
          pid: pid,
        };
        const headers = {
          "AccessToken":getUser().access_token
        }
        try {
          console.log(requestPID)
          const response = await axios.post(
            `${process.env.REACT_APP_API}bills/up_print/`,
            requestPID,
            {headers}
          );
          
          
        } catch (error) {
          const errors = []
          console.log(error)
          errors.push("Something went wrong")
          
          if(error.response !== null || error.response !== undefined){
            errors.push(error.response.data.message)
          }
          console.log(errors.toString())
        }
      }

    

    return (

<div className=''> 
        <div className="mycontainer mycenter" id='mycontainerprint'>
            <div className=" height">
                <div class="hideMe">
                  <button  class="btn btn-primary" onClick={()=>{
                    console.log("printing")
                    var prtContent = document.getElementById("mycontainerprint");
                    var WinPrint = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
                    WinPrint.document.write('<html><head>');
                    WinPrint.document.write('<link rel="stylesheet" href="assets/css/myprint.css">');

                    WinPrint.document.write('</head><body class="mybody" onload="print();close();">');
                    WinPrint.document.write(prtContent.innerHTML);
                    WinPrint.document.write('</body></html>');
                    WinPrint.document.close();
                    WinPrint.focus();
                    updatePrint((parseInt(props.propResponse.pid.slice(3))).toString())
                    props.refreshTable()
                    props.closeModal()

                    }} type="button">Confirm Print</button>
                    <button class="btn-close" type="button" onClick={()=>props.closeModal()}  data-bs-dismiss="modal" aria-label="Close"></button>
                  </div></div>
            <div className="content">
                <div className="border2">
			<div><b id='warningNote'>NOTE: IT IS ILLEGAL TO PAY CASH TO ANYONE.  DEFAULTER WILL BE LIABLE</b></div>

                    <div className="notice-header flex space-btw">
                        <div>
                            Notice is hereby given to -
                            <span className="pid">{props.propResponse.occupant} - PID ({props.propResponse.pid})</span>
                        </div>
                        <div>
                            <span className="year right">
                                <b>YEAR {props.propResponse.year}</b>
                            </span>
                        </div>
                    </div>
                    <div className="border-top2">
                        <div className="pad7 text">
                            In respect of the property below
                        </div>
                        <div className="flex">
                            <div className="col-8">
                                <div className="props flex">
                                    <div className="fields">Property Address:- </div>
                                    <div className="values">
                                        {props.propResponse.prop_addr}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Name of Occupier:-</div>
                                    <div className="values">
                                        {props.propResponse.occupant}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Assessment Number:- </div>
                                    <div className="values">
                                        {props.propResponse.asset_no}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Cadastral Zone:- </div>
                                    <div className="values">
                                        {props.propResponse.cadastral_zone}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Use of Property:- </div>
                                    <div className="values">
                                        {props.propResponse.prop_use}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Rating District:- </div>
                                    <div className="values">
                                        {props.propResponse.rating_dist}
                                    </div>
                                </div>
                            </div>
                            <div>
            
                                <div id="code"><QRCode size={96} value={`${process.env.REACT_APP_API}upay/make_payment/?_pid=${(parseInt(props.propResponse.pid.slice(3))).toString()}&_amt=${props.propResponse.grand_total}`} /></div>
                                <div>
                                    {/* <p className="code">TR/z2yst1zqsul07xh1dg/11/343300</p> */}
                                    <p className="date">{getDateTime()}</p>
                                    <p className="date">{Date.now()}</p>
                                </div>
                            </div>
                        </div>
            
                    </div>
                    <div className="border-top2 flex">
                        <div className="col-2 flex align-center pad6">
                            <table className="col-12 border2">
                                <tbody>
                                    <tr>
                                        <td className="td-pad weight">Bill Ref</td>
                                        <td className="td-pad">{getYear()/getMonth()}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-pad weight">Agency Code</td>
                                        <td className="td-pad">2014000</td>
                                    </tr>
                                    <tr>
                                        <td className="td-pad weight">Revenue Code</td>
                                        <td className="td-pad">1002</td>
                                    </tr>
                                    <tr>
                                        <td className="td-pad weight">Rate Year</td>
                                        <td className="td-pad">{props.propResponse.year}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-8">
                            <table width="98%" className="tbl border2 border-collapse spacing">
                                <tbody>
                                    <tr className="border-bottom2">
                                        <td colspan="2">
                                            <div className="info">Bill Information</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="_td">
                                            Annual Value
                                        </td>
                                        <td className="_td">
                                            ₦ {getAmount(props.propResponse.annual_value)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="_td">
                                            Rate Nairage
                                        </td>
                                        <td className="_td">
                                            4 kobo per Naira
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="_td">Rate payable</td>
                                        <td className="_td">₦ {getAmount(props.propResponse.rate_payable)}</td>
                                    </tr>
                                </tbody>
                            </table>
            
                            <table width="98%" className="tbl border2 border-collaps spacing">
                                <tbody>
                                    <tr>
                                        <td className="_td">
                                            Arrears
                                        </td>
                                        <td className="_td">
                                            ₦ {getAmount(props.propResponse.arrears)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="_td">Penalty on arrears(10%)</td>
                                        <td className="_td">₦ {getAmount(props.propResponse.penalty)}</td>
                                    </tr>
                                    <tr>
                                        <td className="_td"> Grand total</td>
                                        <td className="_td">₦ {getAmount(props.propResponse.grand_total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="border-top2 law">
                        In accordance with the provision of the section 7 (4th schedule) of the 1999
                        constitution of the Federal Republic of Nigeria; Federal Capital Territory Act Cap
                        503,LFN,2004(Vol.3) as ammended Taxes and Levies Act No. 21 of 1998 and A.M.A.C Tenement Rate
                        bye-Laws 2014, we forward herewith your bill for the year {props.propResponse.year} totalling ₦
                        {getAmount(props.propResponse.grand_total)} in respect of the landed property(ies) you are occupying in Abuja as per details
                        above.
                    </div>
                </div>
            </div>
            <div className="border2 margin5 note">
                NOTE: Please turn overleaf for a list of payment options through which payment can be made and ensure your
                electronic receipt is collected from the bank.
            </div>
            <div className="margin10">
                <div className="flex" style={{justifyContent:'space-between'}}>
                    <div id='leftContainer'>
                        <div id='earlyCompliance'  className="pad-left-10">
                            <p>Your early compliance will be highly appreciated.</p>
                            <p className="yours">Yours faithfully,</p>
                            <div>
                            </div>
                       

                            <div className="authority flex  ">
                                <div id='headOne'>
                                    <img className='signatures' src="../assets/images/sign1.png"/>
                                    <div className="head-tenrement">HEAD OF TENEMENT RATE</div>
                                    <div className="chairman"> For Honourable Chairman</div>
                                    <div className="council">Abuja Municipal Area Council</div>
                                </div>
                                <div >
                                <img className='signatures' src="../assets/images/sign2.png" />
                                    <div className="head-tenrement">DIRECTOR OF OPERATIONS</div>
                                    <div className="chairman"> For Honourable Chairman</div>
                                    <div className="council">Abuja Municipal Area Council</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="" id='rightContainer' >
                        <div className="c-table">
                            <div className="c-table-head">
                                Acknowledgement
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-22">
                                    Name
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-22">
                                    Date
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-22">
                                    Signature
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                        </div>
                        <div className="c-table" >
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-70">
                                    Date of dispatch
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-70">
                                    Name of Officer
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-70">
                                    Mode of dispatch
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                        </div>	                      	
                    </div>
                </div>
            </div>
            <div id='printFoot'>All payment To <b>AMAC Tenement Rate</b> Account No. <b>0057326758, UNION BANK.</b></div>	
        </div>
    
</div>



    )

}
