import React, { Component,useEffect, useState } from 'react'
import FeatherIcon from 'feather-icons-react';
import {logOut, getUser, getPrintData} from "../functions/Reusables";
import { PrintBulkReminder } from '../pages/printbulkreminder';
import { PrintBulkReminderAuto } from '../pages/printbulkreminderauto';
// import { useNavigate, Navigate } from 'react-router-dom';
export default function Header (props) {


  const logOutUser=()=>{
    logOut();
    props.logOut()
  }
  function closePrintModal(){
    setisPrintingBulk(false)
 
   }

  const [isPrintingBulk, setisPrintingBulk] = useState(false)

    return (
   <div className="page-main-header" id='main-header'>
     { isPrintingBulk ? <div className="modal fade show" id="exampleModalLong" style={{display:'block', backgroundColor:'#0000004a', overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'1000px'}}>
                  
                <div className="modal-content" id='printModal'>
                  <PrintBulkReminderAuto propResponses={getPrintData().message} changeHeaderNotify={props.changeHeaderNotify} closeModal={()=>{
                    closePrintModal()
                  }} />
                </div>
              </div>
            </div>: <div></div>}
        {props.isPrinting == true ? <div></div> :<div className="main-header-right row m-0">
          <div className="main-header-left">
            <div className="logo-wrapper"><a href="index.html"><img className="img-fluid" src="assets/images/logo/logo2.png" alt=""/></a></div>
            <div className="dark-logo-wrapper"><a href="index.html"><img className="img-fluid" src="assets/images/logo/dark-logo.png" alt=""/></a></div>
            <div className="toggle-sidebar"><i className="status_toggle middle" data-feather="align-center" id="sidebar-toggle"></i></div>
          </div>
          <div className="left-menu-header col">
            <ul>
              {/* <li>
                <form className="form-inline search-form">
                  <div className="search-bg"><i className="fa fa-search"></i>
                    <input className="form-control-plaintext" placeholder="Search here....."/>
                  </div>
                </form><span className="d-sm-none mobile-search search-bg"><i className="fa fa-search"></i></span>
              </li> */}
            </ul>
          </div>
          <div className="nav-right col pull-right right-menu p-0">
            <ul className="nav-menus">
              
              {props.shouldNotify == false ? <div></div> : <li className="onhover-dropdown">
                <div className="notification-box"><FeatherIcon icon="bell"></FeatherIcon><span className="dot-animated"></span></div>
                <ul className="notification-dropdown onhover-show-div">
                  <li>
                    <p className="f-w-700 mb-0">You have a Pending Reminder Print</p>
                  </li>
                  <li className="noti-primary" onClick={()=>{
                    setisPrintingBulk(true)
                  }}>
                    <div className="media"><span className="notification-bg bg-light-primary"><FeatherIcon icon="file-text"> </FeatherIcon></span>
                      <div className="media-body">
                        <p>Click to Print Reminders</p>
                      </div>
                    </div>
                  </li>
                  
                </ul>
              </li>}
              
              <li className="onhover-dropdown p-0">
                <button className="btn btn-primary-light" onClick={()=>logOutUser()} type="button"><FeatherIcon icon="log-out"></FeatherIcon>Log out</button>
              </li>
            </ul>
          </div>
          <div className="d-lg-none mobile-toggle pull-right w-auto"><FeatherIcon icon="more-horizontal"></FeatherIcon></div>
        </div>}
      </div>
    
    )
}
