import axios from 'axios';
import React, { Component, createRef, useRef, useState } from 'react'
import QRCode from "react-qr-code";
import {ReceiptContent} from '../components/ReceiptContent';
import ReactToPrint from 'react-to-print';
import { useScreenshot } from 'use-react-screenshot'
import { getUser } from '../functions/Reusables';

export default function PrintReceipt (props) { 



    function getAmount(value){
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
    }

    function getDateTime (){
        const d = new Date();
        return `${d.toJSON().slice(0,10).split('-').reverse().join('/')} ${d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        
    }
    function getYear(){
        const d = new Date();
        return `${d.getFullYear()}`;
    }

    async function updatePrint(pid){
        const requestPID = {
          pid: pid,
        };
        const headers = {
          "AccessToken":getUser().access_token
        }
        try {
          console.log(requestPID)
          const response = await axios.post(
            `${process.env.REACT_APP_API}bills/up_print/`,
            requestPID,
            {headers}
          );
          
          
        } catch (error) {
          const errors = []
          console.log(error)
          errors.push("Something went wrong")
          
          if(error.response !== null || error.response !== undefined){
            errors.push(error.response.data.message)
          }
          console.log(errors.toString())
        }
    }

    const [isEditing, setIsEditing] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);

    function setEditing(value){
        setIsEditing(value)
    }


    const ref = createRef(null)
    const [image, takeScreenshot] = useScreenshot()
    const getImage = () => takeScreenshot(ref.current)

    async function showPrint(){
        props.pageLoader(true)
        await getImage()
        setIsPrinting(true)
        setTimeout(printPage, 1000)
    }

    async function printPage(){
        
        console.log("printing")
        var prtContent = document.getElementById("print_new");
        var WinPrint = window.open('', '', 'toolbar=0,scrollbars=0,status=0 ,height=800,width=800');
        console.log(WinPrint)
        WinPrint.document.write('<html><head>');
        WinPrint.document.write('<link rel="stylesheet" href="assets/css/myprint3.css">');
        WinPrint.document.write('</head><body class="" onload="print();close();">');
        // WinPrint.document.write('</head><body class="" onload="">');
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.write('</body></html>');
        WinPrint.document.close();
        WinPrint.focus();
        setIsPrinting(false)
        props.pageLoader(false)
        // this.props.closeModal()
        // updatePrint((parseInt(props.propResponse.pid.slice(3))).toString())
    }
    // async  printPage(){
    //     console.log("printing")
    //     // console.log(this.props)

        
        
    //     var prtContent = document.getElementById("print_success");
    //     var WinPrint = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
    //     console.log(WinPrint)
    //     WinPrint.document.write('<html><head>');
    //     WinPrint.document.write('<link rel="stylesheet" href="assets/css/bootstrap.css">');
    //     WinPrint.document.write('<link rel="stylesheet" href="assets/css/style.css">');
    //     WinPrint.document.write('<link rel="stylesheet" href="assets/css/myprint3.css">');
    //     // WinPrint.document.write('</head><body class="" onload="print();close();">');
    //     WinPrint.document.write('</head><body class="" onload="">');
    //     WinPrint.document.write(prtContent.innerHTML);
    //     WinPrint.document.write('</body></html>');
    //     WinPrint.document.close();
    //     WinPrint.focus();
    //     // this.props.closeModal()
    //     // updatePrint((parseInt(props.propResponse.pid.slice(3))).toString())
    // }


    return (

        <div className=''> 
             <div class="modal fade show" id="exampleModalCenter" style={{display:'block', backgroundColor:'#0000004a',overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
                <div class="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'900px'}} >
                    <div class="modal-content">
                        <div class="modal-header"><p>PRINT RECEIPT</p></div>
                        {!isPrinting ?
                        <ReceiptContent  ref={ref}  setIsEditing={setEditing} isEditing={isEditing} info={props.propResponse} />
                        : 
                        <div id='print_new'>
                            <div style={{ height:'30px'}}></div>
                            <img  src={image} alt={'Screenshot'} style={{width:'100%'}} />
                            <div style={{ height:'30px'}}></div>
                            <hr style={{borderTop:'4px dashed #000'}}/>
                            <div style={{ height:'30px'}}></div>
                            <img  src={image} alt={'Screenshot'} style={{width:'100%'}}/>
                        </div>}
                        <div className="modal-footer" tyle="background-color: #fafafa;" style={{display:'flex', justifyContent:'space-between'}}>
                            <button type="button" className="btn btn-secondary close-box left" data-dismiss="modal" onClick={()=>{
                                    props.closeModal() 
                                }}>
                                Close
                            </button>
                            <div>
                                <button type="button" className="btn btn-primary" id="change_name" style={{marginRight:'5px'}} onClick={()=>{
                                    setIsEditing(true)


                                }}>
                                    Add Occupier
                                </button>
                                <button type="submit" className="btn btn-primary" id="receipt" onClick={()=>{
                                    // printPage()
                                    showPrint()
                                }}>
                                    PRINT
                                </button>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
     



)
}


