import React, {Component} from 'react'
import axios from 'axios'
import { getAmountComma, getUser, saveLastPage, stripComma, titleClass } from '../functions/Reusables';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

export default class UpdateProperty extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      canProceed: false,
      cadastral_zone: "",
      rating_dist: "Select a Cadastral Zone",
      active: 1,
      prop_use: "RESIDENTIAL",
      category: "EDUCATIONAL INSTITUTION",
      group: "AMAC 1",
      occupant:"",
      asset_no:"",
      prop_addr:"",
      street_name:"",
      rate_payable:0,
      rate_payableFormatted:"0",
      grand_total:0,
      annual_value:"",
      step: 1,
      isLoading:false,
      errorMessage:"",
      successMessage :"",
      errorMessageUpload:"",
      successMessageUpload :"",
      warningMessageUpload :"",
      hasSearched: false,
      pid:'',
      rawPid:'',
      penalty:"",
      arrears:"",
      selectedFile:"",
      failedPIDS:"",
      attribute:"0"
    };
  }
  zones = [
    {name:"---- Choose a Cadastral Zone ----" , district:"Select a Cadastral Zone"},
    {name:"A00 - CBD" , district:"CBD"},
    {name:"A01 - AREA 1" , district:"GARKI AREAS"},
    {name:"A01 - AREA 2" , district:"GARKI AREAS"},
    {name:"A01 - AREA 3" , district:"GARKI AREAS"},
    {name:"A01 - AREA 7" , district:"GARKI AREAS"},
    {name:"A01 - AREA 8" , district:"GARKI AREAS"},
    {name:"A01 - AREA 11" , district:"GARKI AREAS"},
    {name:"A01 - Area 10" , district:"GARKI AREAS"},
    {name:"A02 - WUSE ZONE 1" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 2" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 3" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 4" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 5" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 6" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 7" , district:"WUSE ZONES"},
    {name:"A03 - GARKI 2" , district:"GARKI 2"},
    {name:"A04 - ASOKORO" , district:"ASOKORO"},
    {name:"A06 - MAITAMA" , district:"MAITAMA"},
    {name:"A07 - WUSE 2" , district:"WUSE 2"},
    {name:"A09 - GUZAPE" , district:"GUZAPE"}
  ]

  buildCadastralDropdownFound(cad, dist){
    console.log(cad,dist)
    var found = false;
    for(var i = 0; i < this.zones.length; i++) {
        if (this.zones.name == cad.toString().toUpperCase()) {
            found = true;
            break;
        }
    }
    if(!found){
      this.zones.push({name:cad , district:dist})
      this.setState({
        attribute : this.zones.length -1
      })
    }
    console.log(this.zones)
  }
  

  formateToAmount(value){
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    return formatter.format(value)
    
  }


  isEmpty(str) {
    return !str.trim().length;
  }
 getAction(value){
   if(value === "ACTIVE"){
     return 1;
   }
   if(value === "VACANT"){
     return 2;
   }
   if(value === "UNDER CONSTRUCTION"){
     return 3;
   }
   if(value === "EMPTY LAND"){
     return 4;
   }
   if(value === "EXEMPTED"){
     return 5;
   }

 }
  checkIfCanProceed =(step)=>{
    let canProceedNext = false
    if(step == 1){
      // let canProceedNext = false
      if(this.state.cadastral_zone == '---- Choose a Cadastral Zone ----'){
        canProceedNext = false;
      }else{
        canProceedNext = true
      }

      if(canProceedNext == false){
        console.log("CANNOT PROCEED")
        this.setState((state, props) => ({
          errorMessage: "Error. All fields are required.",
        }));
      }else{
        this.setState((state, props) => ({
          step: state.step + 1,
        }));
      }
    }
    if(step == 2){
      // let canProceedNext = false
      if(this.isEmpty(this.state.occupant) || this.isEmpty(this.state.prop_addr) || this.isEmpty(this.state.asset_no) || this.isEmpty(this.state.street_name)){
        canProceedNext = false;
      }else{
        canProceedNext = true
      }

      if(canProceedNext == false){
        console.log("CANNOT PROCEED 2")
        this.setState((state, props) => ({
          errorMessage: "Error. All fields are required.",
        }));
      }else{
        this.setState((state, props) => ({
          step: state.step + 1,
        }));
      }
    }
    if(step == 3){
      // let canProceedNext = false
      if(this.isEmpty(this.state.annual_value.toString())){
        canProceedNext = false;
      }else{
        canProceedNext = true
      }

      if(canProceedNext == false){
        console.log("CANNOT PROCEED 2")
        this.setState((state, props) => ({
          errorMessage: "Error. All fields are required.",
        }));
      }else{
        // this.updateProperty()
      }
    }
    return canProceedNext
  }

  async getProperty (){
    if(this.isEmpty(this.state.pid)){
      return
    }
    this.setState((state, props) => ({
      isLoading: true,
    }));
    this.object = {
      pid: this.state.pid
    }
    try {
      const headers = {
        "AccessToken": getUser().access_token
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}properties/get_property/`,
        this.object,{
          headers
        }
      );
      console.log(response.data)
      
      this.setState((state, props) => ({
        cadastral_zone : response.data.cadastral_zone,
        rating_dist: response.data.rating_dist,
        active: response.data.active.toUpperCase(),
        prop_use: response.data.prop_use,
        category: response.data.category,
        group: response.data.group,
        occupant: response.data.occupant,
        asset_no: response.data.asset_no,
        prop_addr: response.data.prop_addr,
        street_name: response.data.street_name,
        rate_payable: response.data.rate_payable,
        grand_total: response.data.grand_total,
        arrears:response.data.arrears,
        penalty:response.data.penalty,
        annual_value: response.data.annual_value,
        rate_payableFormatted:response.data.rate_payable,
        rawPid: response.data.pid
      }))
      this.buildCadastralDropdownFound(response.data.cadastral_zone,response.data.rating_dist)
      this.setState((state, props) => ({
        hasSearched:true,
        successMessage: `Property Retrieved Successfully`,   
        isLoading: false,
      }));

    } catch (error) {
      if(error.response){
        console.log(error.response)
        
      }
      this.setState((state, props) => ({
        isLoading: false
      }));
    }
  }

  async updateProperty (){
    this.setState((state, props) => ({
      isLoading: true,
    }));
    this.propertyObject = {
      pid: this.state.pid,
      cadastral_zone : this.state.cadastral_zone,
      rating_dist: this.state.rating_dist,
      active: this.getAction(this.state.active),
      prop_use: this.state.prop_use,
      category: this.state.category,
      group: this.state.group, 
      occupant: this.state.occupant,
      asset_no: this.state.asset_no,
      prop_addr: this.state.prop_addr,
      street_name: this.state.street_name,
      rate_payable: this.state.rate_payable,
      grand_total: this.grandTotal(),
      annual_value: Number(this.state.annual_value),
      penalty:this.state.penalty,
      arrears: Number(this.state.arrears),
      street_name: this.state.street_name
    }
    console.log(this.propertyObject)
    try {
      const headers = {
        "AccessToken": getUser().access_token
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}properties/update_property/`,
        this.propertyObject,{
          headers
        }
      );
      console.log(response.body)
      
      this.setState((state, props) => ({
        successMessage: `Property Updated Successfully`,
        isLoading: false,
        
      }));

      this.setState({
        step:1
      })

    } catch (error) {
      if(error.response){
        console.log(error.response)
        
      }
      this.setState((state, props) => ({
        isLoading: false
      }));
    }
  }

  grandTotal(){
    console.log(this.state.rate_payable , this.state.penalty , this.state.arrears)
    let total = stripComma(this.state.rate_payable) + stripComma(this.state.penalty) + stripComma(this.state.arrears)
    // this.setState({
    //   grand_total : total
    // })
    return total
  }

  handleFileSelected(event){
    this.setState({
      selectedFile: event.target.files[0],
    })
  }

  async uploadFile(){
    this.setState((state, props) => ({
      isLoading: true,
    }));
    const formData = new FormData() 
    formData.append('amacfile', this.state.selectedFile)
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(this.state.selectedFile);
      let url = `${process.env.REACT_APP_API}properties/up_bulk_property/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data.fail)
      if(response.data.fail.length > 0){
        this.setState((state, props) => ({
          warningMessageUpload: `Warning some Properties failed to Update`,
          failedPIDS:response.data.fail,
          isLoading: false, 
        }));
      }else{
        this.setState((state, props) => ({
          successMessageUpload: `Bulk Properties Updated Successfully`,
          isLoading: false, 
        }));
      }
      
    } catch (error) {
      console.log(error)
      if(error.response){
        if(error.response.data){
          console.log(error.response)
          this.setState((state, props) => ({
            isLoading: false,
            errorMessageUpload:error.response.data.message
          }));
          return
        }
      }
      this.setState((state, props) => ({
        isLoading: false,
        errorMessageUpload:"Something went wrong"
      }));
    }

}
componentDidMount(){
  titleClass("Update Properties | AMAC Tenement")
  saveLastPage("updateproperties")
}


 render(){
  return (
    
    <div className="page-body">
          <div className="container-fluid">
        { this.state.isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Update Property</h3>
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Properties</a></li>
                    <li className="breadcrumb-item">Update Properties</li>

                  </ol>
                </div>
                <div className="col-sm-6">
                {/* <h6 className = '' style={{marginTop:'20px'}} >Search for Property with PID</h6> */}
                <div className='' style={{padding:'20px', marginBottom:'0px', display:'flex', flexDirection:'row'}}>
                  <input style={{width:'calc(100% - 120px)', height:'40px', border:'1px solid #e2e2e2', paddingLeft:'13px' }} placeholder='Search with Property PID' type='text' onChange={(e)=>{
                    this.setState({
                      pid:e.target.value
                    })
                  }}/><button className='btn btn-primary ' style={{width:'120px'}} onClick={()=>{
                    this.getProperty()
                  }} >Search</button>
                </div>
                </div>
              </div>
            </div>
            <div className='row'>
                  <div className='col-md-7'>
                  { this.state.hasSearched ? <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">

                  <div className="card-body">
                    <div className="stepwizard">
                      <div className="stepwizard-row setup-panel">
                        <div className="stepwizard-step"><a className={this.state.step == 1 ? "btn btn-primary" : "btn btn-light"} onClick={()=>{
                          if(this.checkIfCanProceed(this.state.step)){
                            this.setState({
                              step : 1
                            })
                          }
                        }}  href="#">1</a>
                          <p>Zone / District Details</p>
                        </div>
                        <div className="stepwizard-step"><a className={this.state.step == 2 ? "btn btn-primary" : "btn btn-light"} onClick={()=>{
                          if(this.checkIfCanProceed(this.state.step)){
                            this.setState({
                              step : 2
                            })
                          }
                        }} href="#">2</a>
                          <p>Property Details</p>
                        </div>
                        <div className="stepwizard-step"><a className={this.state.step == 3 ? "btn btn-primary" : "btn btn-light"} onClick={()=>{
                          if(this.checkIfCanProceed(this.state.step)){
                            this.setState({
                              step : 3
                            })
                          }
                        }}  href="#">3</a>
                          <p>Property Value / Category</p>
                        </div>
                        
                      </div>
                    </div> 
                    <p style={{padding:'10px', backgroundColor:'lightgoldenrodyellow', fontWeight:'bold'}}>PID: {this.state.rawPid}</p>
                    {this.state.errorMessage !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{this.state.errorMessage}</strong>
                      <button class="btn-close" onClick={()=>this.setState({errorMessage:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {this.state.successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{this.state.successMessage}</strong>
                      <button class="btn-close" onClick={()=>this.setState({successMessage:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <form action="#" method="POST">
                     <div className="setup-content" style={{display: this.state.step != 1 ? 'none': 'block'}} id="step-1">
                        <div className="col-xs-12">
                          <div className="col-md-12">
                            <div className='row'>
      
                                  <div class="mb-3 form-group col-md-6">
                                    <label class="control-label" for="exampleFormControlSelect9">Cadastral Zone</label>
                                    <select class="form-select digits" id="cadList" value={this.state.attribute} onChange={(e)=>{
                                      const el = document.getElementById("cadList");
                                      this.setState((state, props) => ({
                                        cadastral_zone: el.options[el.selectedIndex].text,
                                        attribute: e.target.value,
                                      }))
                                      this.setState((state, props) => ({
                                        rating_dist: el.options[el.selectedIndex].attributes.district.value,
                                      }))
                                      console.log(this.state.rating_dist)
                                    }} >
                                      {
                                        this.zones.map((option,i)=><option district={option.district} value={i.toString()}>{option.name}</option>)
                                      }
                                        {/* <option value="CBD" data-attr-id="1">A00 - CBD</option>
                                        <option value="GARKI AREAS" data-attr-id="2">A01 - AREA 1</option>
                                        <option value="GARKI AREAS" data-attr-id="3">A01 - AREA 2</option>
                                        <option value="GARKI AREAS" data-attr-id="4">A01 - AREA 3</option>
                                        <option value="GARKI AREAS" data-attr-id="5">A01 - AREA 7</option>
                                        <option value="GARKI AREAS" data-attr-id="6">A01 - AREA 8</option>
                                        <option value="GARKI AREAS" data-attr-id="7">A01 - AREA 11</option>
                                        <option value="GARKI AREAS" data-attr-id="8">A01 - Area 10</option>
                                        <option value="WUSE ZONES" data-attr-id="9">A02 - WUSE ZONE 1</option>
                                        <option value="WUSE ZONES" data-attr-id="10">A02 - WUSE ZONE 2</option>
                                        <option value="WUSE ZONES" data-attr-id="11">A02 - WUSE ZONE 3</option>
                                        <option value="WUSE ZONES" data-attr-id="12">A02 - WUSE ZONE 4</option>
                                        <option value="WUSE ZONES" data-attr-id="13">A02 - WUSE ZONE 5</option>
                                        <option value="WUSE ZONES" data-attr-id="14">A02 - WUSE ZONE 6</option>
                                        <option value="WUSE ZONES" data-attr-id="15">A02 - WUSE ZONE 7</option>
                                        <option value="GARKI 2" data-attr-id="16">A03 - GARKI 2</option>
                                        <option value="ASOKORO" data-attr-id="17">A04 - ASOKORO</option>
                                        <option value="MAITAMA" data-attr-id="18">A06 - MAITAMA</option>
                                        <option value="WUSE 2" data-attr-id="19">A07 - WUSE 2</option>
                                        <option value="GUZAPE" data-attr-id="20">A09 - GUZAPE</option> */}
                                    </select>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className="control-label">Ratings District</label>
                                    <input className="form-control" disabled type="text" placeholder="Select a Cadastral Zone" required="required" value={this.state.rating_dist}/>
                                  </div>
                            </div>
                              <div className='row'>
                                <div class="mb-3 form-group col-md-6">
                                  <label class="control-label" for="exampleFormControlSelect9">Property Action</label>
                                  <select class="form-select digits" id="propact" value={this.state.active} onChange={(e)=>{
                                      
                                      this.setState((state, props) => ({
                                        active: e.target.value
                                      }));
                                    }}>
                                      
                                      <option value="ACTIVE" >ACTIVE</option>
                                      <option value="VACANT" >VACANT</option>
                                      <option value="UNDER CONSTRUCTION">UNDER CONSTRUCTION</option>
                                      <option value="EMPTY LAND" >EMPTY LAND</option>
                                      <option value="EXEMPTED5" >EXEMPTED</option>
                                    </select>
                                </div>  
                                <div class="mb-3 form-group col-md-6">
                                  <label class="control-label" for="exampleFormControlSelect9">Group</label>
                                  <select class="form-select digits" id="exampleFormControlSelect9" value={this.state.group} onChange={(e)=>{
                                      this.setState((state, props) => ({
                                        group: e.target.value
                                      }));
                                    }}>
                                      <option value="AMAC 1" >AMAC 1</option>
                                      <option value="AMAC 2" >AMAC 2</option>

                                  </select>
                                </div>
                              </div>                                                                         
                              <button className="btn btn-secondary nextBtn pull-right" onClick={()=>this.checkIfCanProceed(1)} type="button">Next</button>
                            </div>
                        </div>
                      </div>
                      <div className="setup-content" style={{display: this.state.step != 2 ? 'none': 'block'}} id="step-2">
                        <div className="col-xs-12">
                          <div className="col-md-12">
                            <div className='row'>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Name of Occupant</label>
                                <input className="form-control" type="text" placeholder="Occupants Name" required="required" value={this.state.occupant} onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          occupant: e.target.value
                                        }));
                                      }}/>
                              </div>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Asset Number</label>
                                <input className="form-control" type="text" placeholder="Assessment Number" required="required" value={this.state.asset_no}  onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          asset_no: e.target.value
                                        }));
                                      }}/>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Property Address</label>
                                <input className="form-control" type="text" placeholder="Property Address" required="required" value={this.state.prop_addr}  onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          prop_addr: e.target.value
                                        }));
                                      }}/>
                              </div>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Street Name</label>
                                <input className="form-control" type="text" placeholder="Street Name" required="required"  value={this.state.street_name} onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          street_name: e.target.value
                                        }));
                                      }}/>
                              </div>
                            </div>
                            
                           <div className='row'>
                            <div class="mb-3 form-group col-md-6">
                                <label class="control-label" for="exampleFormControlSelect9">Property Use</label>
                                <select class="form-select digits" id="exampleFormControlSelect9" value={this.state.prop_use} onChange={(e)=>{
                                    this.setState((state, props) => ({
                                      prop_use: e.target.value
                                    }));
                                  }}>
                                    <option value="RESIDENTIAL" >RESIDENTIAL</option>
                                    <option value="COMMERCIAL" >COMMERCIAL</option>

                                </select>
                              </div>
                              <div class="mb-3 form-group col-md-6">
                                <label class="control-label" for="exampleFormControlSelect9">Category</label>
                                <select class="form-select digits" id="exampleFormControlSelect9" value={this.state.category} onChange={(e)=>{
                                    this.setState((state, props) => ({
                                      category: e.target.value
                                    }));
                                  }}>
                                    <option value="EDUCATIONAL INSTITUTION">EDUCATIONAL INSTITUTION</option>
                                    <option value="HOSPITALITY" >HOSPITALITY</option>
                                    <option value="FINANCIAL INSTITUTION" >FINANCIAL INSTITUTION</option>
                                    <option value="OIL AND GAS" >OIL AND GAS</option>
                                    <option value="PLAZA" >PLAZA</option>
                                    <option value="CONSTRUCTION" >CONSTRUCTION</option>

                                </select>
                              </div>
                           </div>

                            <button className="btn btn-secondary nextBtn pull-right" type="button" onClick={()=>this.checkIfCanProceed(2)}>Next</button>
                          </div>
                        </div>
                      </div>
                      <div className="setup-content" id="step-3" style={{display: this.state.step != 3 ? 'none': 'block'}}>
                      <div className="col-xs-12">
                          <div className="col-md-12">
                            
                            <div className='row'>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Property Value</label>
                                <input className="form-control" type="text" placeholder="Property Value" required="required" value={this.state.annual_value}  onChange={(e)=>{
                                          this.setState((state, props) => ({
                                            annual_value: e.target.value,
                                            rate_payable: Number(e.target.value) * 0.04,
                                            grand_total: Number(state.rate_payable) + Number(state.penalty) + Number(state.arrears),
                                            rate_payableFormatted: Number(e.target.value) * 0.04
                                          }));

                                          // this.setState({
                                          //   grand_total: this.grandTotal(),
                                          // })
                                        }}/>
                              </div>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Rate Payable</label>
                                <input className="form-control" disabled type="text" placeholder="Rate Payable" required="required" value={getAmountComma(Number(this.state.rate_payableFormatted))}/>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Penalty</label>
                                <input className="form-control" type="text" disabled placeholder="Penalty" required="required" value={this.state.penalty}  onChange={(e)=>{
                                        
                                          this.setState((state, props) => ({
                                            penalty: Number(e.target.value),
                                            grand_total: stripComma(state.rate_payable) + Number(e.target.value) + stripComma(state.arrears),
                                          }));
                                        }}/>
                              </div>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Arrears</label>
                                <input className="form-control" type="text"  placeholder="Arrears" required="required" value={this.state.arrears} onChange={(e)=>{
                                          this.setState((state, props) => ({
                                            penalty: Number((Number(e.target.value) * 0.1).toFixed(2)),
                                            arrears: e.target.value,
                                            grand_total: stripComma(state.rate_payable) + stripComma(state.penalty) + Number(e.target.value),
                                          }));
                                        }}/>
                              </div>
                            </div>
                            
                            <div className='row'>
                            <div className="form-group  col-md-6">
                                <label className="control-label" style={{fontWeight:'bold'}}>Grand Total</label>
                                <input className="form-control" disabled type="text" placeholder="Grand Total" required="required" value={getAmountComma(Number(this.grandTotal()))} 
                                // onLoad={()=>{
                                //   this.setState((state, props) => ({
                                //     grand_total: this.grandTotal(),
                                //   }));
                                // }}
                                />
                              </div>
                              
                              
                            </div>
                            
                            
                            <button className="btn btn-primary nextBtn pull-right" onClick={()=> {
                              this.updateProperty()
                              }} type="button">Update Property</button>
                          </div>
                        </div>
                      </div>
                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> : <div className='card' style={{padding:'40px', display:'flex', justifyContent:'center', flexDirection:'row'}}> <div style={{padding:'20px', display:'flex', alignItems:'center', flexDirection:'column'}}><FeatherIcon icon="home"  size={70}></FeatherIcon> <div style={{height:'20px'}}></div><p>Search for a Property Above to display</p></div></div>}
                  </div>
                  <div className="col-sm-5">
                <div className='card'>
                  
                  <div className='card-body'>
                  {this.state.errorMessageUpload !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{this.state.errorMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>this.setState({errorMessageUpload:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {this.state.successMessageUpload !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{this.state.successMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>this.setState({successMessageUpload:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {this.state.warningMessageUpload !== "" ?
                    <div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'rgb(232, 188, 57)', borderColor:'rgb(232, 188, 57)'}} role="alert">
                      <div>
                        <div><strong>{this.state.warningMessageUpload}</strong></div>
                        <Link to="/updateproperties/failedupdates" state={{pids:this.state.failedPIDS}}>View Failed</Link>
                      </div>
                      <button class="btn-close" onClick={()=>this.setState({warningMessageUpload:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <p style={{fontWeight:'bold'}}> <span style={{marginRight:'20px'}}><FeatherIcon icon='upload' size={20}></FeatherIcon></span>Bulk Upload (Spool)</p>
                    <p style={{}}>Update properties in bulk</p>
                    <input class="form-control mb-20" type="file" onChange={(e)=>{
                      this.handleFileSelected(e)
                    }} aria-label="file example" required=""/>
                    <button className="btn btn-secondary pull-right" onClick={()=>{
                      this.uploadFile()
                    }}  type="button">Bulk Update</button>
                  </div>
                  
                </div>
              </div>
            </div>
          

        </div>
        </div>
  )
 }
}
