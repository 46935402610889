import React, { Component, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css";
import MyDataTable from '../components/MyDataTable';
import FeatherIcon from 'feather-icons-react';
import { getAmountComma, getUser, setFilterData, useLastPage, useTitleFunctional } from '../functions/Reusables';
import MyBasicDataTable from '../components/MyBasicDataTable';

export default function Unconsolidated (){
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist',shouldAction:true,},
    // { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    // { headerName:'RATE PAY',field: 'rate_payable',valueFormatter: params => getAmountComma(params.value)},
  ]


  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");
  const [errorMessageUpload, setErrorMessageUpload] = useState("");
  const [successMessageUpload, setSuccessMessageUpload] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadedExcel, setHasLoadedExcel] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputKey, setInputKey] = useState("");
  const [group_name, setGroupName] = useState("");
  const [prop_addr, setPropAddr] = useState("");
  const [asset_no, setAssetNo] = useState("");
  const [propertydata, setPropertData] = useState([]);

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  async function handleFileSelected(event){
    setSelectedFile(event.target.files[0])
    await getPropertyData(event.target.files[0])
    setIsDisabled(false)
  }
  function handleClear(event){
    setSelectedFile("")
    setGroupName("")
    setAssetNo("")
    setPropAddr("")
    setIsDisabled(true)
    setInputKey(Date.now())
    setHasLoadedExcel(false)
  }



  async function getPropertyData(file){
    setIsLoadingFile(true)
    const formData = new FormData() 
    
    formData.append('amacfile', file) 
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(selectedFile);
      let url = `${process.env.REACT_APP_API}cons/cons_view/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data.data)
      setPropertData(response.data.data)
      setHasLoadedExcel(true)
      setIsLoadingFile(false)
      // console.log(response.data)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setIsLoadingFile(false)
        return
      }
      setIsLoadingFile(false)
    }
  }
  async function consolidate(){
    setIsLoading(true)
    const formData = new FormData() 
    formData.append('amacfile', selectedFile) 
    formData.append('group_name', group_name) 
    formData.append('prop_addr', prop_addr) 
    formData.append('asset_no', asset_no) 

    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data" 
      }
      // console.log(formData);
      let url = `${process.env.REACT_APP_API}cons/bulk_cons/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      setIsLoading(false)
      setSuccessMessageUpload("Properties Consolidated Successfully with CONPID : " + response.data.conpid)
      handleClear()
      // console.log(response.data)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setIsLoading(false)
        setErrorMessageUpload(error.response.message)
        return
      }
      setIsLoading(false)
      setErrorMessageUpload("Something went wrong")
    }
  }
  const myRef = useRef()
  useTitleFunctional("Unconsolidated | AMAC Tenement")
  useLastPage("consolidate")
  
    return (
          <div className="page-body">
            {isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-8" >
                  <h3>Unconsolidated</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Consolidation</a></li>
                    <li className="breadcrumb-item">Unconsolidated</li>
                  </ol>
                </div>
                <div className="col-sm-4">
                  
                   
                  <div className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search" style={{display:'flex' }}>
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                        {/* <button style={{paddingLeft:'5px', paddingRight:'5px', marginLeft:'10px'}} className='btn btn-primary' onClick={()=>{
                          myRef.current.childMethod()
                          setFilterData({})
                        }}><FeatherIcon icon="filter" size={14}></FeatherIcon></button> */}
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
            <div className="col-sm-4">
                <div className='card'>
                  
                  <div className='card-body'>
                  {errorMessageUpload !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{errorMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>setErrorMessageUpload("")} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {successMessageUpload !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{successMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>setSuccessMessageUpload("")} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <p style={{fontWeight:'bold'}}> <span style={{marginRight:'20px'}}><FeatherIcon icon='layers' size={20}></FeatherIcon></span> Consolidate</p>
                    <div className='d-flex'>
                      <p style={{}}>Upload PIDS</p>
                     {isLoadingFile? <p style={{color:'green',fontWeight:'bold'}}>..FETCHING..</p> : <p></p>} 
                    </div>
                    
                    <input class="form-control mb-10" type="file" key={inputKey} onChange={(e)=>{
                      handleFileSelected(e)
                    }} aria-label="file example" required=""/>
                    <div className='d-flex'>
                      <div className="col-md-12 mb-10">
                        <label className="control-label">Group Name</label>
                        <input className="form-control" type="text" value={group_name} disabled= {isDisabled} placeholder="Group Name" required="required"  
                        onChange={(e)=>{
                          setGroupName(e.target.value)
                        }} 
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 mb-10">
                        <label className="control-label">Address</label>
                        <textarea className="form-control"  type="text" value={prop_addr} disabled= {isDisabled} placeholder="Property Address" required="required"   
                        onChange={(e)=>{
                          setPropAddr(e.target.value)
                        }} 
                        ></textarea>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className="col-md-12 mb-10">
                        <label className="control-label">Asset No.</label>
                        <input className="form-control"  type="text" value={asset_no} disabled = {isDisabled} placeholder="Asset No." required="required" 
                        onChange={(e)=>{
                          setAssetNo(e.target.value)
                        }}   
                        />
                      </div>
                    </div>
                    <div className='d-flex' style={{justifyContent:"space-between"}}>
                      <button className="btn btn-secondary pull-left" onClick={()=>{
                        handleClear()
                      }}  type="button">Clear</button>
                      <button className="btn btn-primary pull-right" disabled = {isDisabled}  onClick={()=>{
                        consolidate()
                      }}  type="button">Consolidate</button>
                    </div>
                    
                  </div>
                  
                </div>
              </div>
              <div className='col-md-8'>
                      {hasLoadedExcel ? <MyBasicDataTable col = {tableColumns} data = {propertydata} /> :<MyDataTable endpoint = "cons/un_cons" ref={myRef} col = {tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime}/> }
              
              </div>
            </div>
          </div>
        </div>
    )

}
