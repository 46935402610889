import axios from 'axios';
import React, { Component, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import {useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { BtnCellRendererViewOnly, BtnCellRendererViewPrint } from '../components/ActionButtons';
import MyDataTable from '../components/MyDataTable';
import { ShimmerThumbnail } from "react-shimmer-effects";
import { getAmountComma, getUser, saveSourcePage, setFilterData, setIsPrinting, setPropsData, showPrintLoader, useLastPage, useTitleFunctional, useUnhide } from '../functions/Reusables';
import Print from './print';
import PrintBulk from './printbulk';
import FeatherIcon from 'feather-icons-react';
import MyBasicDataTable from '../components/MyBasicDataTable';

export default function GenerateBills (){
  const tableColumns = [
    { headerName:'PID',field: 'pid',cellStyle: {'font-weight':'bold'}},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'RATE PAY',field: 'rate_payable',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'PRINT',field: 'prints',cellStyle: {'background-color': '#3254aa', color:'white'}, },
    {
      headerName:'',
      field: "pid",
      shouldAction:true,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function(field,id) {
          console.log(parseInt(field.slice(3)), id)
          if(id === 'view'){
            setIsViewing(true)
            getProperty((parseInt(field.slice(3))).toString(),id)
          }
          if(id === 'print'){
            console.log("PRINTNG")
            getProperty((parseInt(field.slice(3))).toString(),id)
          }
        }
      },
      maxWidth:180
    },
  ]
  const tableColumns2 = [
    { headerName:'PID',field: 'pid',cellStyle: {'font-weight':'bold'}},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'RATE PAY',field: 'rate_payable',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'PRINT',field: 'prints',cellStyle: {'background-color': '#3254aa', color:'white'}, },
    
  ]

  const frameworkComponents = {
    btnCellRenderer: BtnCellRendererViewPrint
  }

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isViewingPrint, setIsViewingPrint] = useState(false);
  const [propResponse, setPropResponse] = useState({});
  const [isViewingPrintBulk, setIsViewingPrintBulk] = useState(false);
  const [bulkResponse, setBulkResponse] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [searchPID, setSearchPID] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasLoadedExcel, setHasLoadedExcel] = useState(false);
  const [propertydata, setPropertData] = useState([]);
  const [isLoadingFile, setIsLoadingFile] = useState(false);


  let navigate = useNavigate();
  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }
  async function getProperty(pid,btnType){
    setIsLoading(true)
    const requestPID = {
      pid: pid,

    };
    const headers = {
      "AccessToken":getUser().access_token
    }
    try {
      console.log(requestPID)
      const response = await axios.post(
        `${process.env.REACT_APP_API}properties/get_property/`,
        requestPID,
        {headers}
      );
      setPropResponse({
        "pid": response.data.pid,
        "occupant": response.data.occupant,
        "prop_addr": response.data.prop_addr,
        "street_name": response.data.street_name,
        "asset_no": response.data.asset_no,
        "cadastral_zone": response.data.cadastral_zone,
        "prop_type": response.data.prop_type,
        "prop_use": response.data.prop_use,
        "rating_dist": response.data.rating_dist,
        "annual_value": response.data.annual_value,
        "rate_payable": response.data.rate_payable,
        "arrears": response.data.arrears,
        "penalty": response.data.penalty,
        "grand_total": response.data.grand_total,
        "category": response.data.category,
        "group": response.data.group,
        "active": response.data.active,
        "year": response.data.year,
      })
      if(btnType == 'print'){
        setIsViewingPrint(true)
      }
      setIsLoading(false)
      
    } catch (error) {
      const errors = []
      console.log(error)
      errors.push("Something went wrong")
      
      if(error.response !== null || error.response !== undefined){
        errors.push(error.response.data.message)
      }
      console.log(errors.toString())
    }
  }
  function closeModal(){
    setIsViewingPrint(false)
    setIsViewingPrintBulk(false)
  }

  function refreshTable(){
    setRand(new Date().getMilliseconds().toString())
  }

  async function getPropertyData(file){
    setIsLoadingFile(true)
    const formData = new FormData() 
    
    formData.append('amacfile', file) 
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(selectedFile);
      let url = `${process.env.REACT_APP_API}cons/cons_view/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data.data)
      setPropertData(response.data.data)
      setHasLoadedExcel(true)
      setIsLoadingFile(false)
      // console.log(response.data)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setIsLoadingFile(false)
        return
      }
      setIsLoadingFile(false)
    }
  }

  async function uploadFile(){
    setIsLoading(true)
    const formData = new FormData() 
    formData.append('amacfile', selectedFile)
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(selectedFile);
      let url = `${process.env.REACT_APP_API}bills/bulk_print/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data.data)
      setPropsData(response.data)
      localStorage.setItem('lastpage', 'bulkprint')
      saveSourcePage("generatebills")
      setIsPrinting(true)
      window.open('/bulkprint')


      
      // setBulkResponse(response.data)
      setIsLoading(false)
      // setIsViewingPrintBulk(true)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setIsLoading(false)
        return
      }
      setIsLoading(false)
    }
  }
  async function handleFileSelected(event){
    setSelectedFile( event.target.files[0])
    await getPropertyData(event.target.files[0])
    setIsDisabled(false)
  }
  function handleClear(event){
    document.getElementById("spoolID").value=null; 
    setSelectedFile("")
    setIsDisabled(true)
    setHasLoadedExcel(false)
    setRand(new Date().getMilliseconds().toString())
  }
  const myRef = useRef()
  useTitleFunctional("Generate Bills | AMAC Tenement")
  useLastPage("generatebills")
  useUnhide()
    return (
      <div>
        { isViewingPrintBulk ?<div class="modal fade show" id="exampleModalLong" style={{display:'block', backgroundColor:'#0000004a', overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'1000px'}}>
                  
                <div className="modal-content" id='printModal'>
                  <PrintBulk propResponses={bulkResponse} closeModal={closeModal} refreshTable={refreshTable} />
                </div>
              </div>
            </div>: <div></div>}
          <div className="page-body">
            
            { isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
            { isViewing ?<div class="modal fade show" id="exampleModalCenter" style={{display:'block', backgroundColor:'#0000004a',overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    {isLoading ? <ShimmerThumbnail height={30} className='myShimmerOveride' rounded /> :<h5 class="modal-title">{propResponse.pid}</h5>}
                    <button class="btn-close" type="button" onClick={()=>setIsViewing(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className='p-20'>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Occupant</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.occupant}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Assessment Number</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.asset_no}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Rating District</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.rating_dist}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Cadastrial Zone</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.cadastral_zone}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Use</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.prop_use}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Type</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.prop_type}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Category</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.category}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Property Address</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.prop_addr}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Special Action</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{propResponse.active}</p>}</div>
                    </div>
                    <p className=" m-0">VALUES</p>
                    <div style={{height:'1px', width:'100%', backgroundColor:'#80808042', marginTop:'5px',marginBottom:'5px'}}></div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Annual Value</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.annual_value)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Rate Payable</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.rate_payable)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Arrears</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.arrears)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Penalty</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.penalty)}</p>}</div>
                    </div>
                    <div className=" row mb-10">
                      <label className="col-sm-4 m-0">Grand Total</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0'>{getAmountComma(propResponse.grand_total)}</p>}</div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    {/* <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button> */}
                    <button  class="btn btn-primary" onClick={()=>{
                      setIsViewing(false)
                      setIsViewingPrint(true)
                    }} type="button">Print</button>
                  </div>
                </div>
              </div>
            </div>: <div></div>}
            { isViewingPrint ?<div class="modal fade show" id="exampleModalLong" style={{display:'block', backgroundColor:'#0000004a', overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'1000px'}}>
                  
                <div className="modal-content" id='printModal'>
                  <Print propResponse={propResponse} closeModal={closeModal} refreshTable={refreshTable} />
                </div>
              </div>
            </div>: <div></div>}
            
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col-md-4" >
                  <h3>Bills</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Generate Bills</a></li>
                    {/* <li className="breadcrumb-item">View Properties</li> */}
                  </ol>
                </div>
                <div className="col-md-8" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  <div style={{display:'flex', width:'100%', alignItems:'center'}}>
                    <div className='myInputDate'>
                      <p>Spool: {isLoadingFile ? <span>.. FETCHING ..</span>: <span></span>}</p>
                      <input class="form-control mb-20" id='spoolID' type="file" onChange={(e)=>{
                          handleFileSelected(e)
                        }} aria-label="file example" required=""/>
                    </div>
                    <div className='myInputDate'>
                      <p> </p>
                      <button style={{width:'130px'}} disabled={isDisabled} className="btn btn-primary pull-right" onClick={()=>{
                          uploadFile()
                        }}  type="button">Bulk Print</button>
                    </div>
                    <button className='btn btn-secondary' disabled={isDisabled} style={{padding:'5px', marginLeft:'5px'}} onClick={()=>{handleClear()}}>X</button>
                  </div>
                  <div className="input-group" style={{paddingLeft:'40px'}}><span style={{padding:'0'}} className="input-group-text">
                  <select class="form-select digits" id="exampleFormControlSelect9" onChange={(e)=>{
                    setSelectedFilter(e.target.value)
                    }}>
                      <option value="all" >All</option>
                      <option value="pid" >PID</option>
                  </select>
                  </span>
                    <input className="form-control" type="text" 
                    onChange={(e)=> {
                        setValue(e.target.value)
                        if(selectedFilter == 'all'){
                          if(e.target.value.length > 2){
                            setRand(e.target.value)
                            console.log("changed")
                          }
                          else if(e.target.value.length == 0){
                            setValue("")
                            setSearchPID("")
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setSearchPID(e.target.value)
                          if(e.target.value.length > 0){
                            setRand(e.target.value)
                            console.log("changed")
                          }
                          else if(e.target.value.length == 0){
                            setValue("")
                            setSearchPID("")
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                        
                      }} 
                      required="" placeholder="Search Term" /> 
                      <button style={{paddingLeft:'5px', paddingRight:'5px', marginLeft:'10px'}} className='btn btn-primary' onClick={()=>{
                          // setIsFloatingFilterEnabled(!isFloatingFilterEnabled)
                          // setRand(new Date().getMilliseconds().toString())
                          myRef.current.childMethod()
                          // console.log(myRef)
                          setFilterData({})
                        }}><FeatherIcon icon="filter" size={14}></FeatherIcon></button>
                  </div>
                  {/* <form className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </form> */}
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              {hasLoadedExcel ? <MyBasicDataTable col = {tableColumns2} data = {propertydata} /> :<MyDataTable endpoint = "bills/get_properties" ref={myRef} col = {tableColumns} search = {searchTerm} searchpid = {searchPID} startDate={startDateReq} endDate={endDateReq} key= {randTime}  frameworkComponents= {frameworkComponents}/>} 
            </div>
          </div>
        </div>
        </div>
    )

}
