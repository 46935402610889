import React, { Component,useState,useEffect } from 'react'
import FeatherIcon from 'feather-icons-react';
import { ShimmerThumbnail,ShimmerTable } from "react-shimmer-effects";
import { getAmountComma, getUser, saveLastPage, titleClass } from '../functions/Reusables';
import axios from "axios";




export default class  LegalDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoadingCards: true,
      isLoadingTable1: true,
      cardValues: {},
      table1Values: [],
    };
  }

    getCardData = async () => {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}legal/dash_legal/`,
        {headers})
        const dataList = response.data.data;
        const newMap = {}
        for (let index = 0; index < dataList.length; index++) {
          let key = Object.keys(dataList[index])[0]
          let value = Object.values(dataList[index])[0]
          newMap[key] = value
        }
        this.setState({
          cardValues: newMap,
          isLoadingCards:false
        });
    } catch (error) {
      console.log(error)
    }
  }
   getTableData2 = async () => {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}reminder/get_rem_due/?_page=1&_limit=5`,
        {headers})
        
        const dataList = response.data.data;
        this.setState((state, props) => ({
          table1Values: dataList,
          isLoadingTable1:false
        }));

        console.log(this.state.table2Values)
  
    } catch (error) {
      console.log(error)
    }
  }

   

  componentDidMount() {
    titleClass("Dashboard | AMAC Tenement")
    saveLastPage("dashboard")
    this.getCardData()
    this.getTableData2()
  }


  render() {
    return (
      <div>
        <div className="page-body">
          <div className="container-fluid dashboard-default-sec">
          <div class="page-header">
              <div class="row">
                <div class="col-sm-6">
                  <h3>Dashboard</h3>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                  </ol>
                </div>
                
              </div>
            </div>
            <div className="row" style={{marginTop:'20px'}}>
              <div className="col-xl-5 box-col-12 des-xl-100"> 
                <div className="row">
                  
                  
                  
                </div>
              </div>
              <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 box-col-12 des-xl-100 dashboard-sec">
                <div className="row">
                  
                  
                  
                </div>
              </div>
              <div class="col-sm-6 col-xl-6 col-lg-6">
                  {this.state.isLoadingCards ? <ShimmerThumbnail height={115} rounded /> :<div class="card o-hidden border-0">
                      <div class="bg-primary b-r-4 card-body">
                        <div class="media static-top-widget">
                          <div class="align-self-center text-center"><FeatherIcon icon="users"></FeatherIcon></div>
                          <div class="media-body"><span class="m-0">Due for Prosecution</span>
                            <h4 class="mb-0 counter">{`${this.state.cardValues.due_for_prosecution}`}</h4><FeatherIcon class="icon-bg" icon="users"></FeatherIcon>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div class="col-sm-6 col-xl-6 col-lg-6">
                  {this.state.isLoadingCards ? <ShimmerThumbnail height={115} rounded /> :<div class="card o-hidden border-0">
                      <div class="bg-secondary b-r-4 card-body" >
                        <div class="media static-top-widget">
                          <div class="align-self-center text-center"><FeatherIcon icon="users"></FeatherIcon></div>
                          <div class="media-body"><span class="m-0">Prosecuted</span>
                            <h4 class="mb-0 counter">{`${this.state.cardValues.prosecuted}`}</h4><FeatherIcon class="icon-bg" icon="users"></FeatherIcon>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
              {/* <div className="col-xl-7 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div class="card-header pb-0">
                        <h5>Bills Dispatched</h5>
                      </div>    
                      <div class="card-body pt-10">
                      <div class="table-responsive">
                        {this.state.isLoadingTable2 ? <ShimmerTable row={4} col={5} /> :<table class="table">
                          <thead>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">Recieved By</th>
                              <th scope="col">Date Delivered</th>
                              <th scope="col">Mode</th>
                    
                            </tr>
                          </thead>
                          <tbody>
                            
                              {this.state.table2Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.received_by}</td>
                                  <td>{items.date_delivered}</td>
                                  <td>{items.mode}</td>

                                </tr>
                              )}
                       
                          </tbody>
                        </table>}
                      </div>
                      </div>
           
                      
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-5 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div class="card-header pb-0">
                        <h5>21 Day Reminder Notice</h5>
                      </div>    
                      <div class="card-body pt-10">
                      <div class="table-responsive">
                        {this.state.isLoadingTable1 ? <ShimmerTable row={4} col={4} /> :<table class="table">
                          <thead>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">District</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Days Elapsed</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.table1Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.rating_dist}</td>
                                  <td>{items.grand_total}</td>
                                  <td>{items.elapsed_days}</td>

                                </tr>
                              )}
                            
                          </tbody>
                        </table>}
                      </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-13 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div class="card-header pb-0">
                        <h5>Due For Prosecution</h5>
                      </div>    
                      <div class="card-body pt-10">
                      <div class="table-responsive">
                        {this.state.isLoadingTable1 ? <ShimmerTable row={4} col={9} /> :<table class="table">
                          <thead style={{backgroundColor:"#efefef"}}>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">Occupant</th>
                              <th scope="col">Address</th>
                              <th scope="col">Asset No.</th>
                              <th scope="col">Cadastral Zone</th>
                              <th scope="col">Prop type</th>
                              <th scope="col">Prop Use</th>
                              <th scope="col">Rating Dist.</th>
                              <th scope="col">Annual Value</th>
                              <th scope="col">Grand Total</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.table1Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.occupant}</td>
                                  <td>{items.prop_addr}</td>
                                  <td>{items.asset_no}</td>
                                  <td>{items.cadastral_zone}</td>
                                  <td>{items.prop_type}</td>
                                  <td>{items.prop_use}</td>
                                  <td>{items.rating_dist}</td>
                                  <td>{getAmountComma(items.annual_value)}</td>
                                  <td>{getAmountComma(items.grand_total)}</td>

                                </tr>
                              )}
                            
                          </tbody>
                        </table>}
                      </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2022 © AMAC All rights reserved.</p>
              </div>
              
            </div>
          </div>
        </footer>

    </div>
    )
  }
}
