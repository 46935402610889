import React, { Component, useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from 'react-router-dom';
import BtnCellRenderer, { BtnCellRendererViewOnly } from '../components/ActionButtons';
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';

export default function ViewProsecutedDetails (){
  let navigate = useNavigate();
  const location = useLocation();
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'STATUS',field: 'status'},
    { headerName:'COMMENT',field: 'comment'},
    { headerName:'DATE CREATED.',field: 'created_at'},
    { headerName:'USER',field: 'legal'},
  ]
  

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
useTitleFunctional("Prosecuted Details | AMAC Tenement")
useLastPage("viewprosecuteddetails")
  
    return (
          <div className="page-body">
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Prosecuted Details For {location.state.pid}</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#" onClick={()=> navigate("/prosecuted")}>Prosecuted</a></li>
                    <li className="breadcrumb-item">Prosecuted Details</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>
                  <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div>
                   
                  <form className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </form>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <MyDataTable endpoint = "legal/get_pros" pid = {(parseInt(location.state.pid.slice(3))).toString()} col = { tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime}/> 
            </div>
          </div>
        </div>
    )

}
