import React, { Component, useState } from 'react'

import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { getNowDateFormatted, getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';
import { useLocation } from 'react-router-dom';

export default function CreateDispatch(){

  const location = useLocation();

  const [pid, setPID] = useState(""); 
  const [receivedby, setReceivedBy] = useState(""); 
  const [type, setType] = useState("Demand Notice"); 
  const [mode, setMode] = useState("HAND"); 
  const [successMessage, setSuccessMessage] = useState(""); 
  const [errorMessage, setErrorMessage] = useState(""); 
  const [isLoading, setIsLoading] = useState(false); 



  function isEmpty(str) {
    return !str.trim().length;
  }

 async function  createDispatch(){
  if(isEmpty(mode) || isEmpty(receivedby)){
    setErrorMessage("All Fields are required")
  }else{

    setIsLoading(true)
      const createRequest = {
        // "pid": (parseInt(location.state.pid.slice(3))).toString(),
        "pid": pid,
        "type": type,
        'date_delivered':  getNowDateFormatted(),
        'received_by':receivedby,
        "mode": mode,
        "process": getUser().user_id
      };
      const headers = {
        "AccessToken": getUser().access_token
      }
      try {
        console.log(createRequest)
        const response = await axios.post(
          `${process.env.REACT_APP_API}dispatch/create_dispatch/`,
          createRequest,
          {headers}
        );
          setSuccessMessage("Dispatch Created Successfully")
          setIsLoading(false)
        
      } catch (error) {
        const errors = []
        console.log(error)
        setErrorMessage("Something went wrong")
        
        if(error.response !== null || error.response !== undefined){
          setErrorMessage(error.response.data.message)
        }
        setIsLoading(false)
      }

  }
 }

 useTitleFunctional("Create Dispatch | AMAC Tenement")
 useLastPage("createdispatch")

    return (
          <div className="page-body">
             { isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Create Dispatch</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Dispatch</a></li>
                    <li className="breadcrumb-item">Create Dispatch</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>

                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid" style={{marginTop:'20px'}}>
            <div className="row">
            <div className="card">
           
                  <div className="card-header pb-0">
                    {/* <h5>Change Password</h5> */}
                  </div>
                  <div className="form theme-form">
                  {errorMessage!== "" ? 
                  <div className="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{errorMessage}</strong>
                    <button class="btn-close" onClick={()=>{
                     setErrorMessage("")
                    }} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                  </div>
                  : <div></div>
                  }
                    {successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{successMessage}</strong>
                      <button className="btn-close" onClick={()=>{
                        setSuccessMessage("")
                      }} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">PID</label>
                            <div className="col-sm-9">
                              <input className="form-control"  value={pid} type="text" placeholder="PID" onChange={(e)=>{
                                        setPID(e.target.value)
                                      }}/>
                              {/* <input className="form-control" disabled value={location.state.pid} type="text" placeholder="PID"/> */}
                            </div>
                          </div>
                       
                         
                          
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Dispatch Type</label>
                            <div className="col-sm-9">
                            <select class="form-select digits" value={type} id="exampleFormControlSelect9" onChange={(e)=>{
                                        setType(e.target.value)
                                      }}>
                                        
                                  <option value="Reminder Notice" >Reminder Notice</option>
                                  <option value="Demand Notice" >Demand Notice</option>
                                       
                                        
                                    </select>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Received By</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={receivedby} onChange={(e)=>{
                                setReceivedBy(e.target.value)
                              }} type="text" placeholder="Received By"/>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Dispatch Mode</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={mode} onChange={(e)=>{
                                setMode(e.target.value)
                              }} type="text" placeholder="Dispatch Mode"/>
                            </div>
                          </div>
                          
                          
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-end">
                      <div className="col-sm-9 offset-sm-3">
                        <button className="btn btn-primary" onClick={()=>{
                          createDispatch()
                        }} type="submit">Submit</button>
                        
                      </div>
                    </div>
                  </div>
                </div> 
            </div>
          </div>
        </div>
    )

}
