import axios from "axios";
import { useEffect, useLayoutEffect } from "react";
  export function getUser(){
    const loggedInUser = localStorage.getItem("user");
    return JSON.parse(loggedInUser)
  };
  export async function logOut  (){
    if(getUser() != null){
      const accessToken = getUser().access_token
      if(accessToken == null){
        return
      }
      const headers = {
        'AccessToken': accessToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API}user/logout/`,{headers}
      );
      console.log(response)
      localStorage.clear();
    }else{
      console.log("ALREADY LOGGED OUT")
    }
    
  };

  export function getAmountComma(value){
    if(value == "" || value == undefined){
      return "0"
    }
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
  }

  export function stripComma(value){
    return Number(value.toString().replaceAll(",",""))
  }

  export function getNowDateFormatted() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  export const setFilterDataFromColumn =(field, value)=>{
    var existingData = getFilterData()
    if(existingData != null){
      existingData[field] = value
    }else{
      existingData = {}
      existingData[field] = value
    }
    setFilterData(existingData)
    
  }

  export const useTitleFunctional = title => {
    useEffect(() => {
      const oldTitle = document.title;
      title && (document.title = title);
      // following line is optional, but will reset title when component unmounts
      return () => document.title = oldTitle;
    }, [title]);
  };
  export const setFilterData = filterdata => {
    localStorage.setItem('filterData', JSON.stringify(filterdata))
  };
  export const getFilterData = () => {
    const filterData = localStorage.getItem("filterData");
    return JSON.parse(filterData)
  };
  export const setIsPrinting = isPrinting => {
    localStorage.setItem('isPrinting', isPrinting)
  };
  export const getIsprinting = () => {
    const filterData = localStorage.getItem("isPrinting");
    return filterData
  };
  export const setPrintData = printdata => {
    localStorage.setItem('printData', JSON.stringify(printdata))
  };
  export const getPrintData = () => {
    const printData = localStorage.getItem("printData");
    return JSON.parse(printData)
  };
  export const setConsData = consData => {
    localStorage.setItem('consData', JSON.stringify(consData))
  };
  export const getConsData = () => {
    const consData = localStorage.getItem("consData");
    return JSON.parse(consData)
  };
  export const setPropsData = propsData => {
    localStorage.setItem('propsData', JSON.stringify(propsData))
  };
  export const getPropsData = () => {
    const propsData = localStorage.getItem("propsData");
    return JSON.parse(propsData)
  };
  export const setRemsData = remsData => {
    localStorage.setItem('remsData', JSON.stringify(remsData))
  };
  export const getRemsData = () => {
    const remsData = localStorage.getItem("remsData");
    return JSON.parse(remsData)
  };
  export const saveHasSeenReminder = value => {
    localStorage.setItem('hasSeenReminder', value)
  };
  export function getHasSeenReminder(){
    const value = localStorage.getItem("hasSeenReminder");
    return value
  };
  export const saveLastID = page => {
    localStorage.setItem('lastid', page)
  };
  export function getLastID(){
    const lastPage = localStorage.getItem("lastid");
    return lastPage
  };
  export const useLastPage = page => {
    useEffect(() => {
      localStorage.setItem('lastpage', page)
      document.getElementById('divLoader').style.display = 'none'
      document.getElementById('main-side').style.display = 'block'
      document.getElementById('main-header').style.display = 'block'
    }, [page]);
  };
  export const saveLastPage = page => {
    localStorage.setItem('lastpage', page)
    document.getElementById('divLoader').style.display = 'none'
    document.getElementById('main-side').style.display = 'block'
    document.getElementById('main-header').style.display = 'block'
  };
  export function getLastPage(){
    const lastPage = localStorage.getItem("lastpage");
    return lastPage
  };
  export const saveSourcePage = page => {
    localStorage.setItem('sourcepage', page)
  };
  export function getSourcePage(){
    const sourcepage = localStorage.getItem("sourcepage");
    return sourcepage
  };

  export const titleClass = title => {
    document.title = title
  };

  export function useUnhide(){
    useEffect(() => {
      document.getElementById('main-side').style.display = 'block'
      document.getElementById('main-header').style.display = 'block'
    }, []);
  };
  export function useUnhidePrintLoader(){
    useLayoutEffect(() => {
      document.getElementById('print-loader').style.display = 'block'
    }, []);
  };
  export function hidePrintLoader(){
      if(document.getElementById('divLoader') != null){
        document.getElementById('divLoader').style.display = 'none'
      }

  };
  export function showPrintLoader(){

  };
