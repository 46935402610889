import React, {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'; 
import FeatherIcon from 'feather-icons-react';

export default forwardRef((props, ref) => {
  const [currentValue, setCurrentValue] = useState(null);
  const inputRef = useRef(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        // if (!parentModel) {
        //   inputRef.current.value = '';
        //   setCurrentValue(null);
        // } else {
        //   inputRef.current.value = parentModel.filter + '';
        //   setCurrentValue(parentModel.filter);
        // }
      },
    };
  });

  const onInputBoxChanged = (input) => {
    // if (input.target.value === '') {
    //   // Remove the filter
    //   props.parentFilterInstance((instance) => {
    //     instance.onFloatingFilterChanged(null, null);
    //   });
    //   return;
    // }

    setCurrentValue(input.target.value);
    props.parentFilterInstance(input.target.value, props.column.colDef.field)
    // props.parentFilterInstance((instance) => {
    //   instance.onFloatingFilterChanged('greaterThan', input.target.value);
    // });
  };



  const performTask =()=>{
    props.parentFilterInstance()
    // gridApi.purgeServerSideCache()
    // console.log(props.api.purgeServerSideCache())
    props.api.purgeServerSideCache()
    // var model =  props.api.getModel()
    // model.datasource.getRows()
    // console.log(props.column.colDef.shouldAction)
    // console.log(props.columnApi.columnModel.displayedColumns.length)
    // console.log(props.columnApi.columnModel.displayedColumns.length())
  }

  const style = {
    // color: props.color,
    width: '100%',
  };

  return (
    props.column.colDef.shouldAction == false ? <Fragment>
      <input
        ref={inputRef}
        style={style}
        type="text"
        min="0"
        onInput={onInputBoxChanged}
      />
    </Fragment> : <Fragment>
      <button style={{paddingLeft:'7px', paddingRight:'7px', fontSize:'10px'}} className='btn btn-primary' onClick={performTask}>Search</button>
    </Fragment>
  );
});