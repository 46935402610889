import React, { useState , useEffect} from 'react'
import {useNavigate } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import {getIsprinting, getUser} from "../functions/Reusables";


export default function Sidebar(props){
   const adminmenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Properties','icon':'airplay', 'sub':[{'name':'View Properties'},{'name':'Create Properties'},{'name':'Update Properties'},{'name':'Upload Address'}]},
    {'name':'Bills','icon':'file-text', 'sub':[{'name':'Generate Bills'},{'name':'Edit Arrears'}]},
    {'name':'Consolidation','icon':'layers', 'sub':[{'name':'Consolidate'},{'name':'Print & Generate Bill'}]},
    {'name':'Reminders','icon':'bell', 'sub':[{'name':'Reminder Notice'},{'name':'7 Days Reminder'},{'name':'21 Days Reminder'},{'name':'Due For Prosecution'},{'name':'Prosecuted'}]},
    {'name':'Discounts','icon':'percent', 'sub':[]},
    {'name':'Dispatch','icon':'send', 'sub':[{'name':'View Dispatch'}]},
    {'name':'Account','icon':'inbox', 'sub':[{'name':'All Accounts'},{'name':'Create Account'}]},
    {'name':'Roles Assign','icon':'users', 'sub':[{'name':'Management Accounts'},{'name':'Zonal Managers'},{'name':'Revenue Officers'},{'name':'Processing Officers'}]},
    {'name':'Payment Report','icon':'trending-up', 'sub':[]},
    {'name':'Audit','icon':'activity', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
   const directormenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Properties','icon':'airplay', 'sub':[{'name':'View Properties'}]},
    {'name':'Reminders','icon':'bell', 'sub':[{'name':'Reminder Notice'},{'name':'7 Days Reminder'},{'name':'21 Days Reminder'},{'name':'Due For Prosecution'},{'name':'Prosecuted'}]},
    {'name':'Dispatch','icon':'send', 'sub':[{'name':'View Dispatch'}]},
    {'name':'Users','icon':'users', 'sub':[{'name':'Zonal Managers'},{'name':'Revenue Officers'},{'name':'Processing Officers'}]},
    {'name':'Payment Report','icon':'trending-up', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
   const financemenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Daily Payment','icon':'calendar', 'sub':[]},
    {'name':'Weekly Payment','icon':'calendar', 'sub':[]},
    {'name':'Monthly Payment','icon':'calendar', 'sub':[]},
    {'name':'Payment History','icon':'clock', 'sub':[]},
    {'name':'Make Payment','icon':'credit-card', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
   const legalmenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Due For Prosecution','icon':'users','sub':[]},
    {'name':'Prosecuted','icon':'users','sub':[]},
    {'name':'Payment History','icon':'clock', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
   const itsupportmenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Properties','icon':'airplay', 'sub':[{'name':'View Properties'},{'name':'Upload Address'}]},
    {'name':'Bills','icon':'file-text', 'sub':[{'name':'Generate Bills'}]},
    {'name':'Consolidation','icon':'layers', 'sub':[{'name':'Print & Generate Bill'}]},
    {'name':'Reminders','icon':'bell', 'sub':[{'name':'Reminder Notice'}]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
   const processingmenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Dispatch','icon':'send', 'sub':[{'name':'View Dispatch'},{'name':'Create Dispatch'}]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
  const zonalmenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Properties','icon':'airplay', 'sub':[{'name':'View Properties'}]},
    {'name':'Reminders','icon':'bell', 'sub':[{'name':'Reminder Notice'},{'name':'7 Days Reminder'},{'name':'21 Days Reminder'},{'name':'Due For Prosecution'},{'name':'Prosecuted'}]},
    {'name':'Dispatch','icon':'send', 'sub':[{'name':'View Dispatch'}]},
    {'name':'Users','icon':'users', 'sub':[{'name':'Revenue Officers'},{'name':'Processing Officers'}]},
    {'name':'Payment Report','icon':'trending-up', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
  const revenuemenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Properties','icon':'airplay', 'sub':[{'name':'View Properties'}]},
    {'name':'Dispatch','icon':'send', 'sub':[{'name':'View Dispatch'}]},
    {'name':'Reminders','icon':'bell', 'sub':[{'name':'Reminder Notice'},{'name':'7 Days Reminder'},{'name':'21 Days Reminder'},{'name':'Due For Prosecution'},{'name':'Prosecuted'}]},
    {'name':'Payment Report','icon':'trending-up', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
  const hoomenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Properties','icon':'airplay', 'sub':[{'name':'View Properties'}]},
    {'name':'Bills','icon':'file-text', 'sub':[{'name':'Demand By District'},{'name':'Reminder by District'}]},
    {'name':'Reminders','icon':'bell', 'sub':[{'name':'Reminder Notice'},{'name':'7 Days Reminder'},{'name':'21 Days Reminder'},{'name':'Due For Prosecution'},{'name':'Prosecuted'}]},
    {'name':'Dispatch','icon':'send', 'sub':[{'name':'View Dispatch'}]},
    {'name':'Users','icon':'users', 'sub':[{'name':'Revenue Officers'},{'name':'Processing Officers'},{'name':'Zonal Managers'}]},
    {'name':'Payment Report','icon':'trending-up', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]
  const auditmenuItems = [
    {'name':'Dashboard','icon':'home', 'sub':[]},
    {'name':'Audit','icon':'activity', 'sub':[]},
    {'name':'Change Password','icon':'settings', 'sub':[]},
  ]

  
  let navigate = useNavigate();

  const [selectedIndex, setValue] = useState(0);
  const [user, setUser] = useState({});
  const [hasLoadedUser, setHasLoaded] = useState(false);
  const [setMenu, setMainMenu] = useState([]);
  const [printStatus, setPrintStatus] = useState(false);

  

  function OpenDropdown ( itemIndex, name ){
    if(setMenu[itemIndex].sub.length == 0){
      GotoPage(name)
      return;
    }
    if(itemIndex == selectedIndex){
      setValue(0);
      return;
    } 
    // this.setState(
    //   {selectedIndex : itemIndex}
    // );
    setValue(itemIndex);
  }

   const gotoPageNavigate = (e,name) => {
     if(e != null){
      e.preventDefault();
     }
    
    let newpath = "";
    newpath = name.replaceAll(" ","");
    newpath = newpath.toLowerCase();
    localStorage.removeItem("filterData");
    console.log(newpath);
    navigate("/"+newpath);
  }
  function GotoPage (name){
    
    // const navigation = useNavigate();
    // const history = useHistory();
    let newpath = "";
    newpath = name.replaceAll(" ","");
    newpath = newpath.toLowerCase();
    console.log(newpath);
    newpath = `${process.env.REACT_APP_URL}${newpath}`
    // newpath = `${appConfig.SERVER_URL}${newpath}`
    // newpath = "/"+newpath;
    // history.push("/"+newpath);
    // navigation("/"+name);
    return newpath
  }

  useEffect(() => {
    localStorage.removeItem("filterData");
    var printStatus = getIsprinting()
    setPrintStatus(printStatus)
    if (getUser() == undefined || getUser() == null) {
      console.log("NOT ALLOWED")
      gotoPageNavigate(null,"/")
    }else{
      let user = getUser()
      console.log("REFRESH")
      setUser(getUser());
      if(user.role == '11'||user.role == '12'){
        setMainMenu(adminmenuItems)
      }else if(user.role == '6'){
        setMainMenu(financemenuItems)
      }else if(user.role == '9'||user.role == '10'){
        setMainMenu(directormenuItems)
      }else if(user.role == '7'){
        setMainMenu(legalmenuItems)
      }else if(user.role == '13'){
        setMainMenu(itsupportmenuItems)
      }else if(user.role == '2'){
        setMainMenu(processingmenuItems)
      }else if(user.role == '4'){
        setMainMenu(zonalmenuItems)
      }else if(user.role == '1'){
        setMainMenu(revenuemenuItems)
      }else if(user.role == '5'){
        setMainMenu(hoomenuItems)
      }else if(user.role == '8'){
        setMainMenu(auditmenuItems)
      }
      setHasLoaded(true)

      
    }
    
    // handleLogout();
  }, []);
  // render() {
    console.log(props.isPrinting)

    return (

      
        <header className="main-nav" id='main-side'>
          <div className="sidebar-user text-center"><img className="img-90 rounded-circle" src="../assets/images/dashboard/1.png" alt=""/>
            <div className="badge-bottom"></div><a href="user-profile.html"> 
              { hasLoadedUser ? <h6 className="mt-3 f-14 f-w-600">{user.user_name.toUpperCase()}</h6> : <h6 className="mt-3 f-14 f-w-600">loading</h6>}
              </a>
            {hasLoadedUser ? <p className="mb-0 font-roboto">{user.email}</p> : <p className="mb-0 font-roboto">loading</p>}
          </div>
          <nav>
            <div className="main-navbar">
              <div className="left-arrow" id="left-arrow"><FeatherIcon icon="arrow-left"></FeatherIcon></div>
              <div id="mainnav">           
                <ul className="nav-menu custom-scrollbar">
                  <li className="back-btn">
                    <div className="mobile-back text-end"><span>Back</span><i className="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
                  </li>
                  {
                    setMenu.map((items, index)=>{
                      return <li className="dropdown"><a className="nav-link menu-title" href="javascript:void(0)" onClick={(e)=>{
                        if(items.sub.length == 0){
                          gotoPageNavigate(e,items.name)
                          
                        }else{
                          OpenDropdown(index, items.name);
                        }
                        
                      }}>< FeatherIcon icon={items.icon} /><span>{items.name}</span><div className='according-menu'><FeatherIcon icon= {items.sub.length == 0 ? "nothing"  :selectedIndex == index ? "chevron-down" : "chevron-right"}></FeatherIcon></div></a>
                        <ul className="nav-submenu menu-content" style={{display: selectedIndex == index? 'block': 'none'}}>
                        {
                          items.sub.map((submenu)=>{
                            return <li ><a href="#" onClick={(e)=>{
                              // window.location.href = `${GotoPage(submenu.name)}`;
                              // navigate(GotoPageNavigate(submenu.name))
                              gotoPageNavigate(e,submenu.name)
                            }}>{submenu.name}</a></li>
                          })
                        }
                        </ul>
                      </li>
                    })
                  }
                  
                </ul>
              </div>
              <div className="right-arrow" id="right-arrow"><FeatherIcon icon="arrow-right"></FeatherIcon></div>
            </div>
          </nav>
        </header>
      
    )

}
