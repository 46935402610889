import axios from 'axios';
import React, { Component, useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import BtnCellRenderer, { BtnCellRendererViewCreate, BtnCellRendererViewOnly } from '../components/ActionButtons';
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';
import { ShimmerThumbnail ,ShimmerTable} from "react-shimmer-effects";

export default function DueForProsecution (){
  let navigate = useNavigate();
  const tableColumnsLegal = [
    { headerName:'PID',field: 'pid'},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'RATE PAY',field: 'grand_total',valueFormatter: params => getAmountComma(params.value)},
    {
      headerName:'Action',
      field: "pid",
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function(field,id) {
          console.log(parseInt(field.slice(3)), id)
          if(id === 'view'){
            getProperty((parseInt(field.slice(3))).toString());
          }
          if(id === 'create'){
            navigate("/createprosecuted", { state:{ pid:  field} });
          }
        }
      },
      maxWidth:300
    },
  ]
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'RATE PAY',field: 'grand_total',valueFormatter: params => getAmountComma(params.value)},
    
  ]
  const frameworkComponents = {
    btnCellRenderer: BtnCellRendererViewCreate
  }

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isViewing, setisViewing] = useState(false);
  const [propResponse, setPropResponse] = useState({});
  const [propResponse2, setPropResponse2] = useState([]);

  async function getProperty(pid){
    setisLoading(true)
    const requestPID = {
      pid: pid,
    };
    const headers = {
      "AccessToken":getUser().access_token
    }
    setisViewing(true)
    try {
      console.log(requestPID)
      const response = await axios.post(
        `${process.env.REACT_APP_API}fpay/get_details/`,
        requestPID,
        {headers}
      );
      console.log(response)
      setPropResponse({
        "pid": response.data.f_data.pid,
        "occupant": response.data.f_data.occupant,
        "prop_addr": response.data.f_data.prop_addr,
        "street_name": response.data.f_data.street_name,
        "asset_no": response.data.f_data.asset_no,
        "cadastral_zone": response.data.f_data.cadastral_zone,
        "prop_type": response.data.f_data.prop_type,
        "prop_use": response.data.f_data.prop_use,
        "rating_dist": response.data.f_data.rating_dist,
        "annual_value": response.data.f_data.annual_value,
        "rate_payable": response.data.f_data.rate_payable,
        "arrears": response.data.f_data.arrears,
        "penalty": response.data.f_data.penalty,
        "grand_total": response.data.f_data.grand_total,
        "category": response.data.f_data.category,
        "group": response.data.f_data.group,
        "active": response.data.f_data.active
      })
      setPropResponse2(response.data.s_data)
      setisLoading(false)
      
    } catch (error) {
      const errors = []
      console.log(error)
      errors.push("Something went wrong")
      
      if(error.response !== null || error.response !== undefined){
        errors.push(error.response.data.message)
      }
      console.log(errors.toString())
    }
  }

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  useTitleFunctional("Due For Prosecution | AMAC Tenement")
  useLastPage("dueforprosecution")
  
    return (
          <div className="page-body">
            { isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          { isViewing ?<div class="modal fade show" id="exampleModalCenter" style={{display:'block', backgroundColor:'#0000004a',overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div class="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'600px'}}>
                <div class="modal-content">
                  <div class="modal-header">
                    {isLoading ? <ShimmerThumbnail height={30} className='myShimmerOveride' rounded /> :<h5 class="modal-title">{propResponse.pid}</h5>}
                    <button class="btn-close" type="button" onClick={()=>setisViewing(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className='p-20' style={{fontSize:'13px'}}>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Occupant</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.occupant}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Assessment Number</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.asset_no}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Rating District</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.rating_dist}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Cadastrial Zone</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.cadastral_zone}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Property Use</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.prop_use}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Property Type</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.prop_type}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Property Category</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.category}</p>}</div>
                    </div>
                    <div className=" row mb-20 ">
                      <label className="col-sm-4 m-0">Property Address</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.prop_addr}</p>}</div>
                    </div>
                    
                    <p className=" m-0">VALUES</p>
                    <div style={{height:'1px', width:'100%', backgroundColor:'#80808042', marginTop:'5px',marginBottom:'5px'}}></div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Annual Value</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.annual_value)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Rate Payable</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.rate_payable)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Arrears</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.arrears)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Penalty</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.penalty)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Grand Total</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.grand_total)}</p>}</div>
                    </div>
                    <div className='mb-20'></div>
                    {isLoading ? <ShimmerTable row={2} col={6} /> :<table className="table">
                          <thead style={{backgroundColor:"#efefef"}}>
                            <tr>
                              <th scope="col">Trans. ID</th>
                              <th scope="col">Prev</th>
                              <th scope="col">Paid</th>
                              <th scope="col">Balance</th>
                              <th scope="col">Month</th>
                              <th scope="col">Date Paid</th>
                            </tr>
                          </thead>
                          <tbody>
                          {propResponse2.map((items)=>
                                <tr>
                                  <th scope="row">{items.transaction_id}</th>
                                  <td>{getAmountComma(items.current_amt)}</td>
                                  <td>{getAmountComma(items.paid_amt)}</td>
                                  <td>{getAmountComma(items.balance)}</td>
                                  <td>{items.month}</td>
                                  <td>{items.date_paid}</td>

                                </tr>
                              )}
                          
                            
                          </tbody>
                        </table>}
                  </div>
                  {/* <div class="modal-footer">
                    <button  class="btn btn-primary" onClick={()=>{
                      setisViewing(false)
                      // setIsViewingPrint(true)
                    }} type="button">Print</button>
                  </div> */}
                </div>
              </div>
            </div>: <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Due For Prosecution</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Reminders</a></li>
                    <li className="breadcrumb-item">Due For Prosecution</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>
                  {/* <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div> */}
                   
                  <form className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </form>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <MyDataTable endpoint = "reminder/get_rem_due" col = {getUser().role == "7" ? tableColumnsLegal : tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime} frameworkComponents= {frameworkComponents}/> 
            </div>
          </div>
        </div>
    )

}
