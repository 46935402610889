import React, { Component, useState } from 'react'

import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { getUser, saveLastPage, titleClass } from '../functions/Reusables';

export default class CreateAccount extends Component{

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      gm_level: '',
      isLoading:false,
      successMessage:"",
      errorMessage:[],
      gm_level:"2",
    };
  }

  isEmpty(str) {
    return !str.trim().length;
  }

 async  createAccount(){
  if(this.isEmpty(this.state.first_name) || this.isEmpty(this.state.last_name) || this.isEmpty(this.state.email) || this.isEmpty(this.state.phone) ){
    this.setState({
      errorMessage : ["All Fields are REQUIRED!"]
    })
  }else{

    this.setState({
      isLoading : true
    })
      const createRequest = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone,
        gm_level: this.state.gm_level,
      };
      const headers = {
        "AccessToken": getUser().access_token
      }
      try {
        console.log(createRequest)
        const response = await axios.post(
          `${process.env.REACT_APP_API}user/register/`,
          createRequest,
          {headers}
        );

        this.setState({
          successMessage : "Account Created Successfully",
          isLoading: false
        })
        
      } catch (error) {
        const errors = []
        console.log(error)
        errors.push("Something went wrong")
        
        if(error.response !== null || error.response !== undefined){
          errors.push(error.response.data.message)
        }
        this.setState({
          errorMessage : errors,
          isLoading: false
        })
      }

  }
 }
 componentDidMount(){
   titleClass("Create Account | AMAC Tenement");
   saveLastPage("createaccount")
 }


 render(){
    return (
          <div className="page-body">
             { this.state.isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Create Account</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Account</a></li>
                    <li className="breadcrumb-item">Create Account</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>

                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid" style={{marginTop:'20px'}}>
            <div className="row">
            <div className="card">
           
                  <div className="card-header pb-0">
                    {/* <h5>Change Password</h5> */}
                  </div>
                  <div className="form theme-form">
                  {this.state.errorMessage.length !== [] ? 
                  this.state.errorMessage.map((error, index)=>{
                    return <div className="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{error}</strong>
                    <button class="btn-close" onClick={()=>{
                      this.setState({
                        errorMessage:[]
                      })
                    }} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                  </div>
                  }): <div></div>
                  }
                    {this.state.successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{this.state.successMessage}</strong>
                      <button className="btn-close" onClick={()=>{
                        this.setState({
                          successMessage:""
                        })
                      }} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          {/* <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label" >Old Password</label>
                            <div className="col-sm-9">
                              <input className="form-control" type="password" placeholder="Type your Old Password"/>
                            </div>
                          </div> */}
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">First Name</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={this.state.first_name} onChange={(e)=>{
                                this.setState({
                                  first_name: e.target.value
                                })
                              }} type="text" placeholder="Type your First Name"/>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Last Name</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={this.state.last_name} onChange={(e)=>{
                                this.setState({
                                  last_name: e.target.value
                                })
                              }} type="text" placeholder="Type your Last Name"/>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Email Address</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={this.state.email} onChange={(e)=>{
                                this.setState({
                                  email: e.target.value
                                })
                              }} type="text" placeholder="Enter Email"/>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Phone Number</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={this.state.phone} onChange={(e)=>{
                                this.setState({
                                  phone: e.target.value
                                })
                              }} type="text" placeholder="Enter phone Number"/>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Role</label>
                            <div className="col-sm-9">
                            <select class="form-select digits" value={this.state.gm_level} id="exampleFormControlSelect9" onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          gm_level: e.target.value
                                        }));
                                      }}>
                                        
                                        <option value="2" >{"processor".toUpperCase()}</option>
                                        <option value="5" >{"head of operation".toUpperCase()}</option>
                                        <option value="3">{"unit head".toUpperCase()}</option>
                                        <option value="9" >{"director".toUpperCase()}</option>
                                        <option value="10" >{"deputy director".toUpperCase()}</option>
                                        <option value="6" >{"finance".toUpperCase()}</option>
                                        <option value="12" >{"super admin".toUpperCase()}</option>
                                        <option value="11">{"it".toUpperCase()}</option>
                                        <option value="7" >{"legal unit".toUpperCase()}</option>
                      
                                        <option value="1" >{"revenue officer".toUpperCase()}</option>
                                        <option value="4" >{"zonal manager".toUpperCase()}</option>
                                        <option value="8">{"audit".toUpperCase()}</option>
                                        <option value="13" >{"itsupport".toUpperCase()}</option>
                                        
                                    </select>
                            </div>
                          </div>
                          
                          
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-end">
                      <div className="col-sm-9 offset-sm-3">
                        <button className="btn btn-primary" onClick={()=>{
                          this.createAccount()
                        }} type="submit">Submit</button>
                        
                      </div>
                    </div>
                  </div>
                </div> 
            </div>
          </div>
        </div>
    )
 }
}
