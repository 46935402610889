import React, { Component, useCallback, useMemo, useRef, useState, useEffect, useImperativeHandle } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getFilterData, getUser, setFilterData, setFilterDataFromColumn } from '../functions/Reusables';
import { BtnSAVE } from './ActionButtons';
import axios from 'axios';
import customFloatingFilterComponent from './customFloatingFilterComponent';
import customFloatingFilterComponentAction from './customFloatingFilterComponentAction';
import { useIdleTimerContext } from 'react-idle-timer';


export default React.forwardRef((props, ref) => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: 'calc(100vh - 280px)', width: '100%' }), []);
  // const gridStyle = useMemo(() => ({   width: '100%' }), []);

 const rowStyle = {
   fontSize:"12px",
   padding:"0px"
 }

 useImperativeHandle(ref, () => ({
  childMethod() {
    childMethod()
  }
}))

function childMethod() {
  if(isFloatingFilterEnabled == true){
    localStorage.removeItem("filterData");
    gridRef.current.api.purgeServerSideCache()
  }
  setIsFloatingFilterEnabled(!isFloatingFilterEnabled)
  let colDefs =  gridRef.current.api.getColumnDefs();
      colDefs.forEach(colDef => {
        colDef.floatingFilter = !isFloatingFilterEnabled;
      })
  gridRef.current.api.setColumnDefs(colDefs);
  gridRef.current.api.refreshHeader();
}

  const [gridApi, setGridApi] = useState(null)
  const [isFloatingFilterEnabled, setIsFloatingFilterEnabled] = useState(false)

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      // floatingFilter: props.isFloatingFilterEnabled,
      floatingFilter: isFloatingFilterEnabled,
      filter: false,
      suppressMenu: true,
      shouldAction:false,
      filter : 'agTextColumnFilter', 
      floatingFilterComponent: customFloatingFilterComponentAction,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        // color: 'red',
        parentFilterInstance : function(searchTerm,field){
          setFilterDataFromColumn(field,searchTerm)
        }
      },
      // sortable: true
    };
  }, []);



  let saveSource =  {
      getExport:async function(params){
        let url = `${process.env.REACT_APP_API}${props.endpoint}/?`;
        if(props.search != "" ||  props.search != undefined){
          
        }else{
          url+=`&q=${props.search}`
        }
        console.log(props) 
        if(props.startDate != undefined || props.endDate != undefined){
          if(props.startDate !== '' && props.endDate !==''){
            // if(props.dateStart != ""){
              url+=`&from_date=${props.startDate}`
            // }
            // if(props.dateEnd != ""){
              url+=`&to_date=${props.endDate}`
            // }
          }
        }

        let access_token =  getUser().access_token
        const headers ={
          'AccessToken':access_token
        }
        console.log(url);
        try {
          const response = await axios.get(
            url,
             {headers})
          console.log(response.data.data)
          console.log(params)
          // gridRef.current.api.setServerSideDatasource(response.data);
          // gridRef.current.api.successCallback(response.data.data, response.data.recordsTotal)
          // gridRef.current.api.exportDataAsExcel();
        } catch (error) {
          console.log(error)
        }

      }
  
  };
  let dataSource =  {

    getExportExcel:async function(params){
      let url = `${process.env.REACT_APP_API}${props.endpoint}/?ext_type=excel`;
      if(props.search != "" ||  props.search != undefined){
        url+=`&q=${props.search}`
      }
      console.log(props) 
      if(props.startDate != undefined || props.endDate != undefined){
        if(props.startDate !== '' && props.endDate !==''){
          // if(props.dateStart != ""){
            url+=`&from_date=${props.startDate}`
          // }
          // if(props.dateEnd != ""){
            url+=`&to_date=${props.endDate}`
          // }
        }
      }
      var obj = getFilterData()
      if(obj != undefined || obj != null ){
        if(Object.keys(obj).length !== 0){
          console.log("emptyobject")
          url+=`&f_dict=${JSON.stringify(getFilterData())}`
        }
        
      }
      let access_token =  getUser().access_token
      const headers ={
        'AccessToken':access_token
      }
      console.log(url);
      try {
        const response = await axios.get(
          url,
           {headers})
        console.log(response.data.message)
          window.open(`${process.env.REACT_APP_API}${response.data.message}`)

      } catch (error) {
        console.log(error)
      }

    },
    getExportCSV:async function(params){
      let url = `${process.env.REACT_APP_API}${props.endpoint}/?ext_type=csv`;
      if(props.search != "" ||  props.search != undefined){
        url+=`&q=${props.search}`
      }
      console.log(props) 
      if(props.startDate != undefined || props.endDate != undefined){
        if(props.startDate !== '' && props.endDate !==''){
          // if(props.dateStart != ""){
            url+=`&from_date=${props.startDate}`
          // }
          // if(props.dateEnd != ""){
            url+=`&to_date=${props.endDate}`
          // }
        }
      }
      var obj = getFilterData()
      if(obj != undefined || obj != null ){
        if(Object.keys(obj).length !== 0){
          console.log("émptyobject")
          url+=`&f_dict=${JSON.stringify(getFilterData())}`
        }
        
      }
      let access_token =  getUser().access_token
      const headers ={
        'AccessToken':access_token
      }
      console.log(url);
      try {
        const response = await axios.get(
          url,
           {headers})
        console.log(response.data.message)
          window.open(`${process.env.REACT_APP_API}${response.data.message}`)

      } catch (error) {
        console.log(error)
      }

    },
      
      getRows: function (params) {
        console.log(props);
        console.log(JSON.stringify(params.request,null,1))
        const {startRow, endRow} = params.request;
        let url = `${process.env.REACT_APP_API}${props.endpoint}/?`;
        url+=`_page=${(startRow/15) +1}&_limit=15`
        if(props.search != "" ||  props.search != undefined){
          url+=`&q=${props.search}`
        }
        console.log(props) 
        if(props.startDate != undefined || props.endDate != undefined){
          if(props.startDate !== '' && props.endDate !==''){
            // if(props.dateStart != ""){
              url+=`&from_date=${props.startDate}`
            // }
            // if(props.dateEnd != ""){
              url+=`&to_date=${props.endDate}`
            // }
          }
        }
        if(props.rating_dist != undefined ){
          if(props.rating_dist !== ''){
              url+=`&rating_dist=${props.rating_dist}`
          }
        }
        if(props.searchpid != undefined ){
          if(props.searchpid !== ''){
              url+=`&_pid=${props.searchpid}`
          }
        }
        var obj = getFilterData()
        if(obj != undefined || obj != null ){
          if(Object.keys(obj).length !== 0){
            console.log("émptyobject")
            url+=`&f_dict=${JSON.stringify(getFilterData())}`
          }
          
        }

        let access_token =  getUser().access_token
        console.log(url);

        if(props.pid){
          fetch(url,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'AccessToken':access_token
            },
            body: JSON.stringify({ pid: props.pid })
          }
          )
            .then((resp) => resp.json())
            .then((data) => {
            params.successCallback(data.data, data.recordsTotal)
            }).catch(error=>{
              console.log(error);
            });
        }else{
          fetch(url,{
            headers: {
              'Content-Type': 'application/json',
              'AccessToken':access_token
            },
          }
          )
            .then((resp) => resp.json())
            .then((data) => {
            params.successCallback(data.data, data.recordsTotal)
            }).catch(error=>{
              console.log(error);
            });
        }
        
      },
  
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(dataSource);
  
  }, []);
  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
    console.log("READYYYY");
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: BtnSAVE,
        },
        // {
        //   statusPanel: ClickableStatusBarComponent,
        // },
      ],
    };
  }, []);
  

  // const [render, rerender] = useState(true);

    return (
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine" >
         <AgGridReact
              ref={gridRef}
              columnDefs={props.col}
              defaultColDef={defaultColDef}
              rowModelType={'serverSide'}
              serverSideStoreType={'partial'}
              pagination={true}
              paginationPageSize={15}
              cacheBlockSize={15}
              animateRows={true}
              onGridReady={onGridReady}
              rowHeight={27}
              rowStyle={rowStyle}
              domLayout = "autoHeight"
              frameworkComponents={props.frameworkComponents}
              onFirstDataRendered={onFirstDataRendered}
              statusBar={statusBar}
            ></AgGridReact>
          </div>
        </div>
    )

})
