import React, { Component, useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, useLastPage, useTitleFunctional } from '../functions/Reusables';

export default function DailyPayment (){
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'Rating Dist.',field: 'rating_dist'},
    { headerName:'Prev Amount',field: 'current_amt',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'Amount Paid',field: 'paid_amt',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'Balance',field: 'balance',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'Trans. ID',field: 'transaction_id'},
    { headerName:'Bank',field: 'bank'},
    { headerName:'Month',field: 'month'},
    { headerName:'Date Paid',field: 'date_paid'},
    { headerName:'Channel',field: 'channel'},
    { headerName:'Percent',field: 'percent'},
  ]

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  useTitleFunctional("Daily Payments | AMAC Tenement")
  useLastPage("dailypayment")
  
    return (
          <div className="page-body">
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Daily Payment</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Daily Payment</a></li>
                    {/* <li className="breadcrumb-item">View Properties</li> */}
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'flex-end'}}>
                  {/* <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div> */}
                   
                  <form className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </form>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <MyDataTable endpoint = "fpay/day_payments" col = {tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime}/> 
            </div>
          </div>
        </div>
    )

}
