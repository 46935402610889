import React, { Component, useState } from 'react'

import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { getUser, useLastPage, useTitleFunctional } from '../functions/Reusables';

export default function ChangePassword (){

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [isLoading, setLoading] = useState(false);
 
  function validatePassword(value) {
    const errors = [];
    console.log(value)
    if (value.length < 8) {
        errors.push("Your password must be at least 8 characters");
    }
    if (value.search(/[a-z]/i) < 0) {
        errors.push("Your password must contain at least one letter."); 
    }
    if (value.search(/[0-9]/) < 0) {
        errors.push("Your password must contain at least one digit.");
    }
    if (errors.length > 0) {
        setErrorMessage([...errors])
        // alert(errors.join("\n").toString());
        return false;
    }
    return true;
  }

 async function changePassword(){
  if(password != confirmPassword){
    setErrorMessage(["Passwords do not match!"])
  }else{
    if(validatePassword(password)){
      setLoading(true)
      const changerequest = {'password':password };
      const headers = {
        "AccessToken": getUser().access_token
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}user/change_password/`,
          changerequest,
          {headers}
        );
        setSuccessMessage("Password Updated Successfully")
        setLoading(false)
      } catch (error) {
        const errors = []
        console.log(error)
        errors.push("Something went wrong")
        
        if(error.response !== null || error.response !== undefined){
          errors.push(error.response.data.message)
        }
        setErrorMessage(errors)
        setLoading(false)
      }
    }
  }
 }

 useTitleFunctional("Change Password | AMAC Tenement")
 useLastPage("changepassword")

 
    return (
          <div className="page-body">
             { isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Change Password</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Change Password</a></li>
                    {/* <li className="breadcrumb-item">All Accounts</li> */}
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>

                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid" style={{marginTop:'20px'}}>
            <div className="row">
            <div className="card">
           
                  <div className="card-header pb-0">
                    {/* <h5>Change Password</h5> */}
                  </div>
                  <div className="form theme-form">
                  {errorMessage.length !== [] ? 
                  errorMessage.map((error, index)=>{
                    return <div className="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{error}</strong>
                    <button class="btn-close" onClick={()=>setErrorMessage([])} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                  </div>
                  }): <div></div>
                  }
                    {successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{successMessage}</strong>
                      <button className="btn-close" onClick={()=>setSuccessMessage("")} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          {/* <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label" >Old Password</label>
                            <div className="col-sm-9">
                              <input className="form-control" type="password" placeholder="Type your Old Password"/>
                            </div>
                          </div> */}
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">New Password</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={password} onChange={(e)=>{
                                setPassword(e.target.value)
                              }} type="text" placeholder="Type your New Password"/>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label className="col-sm-3 col-form-label">Confirm Password</label>
                            <div className="col-sm-9">
                              <input className="form-control" value={confirmPassword} onChange={(e)=>{
                                setConfirmPassword(e.target.value)
                              }} type="text" placeholder="Confirm your new Password"/>
                            </div>
                          </div>
                          
                          
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-end">
                      <div className="col-sm-9 offset-sm-3">
                        <button className="btn btn-primary" onClick={()=>{
                          changePassword()
                        }} type="submit">Submit</button>
                        <input className="btn btn-light" type="reset" value="Cancel"/>
                      </div>
                    </div>
                  </div>
                </div> 
            </div>
          </div>
        </div>
    )

}
