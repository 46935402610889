import axios from 'axios';
import React, { Component } from 'react'
import QRCode from "react-qr-code";
import { getAmountComma, getPrintData, getUser, setPrintData } from '../functions/Reusables';

export class PrintBulkReminderAuto extends Component { 

    constructor(props) {
        super(props);
        this.state = {

        };
      }
     getAmount(value){
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
    }

     getDateTime(){
        const d = new Date();
        return `${d.toJSON().slice(0,10).split('-').reverse().join('/')} ${d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        
    }
     getYear(){
        const d = new Date();
        return `${d.getFullYear()}`;
    }

    async buildPage(){
        // this.updatePrint(getPrintData().batch_id)
        var prtContent = document.getElementById("mycontainerprint");
        var WinPrint = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
        console.log(WinPrint)
        WinPrint.document.write('<html><head>');
        WinPrint.document.write('<link rel="stylesheet" href="assets/css/myprint.css">');
        WinPrint.document.write('</head><body class="" onload="print();close();">');
        // WinPrint.document.write('</head><body class="" onload="">');
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.write('</body></html>');
        WinPrint.document.close();
        WinPrint.focus();
        setPrintData("")
        this.props.changeHeaderNotify(false)
        this.props.closeModal()
        
        // updatePrint((parseInt(props.propResponse.pid.slice(3))).toString())
    }

    async updatePrint(bid){
        const requestPID = {
            batch_id: bid,
        };
        const headers = {
          "AccessToken": getUser().access_token
        }
        try {
          console.log(requestPID)
          const response = await axios.post(
            `${process.env.REACT_APP_API}auto/post_auto/`,
            requestPID,
            {headers}
          );
          
          
        } catch (error) {
          const errors = []
          console.log(error)
          errors.push("Something went wrong")
          
          if(error.response !== null || error.response !== undefined){
            errors.push(error.response.data.message)
          }
          console.log(errors.toString())
        }
      }

    componentDidMount(){
            // this.buildPage()
    }
    
    render() {
    return (

    <div className="mycontainer mycenter" id='mycontainerprint'> 
        <div class="hideMe">
                  <button  class="btn btn-primary" onClick={()=>{
                    this.updatePrint(getPrintData().batch_id)
                    var prtContent = document.getElementById("mycontainerprint");
                    var WinPrint = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
                    console.log(WinPrint)
                    WinPrint.document.write('<html><head>');
                    WinPrint.document.write('<link rel="stylesheet" href="assets/css/myprint.css">');
                    WinPrint.document.write('</head><body class="" onload="print();close();">');
                    // WinPrint.document.write('</head><body class="" onload="">');
                    WinPrint.document.write(prtContent.innerHTML);
                    WinPrint.document.write('</body></html>');
                    WinPrint.document.close();
                    WinPrint.focus();
                    setPrintData("")
                    this.props.changeHeaderNotify(false)
                    this.props.closeModal()
                    }} type="button">Confirm Print</button>
                    <button class="btn-close" type="button" onClick={()=>this.props.closeModal()}  data-bs-dismiss="modal" aria-label="Close"></button>
                    
                  </div>
        {this.props.propResponses.map((propResponse)=>{
            return <div >
            <div className="" style={{height:'40px'}}>
                </div>
            <div className="content">
                <div className="border2">
			<div><b id='warningNote'>NOTE: IT IS ILLEGAL TO PAY CASH TO ANYONE.  DEFAULTER WILL BE LIABLE</b></div>

                    <div className="notice-header flex space-btw">
                        <div>
                            Notice is hereby given to -
                            <span className="pid">{propResponse.occupant} - PID ({propResponse.pid})</span>
                        </div>
                        <div>
                            <span className="year right">
                                <b>YEAR {propResponse.year}</b>
                            </span>
                        </div>
                    </div>
                    <div className="border-top2">
                        <div className="pad7 text">
                            In respect of the property below
                        </div>
                        <div className="flex">
                            <div className="col-8">
                                <div className="props flex">
                                    <div className="fields">Property Address:- </div>
                                    <div className="values">
                                        {propResponse.prop_addr}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Name of Occupier:-</div>
                                    <div className="values">
                                        {propResponse.occupant}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Assessment Number:- </div>
                                    <div className="values">
                                        {propResponse.asset_no}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Cadastral Zone:- </div>
                                    <div className="values">
                                        {propResponse.cadastral_zone}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Use of Property:- </div>
                                    <div className="values">
                                        {propResponse.prop_use}
                                    </div>
                                </div>
                                <div className="props flex">
                                    <div className="fields">Rating District:- </div>
                                    <div className="values">
                                        {propResponse.rating_dist}
                                    </div>
                                </div>
                            </div>
                            <div>
            
                                <div id="code"><QRCode size={96} value={`${process.env.REACT_APP_API}upay/make_payment/?_pid=${(parseInt(propResponse.pid.slice(3))).toString()}&_amt=${propResponse.grand_total}`} /></div>
                                <div>
                                    {/* <p className="code">TR/z2yst1zqsul07xh1dg/11/343300</p> */}
                                    <p className="date">{this.getDateTime()}</p>
                                    <p className="date">{Date.now()}</p>
                                </div>
                            </div>
                        </div>
            
                    </div>
                    <div className="border-top2 flex">
                        <div className="top-border pad-left-10 flex">

                            <div>
                                <div className='reminderText' style={{padding:"0 10px"}}>
                                    <p>Sir/Madam,</p>
                                    <p>1. Further to the tenement Rate Demand Notice served on the property(ies) you are
                                        occupying, you are hereby reminded of your outstanding tenement rate liability to the
                                        tune of <b>₦{getAmountComma(propResponse.grand_total)}</b></p>
                                    <p>2. By a copy of this notice, you are requested to effect payment of the above amount into
                                        AMAC Tenement Rate Collections Account at any branch of Union Bank or
                                        through any of the other payment options listed at the back of this Reminder Notice.
                                        Receipt will therefore be issued to you on presentation of your proof of payment at the
                                        Tenement Rate/Valuation Annex Office: Plot 1194 Aminu Kano Crescent (No: 3, Niafounke
                                        Close, behind Access Bank Plc, Wuse II, Abuja, FCT).</p>
                                    <p>3. This notice is served pursuant to section 7 (4th schedule) of the 1999 constitution of
                                        the Federal Republic of Nigeria; FCT Act Cap, 503, LFN 2004 (Vol. 3) as amended; Taxess
                                        and levies Act No. 21 of 1998 and AMAC Tenement Rate Bye Laws No. 99 of 2016.</p>
                                    <p>4. Please take Notice that the Honourable Charman has been informed of your failure to
                                        settle your bill and has acccordingly directed this office to take neccessary action
                                        against you; should you fail to effect payment within seven(7) working days from nthe
                                        date of this notice.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-top2 law">
                        In accordance with the provision of the section 7 (4th schedule) of the 1999
                        constitution of the Federal Republic of Nigeria; Federal Capital Territory Act Cap
                        503,LFN,2004(Vol.3) as ammended Taxes and Levies Act No. 21 of 1998 and A.M.A.C Tenement Rate
                        bye-Laws 2014, we forward herewith your bill for the year {propResponse.year} totalling ₦
                        {getAmountComma(propResponse.grand_total)} in respect of the landed property(ies) you are occupying in Abuja as per details
                        above.
                    </div>
                </div>
            </div>
            <div className="border2 margin5 note">
                NOTE: Please turn overleaf for a list of payment options through which payment can be made and ensure your
                electronic receipt is collected from the bank.
            </div>
            <div className="margin10">
                <div className="flex" style={{justifyContent:'space-between'}}>
                    <div id='leftContainer'>
                        <div id='earlyCompliance'  className="pad-left-10">
                            <p>Your early compliance will be highly appreciated.</p>
                            <p className="yours">Yours faithfully,</p>
                            <div>
                            </div>
                       

                            <div className="authority flex  ">
                                <div id='headOne'>
                                    <img className='signatures' src="../assets/images/sign1.png"/>
                                    <div className="head-tenrement">HEAD OF TENEMENT RATE</div>
                                    <div className="chairman"> For Honourable Chairman</div>
                                    <div className="council">Abuja Municipal Area Council</div>
                                </div>
                                <div >
                                <img className='signatures' src="../assets/images/sign2.png" />
                                    <div className="head-tenrement">DIRECTOR OF OPERATIONS</div>
                                    <div className="chairman"> For Honourable Chairman</div>
                                    <div className="council">Abuja Municipal Area Council</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="" id='rightContainer' >
                        <div className="c-table">
                            <div className="c-table-head">
                                Acknowledgement 
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-22">
                                    Name
                                </div>
                                <div className="c-table-content wide"> 
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-22">
                                    Date
                                </div>
                                <div className="c-table-content wide"> 
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-22">
                                    Signature
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                        </div>
                        <div className="c-table" >
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-70">
                                    Date of dispatch
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-70">
                                    Name of Officer
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                            <div className="c-table-body flex">
                                <div className="c-table-content c-table-margin wide-70">
                                    Mode of dispatch
                                </div>
                                <div className="c-table-content wide">
            
                                </div>
                            </div>
                        </div>	                      	
                    </div>
                </div>
            </div>
            <div id='printFoot'>All payment To <b>AMAC Tenement Rate</b> Account No. <b>0057326758, UNION BANK.</b></div>	
            <p className='pageBreak' ></p>
        </div>
        })}
        
        
    
    </div>



    )
}

}
