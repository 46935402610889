import React, { Component,useState,useEffect } from 'react'
import FeatherIcon from 'feather-icons-react';
import { ShimmerThumbnail,ShimmerTable } from "react-shimmer-effects";
import { getAmountComma, getUser, saveLastPage, titleClass } from '../functions/Reusables';
import axios from "axios";




export default class  FinanceDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoadingCards: true,
      isLoadingTable1: true,
      cardValues: {},
      table1Values: [],
    };
  }

    getCardData = async () => {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}fdash/get_dashboard1/`,
        {headers})
        const dataList = response.data.data;
        const newMap = {}
        for (let index = 0; index < dataList.length; index++) {
          let key = Object.keys(dataList[index])[0]
          let value = Object.values(dataList[index])[0]
          newMap[key] = value
        }
        this.setState({
          cardValues: newMap,
          isLoadingCards:false
        });
    } catch (error) {
      console.log(error)
    }
  }
   getTableData2 = async () => {
    const accessToken = getUser().access_token
    const headers ={
      'AccessToken':accessToken
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}fdash/get_dashboard2/`,
        {headers})
        
        const dataList = response.data.data;
        this.setState((state, props) => ({
          table1Values: dataList,
          isLoadingTable1:false
        }));

        console.log(this.state.table2Values)
  
    } catch (error) {
      console.log(error)
    }
  }

   

  componentDidMount() {
    titleClass("Dashboard | AMAC Tenement")
    saveLastPage("dashboard")
    this.getCardData()
    this.getTableData2()
  }


  render() {
    return (
      <div>
        <div className="page-body">
          <div className="container-fluid dashboard-default-sec">
          <div class="page-header">
              <div class="row">
                <div class="col-sm-6">
                  <h3>Dashboard</h3>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                  </ol>
                </div>
                
              </div>
            </div>
            <div className="row" style={{marginTop:'20px'}}>
              <div className="col-xl-5 box-col-12 des-xl-100"> 
                <div className="row">
                  <div className="col-xl-12 col-md-6 box-col-6 des-xl-50">
                  {this.state.isLoadingCards ? <div className='card'><ShimmerThumbnail height={220} rounded className='myShimmerOveride' /></div> :
                    <div className="card profile-greeting">
                      <div className="card-header">
                        <div className="header-top">
                          <div className="text-center" style={{color:'white'}}>
                          <FeatherIcon icon="trending-up"  size={70}></FeatherIcon>
                          </div>
                        </div>
                      </div>
                      <div className="card-body text-center p-t-0">
                        <p>COLLECTED</p>
                        
                        <h3 className="font-light">{`₦ ${getAmountComma(this.state.cardValues.total)}`}</h3>
                    
                      </div>
                      <div className="confetti">
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="confetti-piece"></div>
                        <div className="code-box-copy">
                          <button className="code-box-copy__btn btn-clipboard" data-clipboard-target="#profile-greeting" title="Copy"><i className="icofont icofont-copy-alt"></i></button>
                      
                        </div>
                      </div>
                    </div> }
                  </div>
                  <div className="col-xl-12 col-md-6 box-col-6 des-xl-50">
                  {this.state.isLoadingCards ? <div className='card'><ShimmerThumbnail height={220} rounded className='myShimmerOveride' /></div> :<div className="card profile-greeting" style={{backgroundColor: "rgb(117, 181, 236)",backgroundImage: 'none'}}>
                      <div className="card-header">
                        <div className="header-top">
                          <div className="text-center" style={{color:'white'}}>
                          <FeatherIcon icon="target"  size={70}></FeatherIcon>
                          </div>
                        </div>
                      </div>
                      <div className="card-body text-center p-t-0">
                        <p>FINANCIAL TARGET</p>
                        <h3 className="font-light" style={{color:"black !important"}}>₦ 4,463,210,134.50</h3>
                      </div>
                      
                    </div>}
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 box-col-12 des-xl-100 dashboard-sec">
                <div className="row">
                  
                  
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {this.state.isLoadingCards ?<div className='card' ><ShimmerThumbnail height={80} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={100} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                 
                      <div className="card-body text-center" style={{paddingTop:'58px', paddingBottom:'58px'}}>                                  
                        <div className="">
                        <FeatherIcon icon="home" fill='white' size={40}></FeatherIcon>
                        </div>
                        
                        <h5>{`₦${getAmountComma(this.state.cardValues.today_total)}`}</h5>
                        <p>TODAY TOTAL</p>
                        
                      </div>
                    </div>}
                  </div>
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {this.state.isLoadingCards ? <div className='card' ><ShimmerThumbnail height={80} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={100} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                   
                      <div className="card-body text-center" style={{paddingTop:'58px', paddingBottom:'58px'}}>
                        <div className="">
                        <FeatherIcon icon="home" fill='white' size={40}></FeatherIcon>
                        </div>
                        <h5>{`₦${getAmountComma(this.state.cardValues.week_total)}`}</h5>
                        <p>WEEK TOTAL</p>
                        
                      </div>
                    </div>}
                  </div>
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {this.state.isLoadingCards ? <div className='card' ><ShimmerThumbnail height={80} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={100} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                   
                      <div className="card-body text-center" style={{paddingTop:'58px', paddingBottom:'58px'}}>
                        <div className="">
                        <FeatherIcon icon="home" fill='white' size={40}></FeatherIcon>
                        </div>
                        <h5>{`₦${getAmountComma(this.state.cardValues.month_total)}`}</h5>
                        <p>MONTH TOTAL</p>
                        
                      </div>
                    </div>}
                  </div>  
                  <div className="col-sm-6 col-xl-6 col-lg-6">
                  {this.state.isLoadingCards ? <div className='card' ><ShimmerThumbnail height={80} rounded className='myShimmerOveride' /><div style={{padding:'10px', margin:'0 auto'}}><ShimmerThumbnail width={70} height={100} rounded className='myShimmerOveride' /></div></div>  :<div className="card income-card card-primary">                                   
                      <div className="card-body text-center" style={{paddingTop:'58px', paddingBottom:'58px'}}>                                  
                        <div className="">
                        <FeatherIcon icon="users" fill='white' size={40}></FeatherIcon>
                        </div>
                        <h5>{`₦${getAmountComma(this.state.cardValues.total)}`}</h5>
                        <p>TOTAL</p>
                        
                      </div>
                    </div>}
                  </div>
                  <div className="col-sm-6 col-xl-4 col-lg-6">
                  
                  </div>  
                  <div class="col-sm-6 col-xl-6 col-lg-6">
                  
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-7 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div class="card-header pb-0">
                        <h5>Bills Dispatched</h5>
                      </div>    
                      <div class="card-body pt-10">
                      <div class="table-responsive">
                        {this.state.isLoadingTable2 ? <ShimmerTable row={4} col={5} /> :<table class="table">
                          <thead>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">Recieved By</th>
                              <th scope="col">Date Delivered</th>
                              <th scope="col">Mode</th>
                    
                            </tr>
                          </thead>
                          <tbody>
                            
                              {this.state.table2Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.received_by}</td>
                                  <td>{items.date_delivered}</td>
                                  <td>{items.mode}</td>

                                </tr>
                              )}
                       
                          </tbody>
                        </table>}
                      </div>
                      </div>
           
                      
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-5 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div class="card-header pb-0">
                        <h5>21 Day Reminder Notice</h5>
                      </div>    
                      <div class="card-body pt-10">
                      <div class="table-responsive">
                        {this.state.isLoadingTable1 ? <ShimmerTable row={4} col={4} /> :<table class="table">
                          <thead>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">District</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Days Elapsed</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.table1Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.rating_dist}</td>
                                  <td>{items.grand_total}</td>
                                  <td>{items.elapsed_days}</td>

                                </tr>
                              )}
                            
                          </tbody>
                        </table>}
                      </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-13 box-col-12 des-xl-100">
                <div className="row">
                  <div className="col-xl-12 recent-order-sec">
                    <div className="card">
                      <div class="card-header pb-0">
                        <h5>Recent Payments</h5>
                      </div>    
                      <div class="card-body pt-10">
                      <div class="table-responsive">
                        {this.state.isLoadingTable1 ? <ShimmerTable row={4} col={9} /> :<table class="table">
                          <thead style={{backgroundColor:"#efefef"}}>
                            <tr>
                              <th scope="col">PID</th>
                              <th scope="col">Rating. Dst.</th>
                              <th scope="col">Month</th>
                              <th scope="col">Curr. Amount</th>
                              <th scope="col">Amount Paid</th>
                              <th scope="col">Balance</th>
                              <th scope="col">Date</th>
                              <th scope="col">Bank</th>
                              <th scope="col">Channel</th>
                              <th scope="col">Percent</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.table1Values.map((items)=>
                                <tr>
                                  <th scope="row">{items.pid}</th>
                                  <td>{items.rating_dist}</td>
                                  <td>{items.month}</td>
                                  <td>{items.current_amt}</td>
                                  <td>{items.paid_amt}</td>
                                  <td>{items.balance}</td>
                                  <td>{items.date_paid}</td>
                                  <td>{items.bank}</td>
                                  <td>{items.channel}</td>
                                  <td>{items.percent}</td>

                                </tr>
                              )}
                            
                          </tbody>
                        </table>}
                      </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 footer-copyright">
                <p className="mb-0">Copyright 2022 © AMAC All rights reserved.</p>
              </div>
              
            </div>
          </div>
        </footer>

    </div>
    )
  }
}
