import React, { Component, useState } from 'react'
import {useLocation} from 'react-router-dom';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MyBasicDataTable from '../components/MyBasicDataTable';
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, useLastPage, useTitleFunctional } from '../functions/Reusables';

export default function PropertyFailed (props){
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'Occupant',field: 'occupant'},
    { headerName:'Address',field: 'prop_addr'},
    { headerName:'Street',field: 'street_name'},
  ]

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");

  const location = useLocation()

  useTitleFunctional("Failed Property Update | AMAC Tenement")
  useLastPage("updateproperties/failedupdates")
  
    return (
          <div className="page-body">
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Failed Updates Property PIDS</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Properties</a></li>
                    <li className="breadcrumb-item">Update Property</li>
                    <li className="breadcrumb-item">Failed Updates Property PIDS</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>
                  
                   
                  
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <MyBasicDataTable col = {tableColumns} data = {location.state.pids}/> 
            </div>
          </div>
        </div>
    )

}
