import React, { Component, useState } from 'react'
import FeatherIcon from 'feather-icons-react';

import "react-datepicker/dist/react-datepicker.css";
import MyDataTable from '../components/MyDataTable';
import { getAmountComma, getUser, saveSourcePage, setConsData, setIsPrinting, useLastPage, useTitleFunctional } from '../functions/Reusables';
import axios from 'axios';
import MyBasicDataTable from '../components/MyBasicDataTable';
import PrintConsolidated from './printConsolidated';

export default function Consolidated (){
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'OCCUPANT',field: 'occupant'},
    { headerName:'ADDRESS',field: 'prop_addr'},
    { headerName:'ASSET NO.',field: 'asset_no'},
    { headerName:'ZONE.',field: 'cadastral_zone'},
    { headerName:'PROP TYPE.',field: 'prop_type'},
    { headerName:'PROP USE',field: 'prop_use'},
    { headerName:'RATING DIST',field: 'rating_dist'},
    // { headerName:'ANNUAL VAL',field: 'annual_value',valueFormatter: params => getAmountComma(params.value)},
    // { headerName:'RATE PAY',field: 'rate_payable',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'CONS. ID',field: 'consolidate_id'},
  ]

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");
  const [errorMessageUpload, setErrorMessageUpload] = useState("");
  const [successMessageUpload, setSuccessMessageUpload] = useState("");
  const [labelValue, setLabelValue] = useState("Enter CON ID");
  const [formoption, setFormOption] = useState("con_id");
  const [formvalue, setFormValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isViewingPrint, setIsViewingPrint] = useState(false);
  const [propResponse, setPropResponse] = useState([]);
  const [propResponse2, setPropResponse2] = useState({});

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }



  async function searchCons(){
    // setIsLoading(true)
    let formData = {
        "con_option": formoption,
        "con_value": formvalue
    } 
    console.log(formData)
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        // "Content-Type": "multipart/form-data" 
      }
      
      let url = `${process.env.REACT_APP_API}cons/cons_table/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data);
      setPropResponse(response.data.data)
      // setIsLoading(false)
      setHasSearched(true)
      
      // handleClear()
      // console.log(response.data)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setIsLoading(false)
        setErrorMessageUpload(error.response.data.message)
        return
      }
      setIsLoading(false)
      setErrorMessageUpload("Something went wrong")
    }
  }
  async function printCons(){
    setIsLoading(true)
    let formData = {
        "con_option": formoption,
        "con_value": formvalue
    } 
    console.log(formData)
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        // "Content-Type": "multipart/form-data" 
      }
      
      let url = `${process.env.REACT_APP_API}cons/bulk_cons_print/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      // console.log(response.data);
      // setPropResponse2(response.data)
      // setIsViewingPrint(true)
      setConsData(response.data)
      localStorage.setItem('lastpage', 'consprint')
      saveSourcePage('print&generatebill')
      setIsPrinting(true)
      window.open('/consprint')
      setIsLoading(false)
      
      // handleClear()
      // console.log(response.data)
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        setIsLoading(false)
        setErrorMessageUpload(error.response.data.message)
        return
      }
      setIsLoading(false)
      setErrorMessageUpload("Something went wrong")
    }
  }

  function closeModal(){
    setIsViewingPrint(false)
  }
  
  useTitleFunctional("Consolidated | AMAC Tenement")
  useLastPage("print&generatebill")
  
    return (
          <div className="page-body">
            {isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          { isViewingPrint ?<div class="modal fade show" id="exampleModalLong" style={{display:'block', backgroundColor:'#0000004a', overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'1000px'}}>
                  
                <div className="modal-content" id='printModal'>
                  <PrintConsolidated propResponse={propResponse2} closeModal={closeModal} />
                </div>
              </div>
            </div>: <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col" >
                  <h3>Consolidated</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Consolidation</a></li>
                    <li className="breadcrumb-item">Consolidated</li>
                  </ol>
                </div>
                <div className="col" style={{display:'flex', justifyContent:'end'}}>
                  {/* <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div> */}
                   
                  <div className=" search-form row"  style={{marginLeft:'10px'}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold'}}>Filter by: </p>
                    <div className="form-group form-control-search">
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(e.target.value.length > 2){
                          setRand(e.target.value)
                          console.log("changed")
                        }
                        else if(e.target.value.length == 0){
                          setValue("")
                          setRand(new Date().getMilliseconds().toString())
                        }
                        }}/>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
            <div className="col-sm-4">
                <div className='card'>
                  
                  <div className='card-body'>
                  {errorMessageUpload !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{errorMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>setErrorMessageUpload("")} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {successMessageUpload !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{successMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>setSuccessMessageUpload("")} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <p style={{fontWeight:'bold'}}> <span style={{marginRight:'20px'}}><FeatherIcon icon='printer' size={20}></FeatherIcon></span>Print Consolidated</p>
                    
                    
                    
                    <div className='d-flex'>
                      <div className="col-md-12 mb-10">
                        <label className="control-label">Group Name</label>
                        <select class="form-select digits" value={formoption} id="exampleFormControlSelect9" onChange={(e)=>{
                            setFormOption(e.target.value)
                            if(e.target.value == "group_name"){
                              setLabelValue("Enter Group Name")
                            }else if(e.target.value == "pids"){
                              setLabelValue("Enter PIDs (Seperated by comma)")
                            }else{
                              setLabelValue("Enter CON ID")
                            }
                          }}>   
                            <option value="con_id">{"CONSOLIDATION ID"}</option>
                            <option value="group_name" >{"GROUP NAME"}</option>
                            <option value="pids" >{"PROPERTY ID"}</option>
                        </select>
                      </div>
                    </div>
                    
                    <div className='d-flex'>
                      <div className="col-md-12 mb-10">
                        <label className="control-label">{labelValue}</label>
                        <input className="form-control"  type="text" value={formvalue} placeholder={labelValue} required="required" 
                        onChange={(e)=>{
                          setFormValue(e.target.value)
                        }}   
                        />
                      </div>
                    </div>
                    <div className='d-flex' style={{justifyContent:"space-between"}}>
                     
                      <button className="btn btn-secondary pull-left"   onClick={()=>{
                        searchCons()
                      }}  type="button">Search</button>
                      <button className="btn btn-primary pull-right" disabled={!hasSearched}  onClick={()=>{
                        printCons()
                      }}  type="button">Print</button>
                    </div>
                    
                  </div>
                  
                </div>
              </div>
              <div className='col-md-8'>
                {
                  hasSearched ? <MyBasicDataTable  col = {tableColumns} data = {propResponse}/> : <MyDataTable endpoint = "cons/cons" col = {tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime}/> 
                }
              {/* <MyDataTable endpoint = "cons/cons" col = {tableColumns} search = {searchTerm} startDate={startDateReq} endDate={endDateReq} key= {randTime}/>  */}
              </div>
            </div>
          </div>
        </div>
    )

}
