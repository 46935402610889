import React, { Component, useState } from 'react'
import FeatherIcon from 'feather-icons-react';
import BarLoader from "react-spinners/BarLoader";


export default class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler(e) {
   this.props.clicked(this.props.value,e.currentTarget.id);
  }
  render() {
    return (
      <div>
        <button className='btn-secondary' id='view'  onClick={this.btnClickedHandler}><FeatherIcon  icon="eye"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
        <button className='btn-primary' id='edit'  onClick={this.btnClickedHandler}><FeatherIcon  icon="edit"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
      </div>

    )
  }
}
export class BtnCellRendererViewEditDelete extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler(e) {
   this.props.clicked(this.props.value,e.currentTarget.id);
  }
  render() {
    return (
      <div>
        <button className='btn-secondary' id='view'  onClick={this.btnClickedHandler}><FeatherIcon  icon="eye"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
        <button className='btn-primary' id='edit'  onClick={this.btnClickedHandler}><FeatherIcon  icon="edit"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
        <button className='btn-danger' id='delete'  onClick={this.btnClickedHandler}><FeatherIcon  icon="trash"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
      </div>

    )
  }
}
export  class BtnCellRendererViewPrint extends Component {
  constructor(props) { 
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler(e) {
   this.props.clicked(this.props.value,e.currentTarget.id);
  }
  render() {
    return (
      <div>
        <button className='btn-secondary' id='view'  onClick={this.btnClickedHandler}><FeatherIcon  icon="eye"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
        <button className='btn-primary' id='print'  onClick={this.btnClickedHandler}><FeatherIcon  icon="printer"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
      </div>

    )
  }
}
export  class BtnCellRendererViewCreate extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler(e) {
   this.props.clicked(this.props.value,e.currentTarget.id);
  }
  render() {
    return (
      <div>
        <button className='btn-secondary' id='create'  onClick={this.btnClickedHandler}><FeatherIcon  icon="edit"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
        <button className='btn-primary' id='view'  onClick={this.btnClickedHandler}><FeatherIcon  icon="eye"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
      </div>

    )
  }
}
export class BtnCellRendererViewOnly extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler(e) {
   this.props.clicked(this.props.value,e.currentTarget.id);
  }
  render() {
    return (
      <div>
        <button className='btn-secondary' id='view'  onClick={this.btnClickedHandler}><FeatherIcon  icon="eye"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
      </div>

    )
  }
}
export class BtnDiscountCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler(e) {
   this.props.clicked(this.props.value,e.currentTarget.id);
  }
  render() {
    return (
      <div>
        <button className='btn-secondary' id='discount'  onClick={this.btnClickedHandler}><FeatherIcon  icon="percent"  size={15}></FeatherIcon></button>
        <span style={{marginLeft:'5px'}}></span>
      </div>

    )
  }
}


export function BtnDefaultSAVE (props) {

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);

  async function btnClickedHandler(e) {
    setIsLoadingExcel(true)
    var model =  await props.api.getModel()
    // console.log(model.gridApi.exportDataAsCsv)
    
    try {
      await model.gridApi.exportDataAsExcel()
      setIsLoadingExcel(false)
    } catch (error) {
      setIsLoadingExcel(false)
    }
    
  }
  async function btnClickedHandlerCSV(e) {
    setIsLoadingCSV(true)
    var model =  await props.api.getModel()
   try {
    await model.gridApi.exportDataAsCsv()
    setIsLoadingCSV(false)
   } catch (error) {
    setIsLoadingCSV(false)
   }
  }

    return (
      <div style={{display:'flex'}}>
        <button className='btn-primary' id='discount' style={{border:'none', padding:'5px', margin:'3px'}}  onClick={btnClickedHandler}><FeatherIcon  icon="save"  size={15}></FeatherIcon>
          <span style={{margin:'4px',}}>Export Excel </span> 
          {isLoadingExcel ?<BarLoader color={'#ffffff'} loading={isLoadingExcel} cssOverride={{}} size={20} />:<div></div>}
        </button>
        
        <span style={{marginLeft:'5px'}}></span>

        <button className='btn-primary' id='discount' style={{border:'none', padding:'5px', margin:'3px'}}  onClick={btnClickedHandlerCSV}><FeatherIcon  icon="save"  size={15}></FeatherIcon>
          <span style={{margin:'4px'}}>Export CSV</span> 
          {isLoadingCSV ?<BarLoader color={'#ffffff'} loading={isLoadingCSV} cssOverride={{}} size={20} /> : <div></div>}
        </button>
      </div>

    )

}
export function BtnSAVE (props) {

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);

  async function btnClickedHandler(e) {
    setIsLoadingExcel(true)
    var model =  await props.api.getModel()
    try {
      await model.datasource.getExportExcel()
      setIsLoadingExcel(false)
    } catch (error) {
      setIsLoadingExcel(false)
    }
    
  }
  async function btnClickedHandlerCSV(e) {
    setIsLoadingCSV(true)
    var model =  await props.api.getModel()
   try {
    await model.datasource.getExportCSV()
    setIsLoadingCSV(false)
   } catch (error) {
    setIsLoadingCSV(false)
   }
  }

    return (
      <div style={{display:'flex'}}>
        <button className='btn-primary' id='discount' style={{border:'none', padding:'5px', margin:'3px'}}  onClick={btnClickedHandler}><FeatherIcon  icon="save"  size={15}></FeatherIcon>
          <span style={{margin:'4px',}}>Export Excel </span> 
          {isLoadingExcel ?<BarLoader color={'#ffffff'} loading={isLoadingExcel} cssOverride={{}} size={20} />:<div></div>}
        </button>
        
        <span style={{marginLeft:'5px'}}></span>

        <button className='btn-primary' id='discount' style={{border:'none', padding:'5px', margin:'3px'}}  onClick={btnClickedHandlerCSV}><FeatherIcon  icon="save"  size={15}></FeatherIcon>
          <span style={{margin:'4px'}}>Export CSV</span> 
          {isLoadingCSV ?<BarLoader color={'#ffffff'} loading={isLoadingCSV} cssOverride={{}} size={20} /> : <div></div>}
        </button>
      </div>

    )

}