import { useState, useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { BrowserRouter as Router, Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import AllProperties from "./pages/AllProperties";
import Audit from "./pages/Audit";
import Dashboard from "./pages/Dashboard";
import axios from "axios";
import Login from "./pages/Login";
import {getIsprinting, getLastPage, getUser, logOut} from "./functions/Reusables";
import CreateProperty from "./pages/CreateProperties";
import UpdateProperty from "./pages/UpdateProperties";
import GenerateBills from "./pages/GenerateBills";
import EditArrears from "./pages/EditArrears";
import Consolidated from "./pages/Consolidated";
import Unconsolidated from "./pages/Unconsolidated";
import Reminders7 from "./pages/7DaysRemiders";
import Reminders21 from "./pages/21DaysRemiders";
import ReminderNotice from "./pages/ReminderNotice";
import DueForProsecution from "./pages/DueForProsecution";
import Prosecuted from "./pages/Prosecuted";
import ViewDispatch from "./pages/ViewDispatch";
import AllAccounts from "./pages/AllAccounts";
import Management from "./pages/Management";
import ZonalManagers from "./pages/ZonalManagers";
import RevenueOfficers from "./pages/RevenueOfficers";
import Supervisors from "./pages/Supervisors";
import ProcessingOfficers from "./pages/ProcessingOfficers";
import ChangePassword from "./pages/ChangePassword";
import Discounts from "./pages/Discount";
import CreateAccount from "./pages/CreateAccount";
import PaymentReport from "./pages/PaymentReport";
import Print from "./pages/print";
import PropertyFailed from "./pages/PropertyUpdateFailed";
import FinanceDashboard from "./pages/FinanceDashboard";
import DailyPayment from "./pages/DailyPayment";
import WeeklyPayment from "./pages/WeeklyPayment";
import MonthlyPayment from "./pages/MonthlyPayment";
import PaymentHistory from "./pages/PaymentHistory";
import LegalDashboard from "./pages/LegalDashboard";
import CreateProsecuted from "./pages/CreateProsecuted";
import CreateDispatch from "./pages/CreateDispatch";
import ViewProsecutedDetails from "./pages/ViewProsecutedDetails";
import MakePayment from "./pages/MakePayment";
import EditProperty from "./pages/EditProperty";
import ProcessingDashboard from "./pages/ProcessingDashboard";
import ZonalDashboard from "./pages/ZonalDashboard";
import DemandDistrict from "./pages/DemandDistrict";
import ReminderDistrict from "./pages/ReminderDistrict";
import UploadAddress from "./pages/UploadAddress";
import BulkPrint from "./pages/BulkPrint";
import PrintLoader from "./components/PrintLoader";
import ConsolidatePrint from "./pages/ConsolidatePrint";
import BulkReminderPrint from "./pages/BulkReminderPrint";


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggedInUser, setUser] = useState({});
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [shouldNotify, setShouldNotify] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isPrinting, setPrinting] = useState(false);

  const closeErroMessageBox = ()=>{
    setErrorMessage('')
  }

  function changeHeaderNotify(value){
    setShouldNotify(value)
  }

  const setAuth = async (username, password) => {
    const user = { 'user_email':username, 'password':password };
    // console.log(user);
    setIsAuthenticating(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}user/login/`,
        user
      );
      localStorage.setItem('user', JSON.stringify(response.data))
      // console.log(response.data)
      setIsAuthenticated(true);
      setUser(response.data)
      setIsAuthenticating(false)
    } catch (error) {
      if(error.response){
        // console.log(error.response)
       setErrorMessage(error.response.data.message)
      }
      setIsAuthenticating(false)
    }
  };

  
  useEffect(() => {
    // console.log(getUser())
    if (getUser() != null) {
      setIsAuthenticated(true);
      setUser(getUser())
      console.log(getIsprinting())
      setPrinting(getIsprinting()) 
      // handleLogout()
      // console.log(getUser())
    } 
  }, []);

  function handleLogout(){
    setIsAuthenticated(false);
    logOut()
  }


  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    handleLogout()
  }

  const onActive = (event) => { 
    // console.log("ACTIVE")
    // Close Modal Prompt
    // Do some active action
  }

  useIdleTimer({ onIdle, onActive ,timeout: 1000 * 60 * 15,}) 


  return (
      <Router >
          { !isAuthenticated ?  
          <Routes>
            {/* <Route path="/" element={  <Login authenticate={setAuth} isLoading={isAuthenticating} errorMessage={errorMessage} successMessage={successMessage} closeErroMessageBox={closeErroMessageBox}/>} />  */}
            <Route path="/" element={ isAuthenticated ? <Navigate to="/" replace /> : <Login authenticate={setAuth} isLoading={isAuthenticating} errorMessage={errorMessage} successMessage={successMessage} closeErroMessageBox={closeErroMessageBox}/>} /> 
            <Route path="/*" element={<Navigate to="/" replace />} />      
          </Routes> :
          <div className="page-wrapper compact-wrapper" id="pageWrapper">
            {/* <PrintLoader/> */}
            <Header logOut = {handleLogout} shouldNotify={shouldNotify} changeHeaderNotify={changeHeaderNotify}/>
            <div className="page-body-wrapper sidebar-icon">
              <Sidebar user={loggedInUser}/>
            {/* ADMIN OR IT */}
            {loggedInUser.role == "11" || loggedInUser.role == "12" ?<Routes >
            <Route path="/*" element={<Navigate to="/dashboard" replace />} /> 
              <Route path="/" element={ isAuthenticated ?<Navigate to={  getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" :  getLastPage() == "bulkprint" ? "/bulkprint": `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<Dashboard changeHeaderNotify={changeHeaderNotify} />} />
              <Route path="/viewproperties" element={<AllProperties/>} />
              <Route path="/createproperties" element={<CreateProperty/>} />
              <Route path="/editproperty"  element={<EditProperty/>} />
              <Route path="/updateproperties" element={<UpdateProperty/>} />
              <Route path="/uploadaddress" element={<UploadAddress/>} />
              <Route path="/updateproperties/failedupdates" element={<PropertyFailed/>} />
              <Route path="/generatebills" element={<GenerateBills/>} />
              <Route path="/editarrears" element={<EditArrears/>} />
              <Route path="/bulkprint" element={<BulkPrint/>} />
              <Route path="/consolidate" element={<Unconsolidated/>} />
              <Route path="/print&generatebill" element={<Consolidated/>} />
              <Route path="/consprint" element={<ConsolidatePrint/>} />
              <Route path="/7daysreminder" element={<Reminders7/>} />
              <Route path="/21daysreminder" element={<Reminders21/>} />
              <Route path="/remindernotice" element={<ReminderNotice/>} />
              <Route path="/remindersprint" element={<BulkReminderPrint/>} />
              <Route path="/dueforprosecution" element={<DueForProsecution/>} />
              <Route path="/prosecuted" element={<Prosecuted/>} />
              <Route path="/discounts" element={<Discounts/>} />
              <Route path="/viewdispatch" element={<ViewDispatch/>} />
              <Route path="/allaccounts" element={<AllAccounts/>} />
              <Route path="/createaccount" element={<CreateAccount/>} />
              <Route path="/managementaccounts" element={<Management/>} />
              <Route path="/zonalmanagers" element={<ZonalManagers/>} />
              <Route path="/revenueofficers" element={<RevenueOfficers/>} />
              <Route path="/processingofficers" element={<ProcessingOfficers/>} />
              <Route path="/paymentreport" element={<PaymentReport/>} />
              <Route path="/audit" element={<Audit/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
              <Route path="/print" element={<Print/>} />
            </Routes>: <Routes></Routes>}
            {/* FINANCE */}
            {loggedInUser.role == "6" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<FinanceDashboard/>} />
              <Route path="/dailypayment" element={<DailyPayment/>} />
              <Route path="/weeklypayment" element={<WeeklyPayment/>} />
              <Route path="/monthlypayment" element={<MonthlyPayment/>} />
              <Route path="/paymenthistory" element={<PaymentHistory/>} />
              <Route path="/makepayment" element={<MakePayment/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
              
            </Routes>: <Routes></Routes>}
            {/* LEGAL */}
            {loggedInUser.role == "7" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<LegalDashboard/>} />
              <Route path="/dueforprosecution" element={<DueForProsecution/>} />
              <Route path="/prosecuted" element={<Prosecuted/>} />
              <Route path="/createprosecuted" element={<CreateProsecuted/>} />
              <Route path="/viewprosecuteddetails" element={<ViewProsecutedDetails/>} />
              <Route path="/paymenthistory" element={<PaymentHistory/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            {/* DIRECTOR or DEPUTY*/}
            {loggedInUser.role == "9" || loggedInUser.role == "10" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/viewproperties" element={<AllProperties/>} />
              <Route path="/viewdispatch" element={<ViewDispatch/>} />
              <Route path="/7daysreminder" element={<Reminders7/>} />
              <Route path="/21daysreminder" element={<Reminders21/>} />
              <Route path="/remindernotice" element={<ReminderNotice/>} />
              <Route path="/remindersprint" element={<BulkReminderPrint/>} />
              <Route path="/dueforprosecution" element={<DueForProsecution/>} />
              <Route path="/prosecuted" element={<Prosecuted/>} />
              <Route path="/zonalmanagers" element={<ZonalManagers/>} />
              <Route path="/revenueofficers" element={<RevenueOfficers/>} />
              <Route path="/processingofficers" element={<ProcessingOfficers/>} />
              <Route path="/paymentreport" element={<PaymentReport/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            {/* IT SUPPORT */}
            {loggedInUser.role == "13" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<Dashboard/>} />
              <Route path="/viewproperties" element={<AllProperties/>} />
              <Route path="/editproperty"  element={<EditProperty/>} />
              <Route path="/uploadaddress" element={<UploadAddress/>} />
              <Route path="/generatebills" element={<GenerateBills/>} />
              <Route path="/bulkprint" element={<BulkPrint/>} />
              <Route path="/remindernotice" element={<ReminderNotice/>} />
              <Route path="/remindersprint" element={<BulkReminderPrint/>} />
              <Route path="/print&generatebill" element={<Consolidated/>} />
              <Route path="/consprint" element={<ConsolidatePrint/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            {/* PROCESSING OFFICER */}
            {loggedInUser.role == "2" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<ProcessingDashboard/>} />
              <Route path="/viewdispatch" element={<ViewDispatch/>} />
              <Route path="/createdispatch" element={<CreateDispatch/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            {/* ZONAL MANAGER */}
            {loggedInUser.role == "4" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<ZonalDashboard/>} />
              <Route path="/viewproperties" element={<AllProperties/>} />
              <Route path="/7daysreminder" element={<Reminders7/>} />
              <Route path="/21daysreminder" element={<Reminders21/>} />
              <Route path="/remindernotice" element={<ReminderNotice/>} />
              <Route path="/remindersprint" element={<BulkReminderPrint/>} />
              <Route path="/dueforprosecution" element={<DueForProsecution/>} />
              <Route path="/prosecuted" element={<Prosecuted/>} />
              <Route path="/viewdispatch" element={<ViewDispatch/>} />
              <Route path="/revenueofficers" element={<RevenueOfficers/>} />
              <Route path="/processingofficers" element={<ProcessingOfficers/>} />
              <Route path="/paymentreport" element={<PaymentReport/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            {/* REVENUE MANAGER */}
            {loggedInUser.role == "1" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<ZonalDashboard/>} />
              <Route path="/viewproperties" element={<AllProperties/>} />
              <Route path="/7daysreminder" element={<Reminders7/>} />
              <Route path="/21daysreminder" element={<Reminders21/>} />
              <Route path="/remindernotice" element={<ReminderNotice/>} />
              <Route path="/remindersprint" element={<BulkReminderPrint/>} />
              <Route path="/dueforprosecution" element={<DueForProsecution/>} />
              <Route path="/prosecuted" element={<Prosecuted/>} />
              <Route path="/viewdispatch" element={<ViewDispatch/>} />
              <Route path="/paymentreport" element={<PaymentReport/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            {/* HEAD OF OPERATION*/}
            {loggedInUser.role == "5" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<ZonalDashboard/>} />
              <Route path="/viewproperties" element={<AllProperties/>} />
              <Route path="/demandbydistrict" element={<DemandDistrict/>} />
              <Route path="/reminderbydistrict" element={<ReminderDistrict/>} />
              <Route path="/7daysreminder" element={<Reminders7/>} />
              <Route path="/21daysreminder" element={<Reminders21/>} />
              <Route path="/remindernotice" element={<ReminderNotice/>} />
              <Route path="/remindersprint" element={<BulkReminderPrint/>} />
              <Route path="/dueforprosecution" element={<DueForProsecution/>} />
              <Route path="/prosecuted" element={<Prosecuted/>} />
              <Route path="/viewdispatch" element={<ViewDispatch/>} />
              <Route path="/revenueofficers" element={<RevenueOfficers/>} />
              <Route path="/zonalmanagers" element={<ZonalManagers/>} />
              <Route path="/processingofficers" element={<ProcessingOfficers/>} />
              <Route path="/paymentreport" element={<PaymentReport/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            {/*AUDIT*/}
            {loggedInUser.role == "8" ?<Routes >
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />   
              <Route path="/" element={ isAuthenticated ?<Navigate to={getLastPage() == "" || getLastPage() == undefined || getLastPage() == null ? "/dashboard" : `/${getLastPage()}`} replace /> : <Login authenticate={setAuth}/>} />
              <Route path="/dashboard" element={<ProcessingDashboard/>} />
              <Route path="/audit" element={<Audit/>} />
              <Route path="/changepassword" element={<ChangePassword/>} />
            </Routes>: <Routes></Routes>}
            </div>
          </div>}
      </Router>
  );
}

export default App;
