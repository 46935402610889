import React, { useState } from 'react'
import QRCode from 'react-qr-code'
import { getAmountComma } from '../functions/Reusables';



export const ReceiptContent = React.forwardRef((props, ref) => {
  const [occupierName, setOccupierName] = useState(props.info.occupant);

  return (
    <div ref={ref} className="modal-body mybody" id="print_success" style={{fontSize:'13px'}}>
        <div>
            <h5 style={{marginBottom:'15px', fontSize:'16px', textAlign:'center', color:'green' , fontWeight:'bold'}}>ABUJA MUNICIPAL AREA COUNCIL TENEMENT RATE</h5>
            <hr></hr>
        </div>
                            <div className='row'>
                                <div className='col-md-9'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <p>RECEIVED FROM:</p>
                                        </div>
                                        <div className='col-md-9'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <em>The Occupier:</em>
                                                </div>
                                                <div className='col-md-8'>
                                                    <p>{props.info.occupant}</p>
                                                </div>
                                            </div>
                                            <div className='row' >
                                                <div className='col-md-4'>
                                                    <em>Occupiers Name:</em>
                                                </div>
                                                {props.isEditing ? <div style={{display:'flex', width:'250px'}}>
                                                    <input style={{width:'160px'}} type='text' value={occupierName} onChange={(e)=>{
                                                      setOccupierName(e.target.value)
                                                    }}/>
                                                    <button type="submit" className="btn btn-primary" id="receipt" style={{padding:'5px', fontSize:'10px'}} onClick={()=>{
                                                        props.setIsEditing(false)
                                                    }}>
                                                        DONE
                                                    </button>
                                                </div>:
                                                <div className='col-md-8'>
                                                    <p>{occupierName}</p>
                                                </div>}
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <em>Property ID:</em>
                                                </div>
                                                <div className='col-md-8'>
                                                    <p>{props.info.pid}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <em>Property Address:</em>
                                                </div>
                                                <div className='col-md-8'>
                                                    <p>{props.info.prop_addr}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <em>Payment Date:</em>
                                                </div>
                                                <div className='col-md-8'>
                                                    <p>{props.info.date_paid}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <em>Transaction Amount:</em>
                                                </div>
                                                <div className='col-md-8'>
                                                    <p>₦{getAmountComma(props.info.paid_amt)}</p> 
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <em>Amount in words:</em>
                                                </div>
                                                <div className='col-md-8'>
                                                    <p>{props.info.amt_in_words ? (props.info.amt_in_words).toString().toUpperCase() :""}</p>
                                                </div>
                                            </div>
                                            
                                        </div>   
                                        
                                    </div>
                                    
                                </div>
                                <div className='col-md-3'>
                                    <p>Date: {props.info.date_created}</p>
                                    <p>{props.info.transaction_id}</p>
                                    <img style={{width:'120px'}} src="../assets/images/amac-logo.png" />
                                    
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-9'>
                                    <div className='row' style={{marginTop:'10px'}}>
                                        <div className='col-md-6'>
                                            <p>Being Part Payment for:</p>
                                        </div>
                                        <div className='col-md-6' >
                                            <p >Year {new Date().getFullYear()} Tenement Rate</p>
                                        </div>
                                    </div>
                                    <div className='row' style={{marginTop:'10px'}}>
                                        <div className='col-md-6'>
                                            <p>Grand Total tenement Rate</p>
                                        </div>
                                        <div className='col-md-6' >
                                            <p >₦{getAmountComma(props.info.current_amt)}</p>
                                        </div>
                                    </div>
                                    <div className='row' >
                                        <div className='col-md-6'>
                                            <p>Transaction Amount</p>
                                        </div>
                                        <div className='col-md-6'>
                                            <p >₦{getAmountComma(props.info.paid_amt)}</p>
                                        </div>
                                    </div> 
                                    <div className='row' >
                                        <div className='col-md-6'>
                                            <p>Balance</p>
                                        </div>
                                        <div className='col-md-6' >
                                            <p >₦{getAmountComma(props.info.balance)}</p>
                                        </div>
                                    </div>
                                    <div className='row' >
                                        <div className='col-md-6'>
                                            <p>Reference to Pay Balance (RRB)</p>
                                        </div>
                                        <div className='col-md-6' >
                                            <p >78096786786</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                <div id="code" style={{marginTop:'20px'}}><QRCode size={96} value={345353} /></div>
                                </div>
                            </div>
                            <div className='row' >
                                <div className='col-md-4'>
                                    <img style={{height:'74.7px'}} src="../assets/images/newSign.png" />
                                    <div style={{width:'140px', height:'1.5px', backgroundColor:'black'}}></div>
                                    <p >Authorized Signature</p>
                                </div>
                                <div className='col-md-4' style={{paddingLeft:'49px'}}>
                                    <div style={{height:'74.7px'}}></div>
                                    <div style={{width:'140px', height:'1.5px', backgroundColor:'black'}}></div>
                                    <p >{props.info.finance_name}</p>
                                </div>
                                <div className='col-md-4' style={{paddingLeft:'49px'}}>
                                    <div style={{height:'74.7px'}}></div>
                                    <div style={{width:'140px', height:'1.5px', backgroundColor:'black'}}></div>
                                    <p >Signature of Payer</p>
                                    <p >Date</p>
                                </div>

                            </div>
            
                        </div>
  );
});

// export default function ReceiptContent(props) {
//   return (
//     <div className="modal-body" id="print_success" style={{fontSize:'13px'}}>
//                             <div className='row'>
//                                 <div className='col-md-9'>
//                                     <div className='row'>
//                                         <div className='col-md-3'>
//                                             <p>RECEIVED FROM:</p>
//                                         </div>
//                                         <div className='col-md-9'>
//                                             <div className='row'>
//                                                 <div className='col-md-4'>
//                                                     <em>The Occupier:</em>
//                                                 </div>
//                                                 <div className='col-md-8'>
//                                                     <p>THE OCCUPIER</p>
//                                                 </div>
//                                             </div>
//                                             <div className='row' >
//                                                 <div className='col-md-4'>
//                                                     <em>Occupiers Name:</em>
//                                                 </div>
//                                                 {props.isEditing ? <div style={{display:'flex'}}>
//                                                     <input style={{width:'160px'}} type='text'/>
//                                                     <button type="submit" className="btn btn-primary" id="receipt" style={{padding:'5px', fontSize:'10px'}} onClick={()=>{
//                                                         props.setIsEditing(false)
//                                                     }}>
//                                                         DONE
//                                                     </button>
//                                                 </div>:
//                                                 <div className='col-md-8'>
//                                                     <p>THE OCCUPIER</p>
//                                                 </div>}
//                                             </div>
//                                             <div className='row'>
//                                                 <div className='col-md-4'>
//                                                     <em>Property ID:</em>
//                                                 </div>
//                                                 <div className='col-md-8'>
//                                                     <p>MAGDU368</p>
//                                                 </div>
//                                             </div>
//                                             <div className='row'>
//                                                 <div className='col-md-4'>
//                                                     <em>Property Address:</em>
//                                                 </div>
//                                                 <div className='col-md-8'>
//                                                     <p>FLAT 5 NO.24 ANAMBRA RIVER</p>
//                                                 </div>
//                                             </div>
//                                             <div className='row'>
//                                                 <div className='col-md-4'>
//                                                     <em>Payment Date:</em>
//                                                 </div>
//                                                 <div className='col-md-8'>
//                                                     <p>14 January 2022</p>
//                                                 </div>
//                                             </div>
//                                             <div className='row'>
//                                                 <div className='col-md-4'>
//                                                     <em>Transaction Amount:</em>
//                                                 </div>
//                                                 <div className='col-md-8'>
//                                                     <p>₦101,200.00</p>
//                                                 </div>
//                                             </div>
//                                             <div className='row'>
//                                                 <div className='col-md-4'>
//                                                     <em>Amount in words:</em>
//                                                 </div>
//                                                 <div className='col-md-8'>
//                                                     <p>ONE HUNDRED AND ONE THOUSAND TWO HUNDRED NAIRA</p>
//                                                 </div>
//                                             </div>
                                            
//                                         </div>   
                                        
//                                     </div>
                                    
//                                 </div>
//                                 <div className='col-md-3'>
//                                     <p>Date: 21 June 2022</p>
//                                     <p>78881945</p>
//                                     <img style={{width:'120px'}} src="../assets/images/amac-logo.png" />
                                    
//                                 </div>
//                             </div>
//                             <div className='row'>
//                                 <div className='col-md-9'>
//                                     <div className='row' style={{marginTop:'10px'}}>
//                                         <div className='col-md-6'>
//                                             <p>Being Part Payment for:</p>
//                                         </div>
//                                         <div className='col-md-6' >
//                                             <p >Year 2023 Tenement Rate</p>
//                                         </div>
//                                     </div>
//                                     <div className='row' style={{marginTop:'10px'}}>
//                                         <div className='col-md-6'>
//                                             <p>Grand Total tenement Rate</p>
//                                         </div>
//                                         <div className='col-md-6' >
//                                             <p >₦101,200.00</p>
//                                         </div>
//                                     </div>
//                                     <div className='row' >
//                                         <div className='col-md-6'>
//                                             <p>Transaction Amount</p>
//                                         </div>
//                                         <div className='col-md-6'>
//                                             <p >₦101,200.00</p>
//                                         </div>
//                                     </div>
//                                     <div className='row' >
//                                         <div className='col-md-6'>
//                                             <p>Balance</p>
//                                         </div>
//                                         <div className='col-md-6' >
//                                             <p >₦0.00</p>
//                                         </div>
//                                     </div>
//                                     <div className='row' >
//                                         <div className='col-md-6'>
//                                             <p>Reference to Pay Balance (RRB)</p>
//                                         </div>
//                                         <div className='col-md-6' >
//                                             <p >78096786786</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className='col-md-3'>
//                                 <div id="code" style={{marginTop:'20px'}}><QRCode size={96} value={'78881945'} /></div>
//                                 </div>
//                             </div>
//                             <div className='row' >
//                                 <div className='col-md-4'>
//                                     <img style={{width:'120px'}} src="../assets/images/signature.png" />
//                                     <div style={{width:'140px', height:'1.5px', backgroundColor:'black'}}></div>
//                                     <p >Authorized Signature</p>
//                                 </div>
//                                 <div className='col-md-4' style={{paddingLeft:'49px'}}>
//                                     <div style={{height:'74.7px'}}></div>
//                                     <div style={{width:'140px', height:'1.5px', backgroundColor:'black'}}></div>
//                                     <p >CHINJINDU NNAKIRE</p>
//                                 </div>
//                                 <div className='col-md-4' style={{paddingLeft:'49px'}}>
//                                     <div style={{height:'74.7px'}}></div>
//                                     <div style={{width:'140px', height:'1.5px', backgroundColor:'black'}}></div>
//                                     <p >Signature of Payer</p>
//                                     <p >Date</p>
//                                 </div>

//                             </div>
            
//                         </div>
//   )
// }
