import React, {useState, useEffect, Component} from 'react'
import axios from 'axios'
import { getUser, saveLastPage, titleClass } from '../functions/Reusables';
import FeatherIcon from 'feather-icons-react';
import ClockLoader from "react-spinners/ClockLoader"; 

export default class CreateProperty extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      canProceed: false,
      cadastral_zone: "---- Choose a Cadastral Zone ----",
      rating_dist: "Select a Cadastral Zone",
      active: 1,
      prop_use: "RESIDENTIAL",
      category: "EDUCATIONAL INSTITUTION",
      group: "AMAC 1",
      occupant:"",
      asset_no:"",
      prop_addr:"",
      street_name:"",
      rate_payable:0,
      rate_payableFormatted:"0",
      grand_total:0,
      annual_value:"",
      step: 1,
      isLoading:false,
      errorMessage:"",
      errorMessageUpload:"",
      successMessage :"",
      successMessageUpload :"",
      selectedFile:"",
      attribute:"0",
      isCreatedSuccessfully:false,
      isLoadingExcel:false,
      successModalResponse:[]
    };
  }

  formateToAmount(value){
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    return formatter.format(value)
    
  }

  handleFileSelected(event){
    this.setState({
      selectedFile: event.target.files[0],
    })
  }

  async uploadFile(){
    this.setState((state, props) => ({
      isLoading: true,
    }));
    const formData = new FormData() 
    formData.append('amacfile', this.state.selectedFile)
    try {
      const headers = {
        "AccessToken": getUser().access_token,
        "Content-Type": "multipart/form-data"
      }
      console.warn(this.state.selectedFile);
      let url = `${process.env.REACT_APP_API}properties/c_bulk_property/`;
  
      const response = await axios.post(url, formData, {
        headers
      })
      console.log(response.data)
      this.setState((state, props) => ({
        successMessageUpload: `Bulk Properties Created Successfully`,
        isLoading: false, 
        isCreatedSuccessfully:true,
        successModalResponse : response.data.pids,
        fpath:response.data.fpath,
      }));
    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        this.setState((state, props) => ({
          isLoading: false,
          errorMessageUpload:error.response.data.message
        }));
        return
      }
      this.setState((state, props) => ({
        isLoading: false,
        errorMessageUpload:"Something went wrong"
      }));
    }

  }


  zones = [
    {name:"---- Choose a Cadastral Zone ----" , district:"Select a Cadastral Zone"},
    {name:"A00 - CBD" , district:"CBD"},
    {name:"A01 - AREA 1" , district:"GARKI AREAS"},
    {name:"A01 - AREA 2" , district:"GARKI AREAS"},
    {name:"A01 - AREA 3" , district:"GARKI AREAS"},
    {name:"A01 - AREA 7" , district:"GARKI AREAS"},
    {name:"A01 - AREA 8" , district:"GARKI AREAS"},
    {name:"A01 - AREA 11" , district:"GARKI AREAS"},
    {name:"A01 - Area 10" , district:"GARKI AREAS"},
    {name:"A02 - WUSE ZONE 1" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 2" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 3" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 4" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 5" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 6" , district:"WUSE ZONES"},
    {name:"A02 - WUSE ZONE 7" , district:"WUSE ZONES"},
    {name:"A03 - GARKI 2" , district:"GARKI 2"},
    {name:"A04 - ASOKORO" , district:"ASOKORO"},
    {name:"A06 - MAITAMA" , district:"MAITAMA"},
    {name:"A07 - WUSE 2" , district:"WUSE 2"},
    {name:"A09 - GUZAPE" , district:"GUZAPE"}
  ]

  isEmpty(str) {
    return !str.trim().length;
  }

  closeReminderAlert =()=>{
    this.setState({
      isCreatedSuccessfully:false
    })
   }

  checkIfCanProceed =(step)=>{
    let canProceedNext = false
    if(step == 1){
      
      if(this.state.cadastral_zone == '---- Choose a Cadastral Zone ----'){
        canProceedNext = false;
      }else{
        canProceedNext = true
      }

      if(canProceedNext == false){
        console.log("CANNOT PROCEED")
        this.setState((state, props) => ({
          errorMessage: "Error. All fields are required.",
        }));
      }else{
        this.setState((state, props) => ({
          step: state.step + 1,
        }));
      }
    }
    if(step == 2){
      // let canProceedNext = false
      if(this.isEmpty(this.state.occupant) || this.isEmpty(this.state.prop_addr) || this.isEmpty(this.state.asset_no) || this.isEmpty(this.state.street_name)){
        canProceedNext = false;
      }else{
        canProceedNext = true
      }

      if(canProceedNext == false){
        console.log("CANNOT PROCEED 2")
        this.setState((state, props) => ({
          errorMessage: "Error. All fields are required.",
        }));
      }else{
        this.setState((state, props) => ({
          step: state.step + 1,
        }));
      }
    }
    if(step == 3){
      // let canProceedNext = false
      if(this.isEmpty(this.state.annual_value.toString())){
        canProceedNext = false;
      }else{
        canProceedNext = true
      }

      if(canProceedNext == false){
        console.log("CANNOT PROCEED 2")
        this.setState((state, props) => ({
          errorMessage: "Error. All fields are required.",
        }));
      }else{
        // this.createProperty()
      }
    }
    return canProceedNext
  }
  

   async createProperty (){
    this.setState((state, props) => ({
      isLoading: true,
    }));
    this.propertyObject = {
      cadastral_zone : this.state.cadastral_zone,
      rating_dist: this.state.rating_dist,
      active: this.state.active,
      prop_use: this.state.prop_use,
      category: this.state.category,
      group: this.state.group,
      occupant: this.state.occupant,
      asset_no: this.state.asset_no,
      prop_addr: this.state.prop_addr,
      street_name: this.state.street_name,
      rate_payable: this.state.rate_payable,
      grand_total: this.state.grand_total,
      annual_value: this.state.annual_value,
    }
    console.log(this.propertyObject)
    try {
      const headers = {
        "AccessToken": getUser().access_token
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API}properties/create_property/`,
        this.propertyObject,{
          headers
        }
      );
      this.setState((state, props) => ({
        successMessage: `Property Created Successfully with PID ${response.data.pid}`,
        isLoading: false,
        successModalResponse:[response.data.pid],
        isCreatedSuccessfully: true
      }));

      this.setState({
        isLoading: false,
        cadastral_zone: "---- Choose a Cadastral Zone ----",
        rating_dist: "Select a Cadastral Zone",
        active: 1,
        prop_use: "RESIDENTIAL",
        category: "EDUCATIONAL INSTITUTION",
        group: "AMAC 1",
        occupant:"",
        asset_no:"",
        prop_addr:"",
        street_name:"",
        rate_payable:0,
        rate_payableFormatted:"0",
        grand_total:0,
        annual_value:"",
        step:1
    })

    } catch (error) {
      if(error.response.data){
        console.log(error.response)
        this.setState((state, props) => ({
          isLoading: false,
          errorMessage:error.response.data.message
        }));
        return
      }
      this.setState((state, props) => ({
        isLoading: false,
        errorMessage:"Something went wrong"
      }));
    }
  }

  trimResponse(responseList){
    var stringResponse = responseList.join(" , ")
    if(stringResponse.length > 100){
      stringResponse.substring(0, 100);
      stringResponse = stringResponse + "... "
    }
    return stringResponse

  }

  async downloadExcel(){
    this.setState({
      isLoadingExcel: true
    })
      try {
          window.open(`${process.env.REACT_APP_API}${this.state.fpath}`)
          this.setState({
            isLoadingExcel: false
          })
      } catch (error) {
        console.log(error)
        this.setState({
          isLoadingExcel: false
        })
      }
  }

  componentDidMount(){
    titleClass("Create Properties | AMAC Tenement")
    saveLastPage("createproperties")
  }


 render(){
  return (
    
    <div className="page-body">
          <div className="container-fluid">
          { this.state.isLoading ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
          {this.state.isCreatedSuccessfully ? <div className="swal-overlay swal-overlay--show-modal" tabindex="-1" >
          <div className="swal-modal" role="dialog" aria-modal="true">
          <div class="swal-icon swal-icon--success">
          <span class="swal-icon--success__line swal-icon--success__line--long"></span>
          <span class="swal-icon--success__line swal-icon--success__line--tip"></span>

          <div class="swal-icon--success__ring"></div>
          <div class="swal-icon--success__hide-corners"></div>
        </div>
            <div className="swal-title">Property Created Successfully</div>
            {/* <p>The following property(ies)</p> */}
            <p>{this.trimResponse(this.state.successModalResponse)}</p>
            <div className="swal-footer">
              <div className="swal-button-container" style={{display:'flex', justifyContent:'end'}}>
                <button className="btn" style={{color:'grey'}} onClick={()=>{this.closeReminderAlert()}} >OK</button>
                <button className="btn btn-primary" style={{display:'flex'}}  onClick={()=>{
                  this.downloadExcel()
                }}><span style={{marginRight:'10px'}}>Export Excel</span>{this.state.isLoadingExcel ?<ClockLoader color={'#ffffff'} loading={this.state.isLoadingExcel} cssOverride={{}} size={20} /> : <div></div>}</button>
                <div className="swal-button__loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>: <div></div>}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-6">
                  <h3>Create Property</h3>
                  <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Properties</a></li>
                    <li className="breadcrumb-item">Create Properties</li>

                  </ol>
                </div>
                <div className="col-sm-6">
                  
                </div>
              </div>
            </div>
          <div className="container-fluid" style={{marginTop:'20px'}}>
            <div className="row">
              <div className="col-sm-7">
                <div className="card ">

                  <div className="card-body">
                    <div className="stepwizard">
                      <div className="stepwizard-row setup-panel">
                        <div className="stepwizard-step"><a className={this.state.step == 1 ? "btn btn-primary" : "btn btn-light"} onClick={()=>{
                          if(this.checkIfCanProceed(this.state.step)){
                            this.setState({
                              step : 1
                            })
                          }
                        }}  href="#">1</a>
                          <p>Zone / District Details</p>
                        </div>
                        <div className="stepwizard-step"><a className={this.state.step == 2 ? "btn btn-primary" : "btn btn-light"} onClick={()=>{
                          if(this.checkIfCanProceed(this.state.step)){
                            this.setState({
                              step : 2
                            })
                          }
                          
                        }} href="#">2</a>
                          <p>Property Details</p>
                        </div>
                        <div className="stepwizard-step"><a className={this.state.step == 3 ? "btn btn-primary" : "btn btn-light"} onClick={()=>{
                          if(this.checkIfCanProceed(this.state.step)){
                            this.setState({
                              step : 3
                            })
                          }
                        }}  href="#">3</a>
                          <p>Property Value / Category</p>
                        </div>
                        
                      </div>
                    </div> 
                    {this.state.errorMessage !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{this.state.errorMessage}</strong>
                      <button class="btn-close" onClick={()=>this.setState({errorMessage:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {this.state.successMessage !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{this.state.successMessage}</strong>
                      <button class="btn-close" onClick={()=>this.setState({successMessage:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <form action="#" method="POST">
                     <div className="setup-content" style={{display: this.state.step != 1 ? 'none': 'block'}} id="step-1">
                        <div className="col-xs-12">
                          <div className="col-md-12">
                            <div className='row'>
      
                                  <div class="mb-3 form-group col-md-6">
                                    <label class="control-label" for="exampleFormControlSelect9">Cadastral Zone</label>
                                    <select class="form-select digits" id="cadList" value={this.state.attribute} onChange={(e)=>{
                                      const el = document.getElementById("cadList");
                                      // console.log( el.options[el.selectedIndex].attributes.attr.value)
                                      this.setState((state, props) => ({
                                        cadastral_zone: el.options[el.selectedIndex].text,
                                        attribute: e.target.value,
                                      }))
                                      this.setState((state, props) => ({
                                        rating_dist:  el.options[el.selectedIndex].attributes.district.value,
                                      }))
                                      // console.log(this.state.rating_dist)
                                    }} >
                                        <option district="Select a Cadastral Zone" value="0">---- Choose a Cadastral Zone ----</option>
                                        <option district="CBD" value="1">A00 - CBD</option>
                                        <option district="GARKI AREAS" value="2">A01 - AREA 1</option>
                                        <option district="GARKI AREAS" value="3">A01 - AREA 2</option>
                                        <option district="GARKI AREAS" value="4">A01 - AREA 3</option>
                                        <option district="GARKI AREAS" value="5">A01 - AREA 7</option>
                                        <option district="GARKI AREAS" value="6">A01 - AREA 8</option>
                                        <option district="GARKI AREAS" value="7">A01 - AREA 11</option>
                                        <option district="GARKI AREAS" value="8">A01 - Area 10</option>
                                        <option district="WUSE ZONES" value="9">A02 - WUSE ZONE 1</option>
                                        <option district="WUSE ZONES" value="10">A02 - WUSE ZONE 2</option>
                                        <option district="WUSE ZONES" value="11">A02 - WUSE ZONE 3</option>
                                        <option district="WUSE ZONES" value="12">A02 - WUSE ZONE 4</option>
                                        <option district="WUSE ZONES" value="13">A02 - WUSE ZONE 5</option>
                                        <option district="WUSE ZONES" value="14">A02 - WUSE ZONE 6</option>
                                        <option district="WUSE ZONES" value="15">A02 - WUSE ZONE 7</option>
                                        <option district="GARKI 2" value="16">A03 - GARKI 2</option>
                                        <option district="ASOKORO" value="17">A04 - ASOKORO</option>
                                        <option district="MAITAMA" value="18">A06 - MAITAMA</option>
                                        <option district="WUSE 2" value="19">A07 - WUSE 2</option>
                                        <option district="GUZAPE" value="20">A09 - GUZAPE</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label className="control-label">Ratings District</label>
                                    <input className="form-control" disabled type="text" placeholder="Select a Cadastral Zone" required="required" value={this.state.rating_dist}/>
                                  </div>
                            </div>
                            <div class="mb-3 form-group col-md-12">
                                    <label class="control-label" for="exampleFormControlSelect9">Property Action</label>
                                    <select class="form-select digits" id="exampleFormControlSelect9" onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          active: parseInt(e.target.value)
                                        }));
                                      }}>
                                        
                                        <option value="1" >ACTIVE</option>
                                        <option value="2" >VACANT</option>
                                        <option value="3">UNDER CONSTRUCTION</option>
                                        <option value="4" >EMPTY LAND</option>
                                        <option value="5" >EXEMPTED</option>
                                    </select>
                                  </div>                                                                           
                                <button className="btn btn-primary nextBtn pull-right" onClick={()=>this.checkIfCanProceed(1)} type="button">Next</button>
                              </div>
                        </div>
                      </div>
                      <div className="setup-content" style={{display: this.state.step != 2 ? 'none': 'block'}} id="step-2">
                        <div className="col-xs-12">
                          <div className="col-md-12">
                            <div className='row'>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Name of Occupant</label>
                                <input className="form-control" type="text" placeholder="Occupants Name" required="required"  onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          occupant: e.target.value
                                        }));
                                      }}/>
                              </div>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Asset Number</label>
                                <input className="form-control" type="text" placeholder="Assessment Number" required="required"  onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          asset_no: e.target.value
                                        }));
                                      }}/>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Property Address</label>
                                <input className="form-control" type="text" placeholder="Property Address" required="required"  onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          prop_addr: e.target.value
                                        }));
                                      }}/>
                              </div>
                              <div className="form-group  col-md-6">
                                <label className="control-label">Street Name</label>
                                <input className="form-control" type="text" placeholder="Street Name" required="required"  onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          street_name: e.target.value
                                        }));
                                      }}/>
                              </div>
                            </div>
                            
                            <div class="mb-3 form-group col-md-12">
                              <label class="control-label" for="exampleFormControlSelect9">Property Use</label>
                              <select class="form-select digits" id="exampleFormControlSelect9" onChange={(e)=>{
                                  this.setState((state, props) => ({
                                    prop_use: e.target.value
                                  }));
                                }}>
                                  <option value="RESIDENTIAL" >RESIDENTIAL</option>
                                  <option value="COMMERCIAL" >COMMERCIAL</option>

                              </select>
                            </div>
                            <button className="btn btn-primary nextBtn pull-right" type="button" onClick={()=>this.checkIfCanProceed(2)}>Next</button>
                          </div>
                        </div>
                      </div>
                      <div className="setup-content" id="step-3" style={{display: this.state.step != 3 ? 'none': 'block'}}>
                      <div className="col-xs-12">
                          <div className="col-md-12">
                            
                            <div className="form-group  col-md-12">
                              <label className="control-label">Property Value</label>
                              <input className="form-control" type="text" placeholder="Property Value" required="required" value={this.state.annual_value}  onChange={(e)=>{
                                        this.setState((state, props) => ({
                                          annual_value: Number(e.target.value),
                                          rate_payable: Number(e.target.value) * 0.04,
                                          grand_total: Number(e.target.value) * 0.04,
                                          rate_payableFormatted: this.formateToAmount(Number(e.target.value) * 0.04)
                                        }));
                                      }}/>
                            </div>
                            <div className="form-group  col-md-12">
                              <label className="control-label">Rate Payable</label>
                              <input className="form-control" disabled type="text" placeholder="Rate Payable" required="required" value={this.state.rate_payableFormatted}/>
                            </div>
                            
                            <div className='row'>
                              <div class="mb-3 form-group col-md-6">
                                <label class="control-label" for="exampleFormControlSelect9">Group</label>
                                <select class="form-select digits" id="exampleFormControlSelect9" onChange={(e)=>{
                                    this.setState((state, props) => ({
                                      group: e.target.value
                                    }));
                                  }}>
                                    <option value="AMAC 1" >AMAC 1</option>
                                    <option value="AMAC 2" >AMAC 2</option>

                                </select>
                              </div>
                              <div class="mb-3 form-group col-md-6">
                                <label class="control-label" for="exampleFormControlSelect9">Category</label>
                                <select class="form-select digits" id="exampleFormControlSelect9" onChange={(e)=>{
                                    this.setState((state, props) => ({
                                      category: e.target.value
                                    }));
                                  }}>
                                    <option value="EDUCATIONAL INSTITUTION">EDUCATIONAL INSTITUTION</option>
                                    <option value="HOSPITALITY" >HOSPITALITY</option>
                                    <option value="FINANCIAL INSTITUTION" >FINANCIAL INSTITUTION</option>
                                    <option value="OIL AND GAS" >OIL AND GAS</option>
                                    <option value="PLAZA" >PLAZA</option>
                                    <option value="CONSTRUCTION" >CONSTRUCTION</option>

                                </select>
                              </div>
                              
                            </div>
                            
                            
                            <button className="btn btn-secondary nextBtn pull-right" onClick={()=>this.createProperty()} type="button">Create Property</button>
                          </div>
                        </div>
                      </div>
                      
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-sm-5">
                <div className='card'>
                  
                  <div className='card-body'>
                  {this.state.errorMessageUpload !== "" ?<div class="alert alert-danger dark alert-dismissible fade show" role="alert"><strong>{this.state.errorMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>this.setState({errorMessageUpload:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    {this.state.successMessageUpload !== "" ?<div class="alert alert-primary dark alert-dismissible fade show" style={{backgroundColor:'#51cd51', borderColor:'#51cd51'}} role="alert"><strong>{this.state.successMessageUpload}</strong>
                      <button class="btn-close" onClick={()=>this.setState({successMessageUpload:""})} type="button"  aria-label="Close" data-bs-original-title="" title=""></button>
                    </div>: <div></div>}
                    <p style={{fontWeight:'bold'}}> <span style={{marginRight:'20px'}}><FeatherIcon icon='upload' size={20}></FeatherIcon></span> Bulk Upload (Spool)</p>
                    <p style={{}}>Create properties in bulk</p>
                    <input class="form-control mb-20" type="file" onChange={(e)=>{
                      this.handleFileSelected(e)
                    }} aria-label="file example" required=""/>
                    <button className="btn btn-secondary pull-right" onClick={()=>{
                      this.uploadFile()
                    }}  type="button">Bulk Create</button>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
  )
 }
}
