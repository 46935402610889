import React, { Component, useRef, useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { BtnCellRendererViewPrint } from '../components/ActionButtons';
import MyDataTable from '../components/MyDataTable';
import { ShimmerThumbnail ,ShimmerTable} from "react-shimmer-effects";
import { getAmountComma, getUser, setFilterData, useLastPage, useTitleFunctional } from '../functions/Reusables';
import axios from 'axios';
import PrintReceipt from './printReceipt';
import FeatherIcon from 'feather-icons-react';

export default function PaymentHistory (){
  const myRef = useRef()
  const tableColumns = [
    { headerName:'PID',field: 'pid'},
    { headerName:'Rating Dist.',field: 'rating_dist'},
    { headerName:'Prev Amount',field: 'current_amt',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'Amount Paid',field: 'paid_amt',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'Balance',field: 'balance',valueFormatter: params => getAmountComma(params.value)},
    { headerName:'Trans. ID',field: 'transaction_id'},
    { headerName:'Bank',field: 'bank'},
    { headerName:'Month',field: 'month'},
    { headerName:'Date Paid',field: 'date_paid'},
    { headerName:'Channel',field: 'channel',maxWidth:150},
    { headerName:'Percent',field: 'percent',maxWidth:150},
    {
      headerName:'',
      field: "actions",
      shouldAction:true,
      cellRenderer: "btnCellRenderer",
      cellRendererParams: {
        clicked: function(field,id) {
          // console.log(parseInt(field.slice(3)), id)
          if(id === 'view'){
            console.log(field.pid)
            // setIsViewing(true)
            getProperty(field.pid)
          }
          if(id === 'print'){
            console.log(field.fid)
            getPrintDetails(field.fid)
            // saveLastID((parseInt(field.slice(3))).toString());
            // navigate("/editproperty"); 
          }
        }
      },
      maxWidth:180
    },
  ]

  async function getPrintDetails(fid){
    setisLoadingPage(true)
    const requestPID = {
      fid: fid,
    };
    const headers = {
      "AccessToken":getUser().access_token
    }
    
    try {
      console.log(requestPID)
      const response = await axios.post(
        `${process.env.REACT_APP_API}fpay/get_receipt/`,
        requestPID,
        {headers}
      );
      console.log(response)
      setPrintResponse({
        "pid": response.data.f_data.pid,
        "occupant": response.data.f_data.occupant,
        "prop_addr": response.data.f_data.prop_addr,
        "rating_dist": response.data.f_data.rating_dist,
        "paid_amt": response.data.f_data.paid_amt,
        "current_amt": response.data.f_data.current_amt,
        "balance": response.data.f_data.balance,
        "date_paid": response.data.f_data.date_paid,
        "date_created": response.data.f_data.date_created,
        "transaction_id": response.data.f_data.transaction_id,
        "created_by": response.data.f_data.created_by,
        "amt_in_words": response.data.f_data.amt_in_words,
        "finance_name": response.data.f_data.finance_name
      })
      setisViewingPrint(true)
      setisLoadingPage(false)
      console.log(printResponse)
    } catch (error) {
      const errors = []
      console.log(error)
      errors.push("Something went wrong")
      
      if(error.response !== null || error.response !== undefined){
        errors.push(error.response.data.message)
      }
      console.log(errors.toString())
      setisLoadingPage(false)
    }
  }
  async function getProperty(pid){
    setisLoading(true)
    const requestPID = {
      pid: pid,
    };
    const headers = {
      "AccessToken":getUser().access_token
    }
    setisViewing(true)
    try {
      console.log(requestPID)
      const response = await axios.post(
        `${process.env.REACT_APP_API}fpay/get_details/`,
        requestPID,
        {headers}
      );
      console.log(response)
      setPropResponse({
        "pid": response.data.f_data.pid,
        "occupant": response.data.f_data.occupant,
        "prop_addr": response.data.f_data.prop_addr,
        "street_name": response.data.f_data.street_name,
        "asset_no": response.data.f_data.asset_no,
        "cadastral_zone": response.data.f_data.cadastral_zone,
        "prop_type": response.data.f_data.prop_type,
        "prop_use": response.data.f_data.prop_use,
        "rating_dist": response.data.f_data.rating_dist,
        "annual_value": response.data.f_data.annual_value,
        "rate_payable": response.data.f_data.rate_payable,
        "arrears": response.data.f_data.arrears,
        "penalty": response.data.f_data.penalty,
        "grand_total": response.data.f_data.grand_total,
        "category": response.data.f_data.category,
        "group": response.data.f_data.group,
        "active": response.data.f_data.active
      })
      setPropResponse2(response.data.s_data)
      setisLoading(false)
      
    } catch (error) {
      const errors = []
      console.log(error)
      errors.push("Something went wrong")
      
      if(error.response !== null || error.response !== undefined){
        errors.push(error.response.data.message)
      }
      console.log(errors.toString())
    }
  }

  const [searchTerm, setValue] = useState("");
  const [randTime, setRand] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateReq, setStartReqDate] = useState("");
  const [endDateReq, setEndReqDate] = useState("");
  const [isViewing, setisViewing] = useState(false);
  const [isViewingPrint, setisViewingPrint] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingPage, setisLoadingPage] = useState(false);
  const [printResponse, setPrintResponse] = useState({});
  const [propResponse, setPropResponse] = useState({});
  const [propResponse2, setPropResponse2] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [searchPID, setSearchPID] = useState("");

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
  }

  const frameworkComponents = {
    btnCellRenderer: BtnCellRendererViewPrint
  }

  function closeModal(){
    setisViewingPrint(false)
  }
  function loadingPage(value){
    setisLoadingPage(value)
  }

  useTitleFunctional("Payment History | AMAC Tenement")
  useLastPage("paymenthistory")
  
    return (
          <div className="page-body">
            { isLoadingPage ? <div class="loader-wrapper" style={{background:'#fff'}}>
            <div class="theme-loader"> 
            <img style={{position: 'absolute',top: 'calc(50vh + 80px)', left: 'calc(50vw - 48px)'}} className="img-fluid" src="assets/images/logo/logo2.png" alt=""/>  
              <div class="loader-p"></div>
              <p style={{position: 'absolute',top: 'calc(50vh + 110px)', left: 'calc(50vw - 24px)',  fontSize:'12px'}}>LOADING</p>
            </div>
          </div> : <div></div>}
            {isViewingPrint ?
            
                  <PrintReceipt pageLoader={loadingPage} closeModal={closeModal} propResponse={printResponse}/> 
               
                : <div></div>}
            { isViewing ?<div class="modal fade show" id="exampleModalCenter" style={{display:'block', backgroundColor:'#0000004a',overflowY:'auto'}} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter" aria-modal="true">
              <div class="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:'600px'}}>
                <div class="modal-content">
                  <div class="modal-header">
                    {isLoading ? <ShimmerThumbnail height={30} className='myShimmerOveride' rounded /> :<h5 class="modal-title">{propResponse.pid}</h5>}
                    <button class="btn-close" type="button" onClick={()=>setisViewing(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className='p-20' style={{fontSize:'13px'}}>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Occupant</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.occupant}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Assessment Number</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.asset_no}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Rating District</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.rating_dist}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Cadastrial Zone</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.cadastral_zone}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Property Use</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.prop_use}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Property Type</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.prop_type}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Property Category</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.category}</p>}</div>
                    </div>
                    <div className=" row mb-20 ">
                      <label className="col-sm-4 m-0">Property Address</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{propResponse.prop_addr}</p>}</div>
                    </div>
                    
                    <p className=" m-0">VALUES</p>
                    <div style={{height:'1px', width:'100%', backgroundColor:'#80808042', marginTop:'5px',marginBottom:'5px'}}></div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Annual Value</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.annual_value)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Rate Payable</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.rate_payable)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Arrears</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.arrears)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Penalty</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.penalty)}</p>}</div>
                    </div>
                    <div className=" row ">
                      <label className="col-sm-4 m-0">Grand Total</label>
                      <div className="col-sm-8 m-0">{isLoading ? <ShimmerThumbnail height={20} className='myShimmerOveride' rounded /> :<p className='m-0' style={{fontSize:'13px'}}>{getAmountComma(propResponse.grand_total)}</p>}</div>
                    </div>
                    <div className='mb-20'></div>
                    {isLoading ? <ShimmerTable row={2} col={6} /> :<table className="table">
                          <thead style={{backgroundColor:"#efefef"}}>
                            <tr>
                              <th scope="col">Trans. ID</th>
                              <th scope="col">Prev</th>
                              <th scope="col">Paid</th>
                              <th scope="col">Balance</th>
                              <th scope="col">Month</th>
                              <th scope="col">Date Paid</th>
                            </tr>
                          </thead>
                          <tbody>
                          {propResponse2.map((items)=>
                                <tr>
                                  <th scope="row">{items.transaction_id}</th>
                                  <td>{getAmountComma(items.current_amt)}</td>
                                  <td>{getAmountComma(items.paid_amt)}</td>
                                  <td>{getAmountComma(items.balance)}</td>
                                  <td>{items.month}</td>
                                  <td>{items.date_paid}</td>

                                </tr>
                              )}
                          
                            
                          </tbody>
                        </table>}
                  </div>
                  {/* <div class="modal-footer">
                    <button  class="btn btn-primary" onClick={()=>{
                      setisViewing(false)
                      // setIsViewingPrint(true)
                    }} type="button">Print</button>
                  </div> */}
                </div>
              </div>
            </div>: <div></div>}
          <div className="container-fluid">
            <div className="page-header">
              <div className="row" style={{display:'flex'}}>
                <div className="col-md-4" >
                  <h3>Payment History</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Payment History</a></li>
                    {/* <li className="breadcrumb-item">View Properties</li> */}
                  </ol>
                </div>
                <div className="col-md-8" style={{display:'flex', justifyContent:'flex-end'}}>
                  <div className='myInputDate' >
                    <p>Start Date:</p>
                    <DatePicker placeholderText='Pick Start Date' style={{padding:'10px'}} selected={startDate} onChange={(date) =>{
                      console.log(date)
                       if(date == null){
                        setStartDate("")
                        setStartReqDate("")
                        if(endDateReq != null){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }else{
                        setStartDate(date)
                        setStartReqDate(formatDate(date))
                        if(endDateReq != ""){
                          setRand(new Date().getMilliseconds().toString())
                        }
                       }
                    }} />
                  </div>
                  <div className='myInputDate'>
                    <p>End Date:</p>
                      <DatePicker placeholderText='Pick End Date' selected={endDate} onChange={(date) => {
                        if(date == null){
                          setEndDate("")
                          setEndReqDate("")
                          if(startDateReq != null){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setEndDate(date)
                          setEndReqDate(formatDate(date))
                          if(startDateReq != ''){
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                      }} />
                  </div>
                  <select class="form-select digits" style={{marginLeft:'10px',height:'42px', marginTop:'24px', width:'70px'}} id="exampleFormControlSelect9" onChange={(e)=>{
                    setSelectedFilter(e.target.value)
                    }}>
                      <option value="all" >All</option>
                      <option value="pid" >PID</option>
                  </select>
                  <div className=" search-form row"  style={{}}>
                  <p style={{marginBottom:'0px', fontWeight:'bold', height:'24px'}}> </p>
                    <div className="form-group form-control-search" style={{display:'flex' }}>
                      <input type="text" style={{ borderRadius:'10px'}} placeholder="Search.." onChange={e=>{
                        setValue(e.target.value)
                        if(selectedFilter == 'all'){
                          if(e.target.value.length > 2){
                            setRand(e.target.value)
                            console.log("changed")
                          }
                          else if(e.target.value.length == 0){
                            setValue("")
                            setSearchPID("")
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }else{
                          setSearchPID(e.target.value)
                          if(e.target.value.length > 0){
                            setRand(e.target.value)
                            console.log("changed")
                          }
                          else if(e.target.value.length == 0){
                            setValue("")
                            setSearchPID("")
                            setRand(new Date().getMilliseconds().toString())
                          }
                        }
                        }}/>
                        <button style={{paddingLeft:'5px', paddingRight:'5px', marginLeft:'10px'}} className='btn btn-primary' onClick={()=>{
                          myRef.current.childMethod()
                          setFilterData({})
                        }}><FeatherIcon icon="filter" size={14}></FeatherIcon></button>

                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <MyDataTable endpoint = "fpay/total_payments" ref={myRef} col = {tableColumns} search = {searchTerm} searchpid = {searchPID} startDate={startDateReq} endDate={endDateReq} key= {randTime} frameworkComponents= {frameworkComponents} /> 
            </div>
          </div>
        </div>
    )

}
