import React, { Component, useEffect } from 'react'
import QRCode from "react-qr-code";
import { getAmountComma } from '../functions/Reusables';

export default class PrintConsolidatedReminder  extends Component { 

    constructor(props) {
        super(props);
        this.state = {

        };
      }

    getAmount(value){
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
    }

    getDateTime(){
        const d = new Date();
        return `${d.toJSON().slice(0,10).split('-').reverse().join('/')} ${d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        
    }
    getYear(){
        const d = new Date();
        return `${d.getFullYear()}`;
    }

    async buildPage(){
        console.log("printing")
        console.log(this.props)

        
        this.props.closeModal()
        var prtContent = document.getElementById("mycontainerprint");
        var WinPrint = window.open('', '', 'toolbar=0,scrollbars=0,status=0');
        console.log(WinPrint)
        WinPrint.document.write('<html><head>');
        WinPrint.document.write('<link rel="stylesheet" href="assets/css/myprint2.css">');

        // WinPrint.document.write('</head><body class="" onload="print();close();">');
        WinPrint.document.write('</head><body class="" onload="">');
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.write('</body></html>');
        WinPrint.document.close();
        WinPrint.focus();
        this.props.refreshTable()

        // updatePrint((parseInt(props.propResponse.pid.slice(3))).toString())
    }

    firstPage(response){
        return response["1st_page"][0]
    }
    secondPage(response){
        return response["2nd_page"]
    }
    getDateTime(){
        const d = new Date();
        return `${d.toJSON().slice(0,10).split('-').reverse().join('/')} ${d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        
    }

    componentDidMount(){
        this.buildPage()
    }

    render() {
    return (

<div className="__container" id='mycontainerprint'>
        
        <div className="content">
            <div className="container">
                <div className="flex">

                    <div className="first_flex">
                        <table className="no-border">
                            <tr>
                                <td>
                                    <h5>GROUP NAME:</h5>
                                </td>
                                <td>
                                    <h5>{this.firstPage(this.props.propResponse).group_name}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td style={{display:"flex"}}>
                                    <h5>ADDRESS:</h5>
                                </td>
                                <td>
                                    <h5>{this.firstPage(this.props.propResponse).prop_addr}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td className="increase">
                                    <h5>ASSESSMENT NO:</h5>
                                </td>
                                <td>
                                    <h5>{this.firstPage(this.props.propResponse).asset_no}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5>RATE YEAR:</h5>
                                </td>
                                <td>
                                    <h5>{this.firstPage(this.props.propResponse).year}</h5>
                                </td>
                            </tr>

                        </table>
                    </div>
                    <div className="second_flex">
                        <h4 style={{fontsize:"10px"}}>{this.getDateTime()}</h4>
                        <p className="date">{Date.now()}</p>
                        <div>
                        <QRCode size={96} value={`${process.env.REACT_APP_API}upay/make_payment/?_conid=${this.firstPage(this.props.propResponse)._id}&_amt=${this.firstPage(this.props.propResponse).grand_total}`}/>
                        </div>
                        {/* <div className="usercode">
                            TR/z2yst1zqsul07xh1dg/11/343300
                        </div> */}
                    </div>
                </div>
                <div className="main_sub" style={{fontsize:"14px"}}>
                    <p>Please find below your bill summary as requested.</p>
                    <p>Attatched to this summary are the logs of the individual <b>Reminder notices</b></p>
                </div>
                <div className="main_table">
                    <table>
                        <thead>
                            <tr>
                                <th>Bill summary</th>
                                <th>PID ({this.firstPage(this.props.propResponse)._id})</th>
                            </tr>
                        </thead>
                        <tbody className="bill">
                            <tr>
                                <td>Total number of tenements</td>
                                <td> {this.firstPage(this.props.propResponse).total}</td>
                            </tr>
                            <tr>
                                <td>Annual Value</td>
                                <td>₦ {getAmountComma(this.firstPage(this.props.propResponse).annual_value)}</td>
                            </tr>
                            <tr>
                                <td>Total Rate Payable</td>
                                <td>₦ {getAmountComma(this.firstPage(this.props.propResponse).rate_payable)}</td>
                            </tr>
                            <tr>
                                <td>Total Arrears</td>
                                <td>₦ {getAmountComma(this.firstPage(this.props.propResponse).arrears)}</td>
                            </tr>
                            <tr>
                                <td>Total Penalty</td>
                                <td>₦ {getAmountComma(this.firstPage(this.props.propResponse).penalty)}</td>
                            </tr>
                            <tr>
                                <td>Grand Total</td>
                                <td>₦ {getAmountComma(this.firstPage(this.props.propResponse).grand_total)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="main_text" >
                    <p style={{fontSize:"12px"}}>
                        <span style={{fontWeight:"bold",fontSize:"12px"}} >Please turn overleaf for a list of payments options through which payments can be made and
                            ensure
                            your proof of payment is collected from the bank
                        </span>
                    </p>
                    <p style={{fontSize: "12px",fontWeight:"400"}}>Upon succesful payment at any of our payment channels, an
                        official reciept
                        will be available for collection at our office. Please note that electronic receipts can also
                        delivered to your preferred email address should you subscribe for the service.
                        <span style={{fontWeight:"bold",fontSize:"12px"}}>Reach us on our dedicated center for more information or assistance.
                        </span>
                    </p>
                    <br/>
                    <br/>
                    <div style={{fontSize: "12px"}}>All payment To <span style={{fontSize: "12px",fontWeight:"bold"}}>AMAC Tenement Rate</span> Account No. <span style={{fontSize: "12px",fontWeight:"bold"}}>0057326758, UNION BANK.</span></div>
                </div>
                <div>
                <div className="flex" >
                    <div className="flex">
                        <div style={{width: "319px",marginRight:"10px"}}>
                            <div style={{marginTop:"15px"}} className="pad-left-10">
                                <p>Your early compliance will be highly appreciated.</p>
                                <p className="yours">Yours faithfully,</p>
                                <div className="margin-45">
                                    <div>
                                        
                                    </div>                    
                                </div>
                                <div className="authority flex space-btw">
                                    <div>
                                        <img className='signatures' src="../assets/images/sign1.png"/>
                                        <div className="head-tenement">HEAD OF TENEMENT RATE</div>
                                        <div className="chairman" style={{fontSize: "11px"}}> For Honourable Chairman</div>
                                        <div className="council" style={{fontSize: "11px"}}>Abuja Municipal Area Council</div>
                                    </div>
                                    <div>
                                        <img className='signatures' src="../assets/images/sign2.png" />
                                        <div className="head-tenement">DIRECTOR OF OPERATIONS</div>
                                        <div className="chairman" style={{fontSize: "11px"}}> For Honourable Chairman</div>
                                        <div className="council" style={{fontSize: "11px"}}>Abuja Municipal Area Council</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="fill-avail" style={{width: "100%", marginLeft:'20px'}}> 
                            <div className="c-table">
                                <div className="c-table-head" style={{marginTop: "2px"}}>
                                    Acknowledgement
                                </div>
                                <div className="c-table-body flex">
                                    <div className="c-table-content c-table-margin wide-22">
                                        Name
                                    </div>
                                    <div className="c-table-content wide">

                                    </div>
                                </div>
                                <div className="c-table-body flex">
                                    <div className="c-table-content c-table-margin wide-22">
                                        Date
                                    </div>
                                    <div className="c-table-content wide">

                                    </div>
                                </div>
                                <div className="c-table-body flex">
                                    <div className="c-table-content c-table-margin wide-22">
                                        Signature
                                    </div>
                                    <div className="c-table-content wide">

                                    </div>
                                </div>
                            </div>
                            <div className="c-table">
                                <div className="c-table-body flex">
                                    <div className="c-table-content c-table-margin wide-70">
                                        Date of dispatch
                                    </div>
                                    <div className="c-table-content wide">

                                    </div>
                                </div>
                                <div className="c-table-body flex">
                                    <div className="c-table-content c-table-margin wide-70">
                                        Name of Officer
                                    </div>
                                    <div className="c-table-content wide">

                                    </div>
                                </div>
                                <div className="c-table-body flex">
                                    <div className="c-table-content c-table-margin wide-70">
                                        Mode of dispatch
                                    </div>
                                    <div className="c-table-content wide">

                                    </div>
                                </div>
                            </div>                          
                        </div>

                </div>
            </div>
            </div>
        </div>
      
        
            
            <div>
            </div>

            <div style={{marginTop:"350px"}}>
              
                <div className="main_table">
                    <table style={{marginBottom: "-1px"}}>
                        <tr>
                            <th><b>{this.firstPage(this.props.propResponse).prop_addr}</b></th>
                            
                        </tr>
                    </table>
                    <table style={{marginTop: "0"}}>
                        <tbody className="tbody" id="tbody">
                            <tr className="table-tr">
                                <td className="table-head">S/N</td>
                                <td className="table-head">PID</td>
                                <td className="table-head">Property Address</td>
                                <td className="table-head">Annual Value</td>
                                <td className="table-head">Rate Payable</td>
                                <td className="table-head">Arrears</td>
                                <td className="table-head">Penalty</td>
                                <td className="table-head">Grand Total</td>
                            </tr>
                            {
                                this.secondPage(this.props.propResponse).map((row,index)=>
                                <tr>
                                    <td className="table-body">{index +1}</td>
                                    <td className="table-body">{row.pid}</td>
                                    <td className="table-body">{row.prop_addr}</td>
                                    <td className="table-body align-padding">{getAmountComma(row.annual_value)}</td>
                                    <td className="table-body align-padding">{getAmountComma(row.rate_payable)}</td>
                                    <td className="table-body align-padding">{getAmountComma(row.arrears)}</td>
                                    <td className="table-body align-padding">{getAmountComma(row.penalty)}</td>
                                    <td className="table-body align-padding">{getAmountComma(row.grand_total)}</td>
                                </tr>
                                )
                            }
                                <tr>
                                    <td className="table-body"></td>
                                    <td className="table-body"></td>
                                    <td className="table-body"></td>
                                    <td className="table-body align-padding">{getAmountComma(this.firstPage(this.props.propResponse).annual_value)}</td>
                                    <td className="table-body align-padding">{getAmountComma(this.firstPage(this.props.propResponse).rate_payable)}</td>
                                    <td className="table-body align-padding">{getAmountComma(this.firstPage(this.props.propResponse).arrears)}</td>
                                    <td className="table-body align-padding">{getAmountComma(this.firstPage(this.props.propResponse).penalty)}</td>
                                    <td className="table-body align-padding">{getAmountComma(this.firstPage(this.props.propResponse).grand_total)}</td>
                                </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
    </div>



    )
}

}
